// HabitTracker.js
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { FaPlus, FaTimes, FaCheck } from 'react-icons/fa';
import HabitModal from '../components/HabitModal';

const Container = styled.div`
  background: #070707;
  border-radius: 20px;
  color: #ffffff;
  min-height: 100vh;
  padding: 20px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
`;

const Title = styled.h1`
  font-size: 2rem;
`;

const AddButton = styled.button`
  background: rgba(255, 255, 255, 0.1);
  border: none;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  backdrop-filter: blur(5px);
  color: #ffffff;
  font-size: 1.2rem;
`;

const Subheader = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
`;

const DayLabel = styled.span`
  width: 40px;
  text-align: center;
  margin: 0 5px;
  font-weight: bold;
`;

const HabitRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

const HabitName = styled.span`
  color: ${props => props.color};
  font-weight: bold;
`;

const TrackingMarkers = styled.div`
  display: flex;
`;

const Marker = styled.div`
  width: 40px;
  height: 40px;
  margin: 0 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  backdrop-filter: blur(5px);
  color: ${props => props.completed ? props.color : '#666'};
  font-size: 1.2rem;
`;

const LabPage = () => {
  const [habits, setHabits] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [trackableDays, setTrackableDays] = useState([]);

  useEffect(() => {
    // Load habits from localStorage
    const savedHabits = JSON.parse(localStorage.getItem('habits')) || [];
    setHabits(savedHabits);

    // Generate trackable days
    const today = new Date();
    const days = [];
    for (let i = -2; i <= 2; i++) {
      const date = new Date(today);
      date.setDate(today.getDate() + i);
      days.push(date.toLocaleDateString('en-US', { weekday: 'short' }));
    }
    setTrackableDays(days);
  }, []);

  useEffect(() => {
    // Save habits to localStorage whenever they change
    localStorage.setItem('habits', JSON.stringify(habits));
  }, [habits]);

  const addHabit = (name, color) => {
    const newHabit = {
      id: Date.now(),
      name,
      color,
      tracking: Array(5).fill(false)
    };
    setHabits([...habits, newHabit]);
    setIsModalOpen(false);
  };

  const toggleHabitCompletion = (habitId, dayIndex) => {
    setHabits(habits.map(habit => 
      habit.id === habitId 
        ? { ...habit, tracking: habit.tracking.map((status, index) => index === dayIndex ? !status : status) }
        : habit
    ));
  };

  return (
    <Container>
      <Header>
        <Title>Habits</Title>
        <AddButton onClick={() => setIsModalOpen(true)}>
          <FaPlus />
        </AddButton>
      </Header>
      
      <Subheader>
        {trackableDays.map((day, index) => (
          <DayLabel key={index}>{day}</DayLabel>
        ))}
      </Subheader>

      {habits.map(habit => (
        <HabitRow key={habit.id}>
          <HabitName color={habit.color}>{habit.name}</HabitName>
          <TrackingMarkers>
            {habit.tracking.map((completed, index) => (
              <Marker 
                key={index} 
                completed={completed} 
                color={habit.color}
                onClick={() => toggleHabitCompletion(habit.id, index)}
              >
                {completed ? <FaCheck /> : <FaTimes />}
              </Marker>
            ))}
          </TrackingMarkers>
        </HabitRow>
      ))}

      <HabitModal 
        isOpen={isModalOpen} 
        onClose={() => setIsModalOpen(false)}
        onAdd={addHabit}
      />
    </Container>
  );
};

export default LabPage;