import React from 'react';
import styled from 'styled-components';
// import MathPracticeComponent from '../components/MathPracticeComponent';
import GeneralPracticeComponent from '../components/study/GeneralPracticeComponent';


const PageContainer = styled.div`
  padding: 20px;
`;

// const PageHeader = styled.h1`
//   color: #ffffff;
// `;

const StudyPage = () => {
  return (
    <PageContainer>
      {/* <PageHeader>Study</PageHeader> */}
      {/* <p>This is a placeholder for the page. We'll add more details here later!</p> */}
      {/* <MathPracticeComponent /> */}
      <GeneralPracticeComponent />
    </PageContainer>
  );
};

export default StudyPage;