import React, { createContext, useState, useContext, useCallback, useRef } from 'react';
import Toast from './components/shared/Toast';

const ToastContext = createContext();

export const ToastProvider = ({ children }) => {
  const [toasts, setToasts] = useState([]);
  const toastTimeoutRef = useRef(null);


  const removeToast = useCallback((id) => {
    setToasts(prevToasts => prevToasts.filter(toast => toast.id !== id));
    toastTimeoutRef.current = null;
  }, []);
  
  const showToast = useCallback((message, type = 'info') => {
    if (toastTimeoutRef.current) {
      clearTimeout(toastTimeoutRef.current);
    }

    setToasts(prevToasts => {
      if (prevToasts.length === 0) {
        const newToast = { id: Date.now(), message, type };
        toastTimeoutRef.current = setTimeout(() => {
          removeToast(newToast.id);
        }, 3000);
        return [newToast];
      } else {
        return prevToasts; // Don't add a new toast if one is already showing
      }
    });
  }, [removeToast]);

  

  return (
    <ToastContext.Provider value={{ showToast }}>
      {children}
      {toasts.map(toast => (
        <Toast 
          key={toast.id}
          message={toast.message}
          type={toast.type}
          onClose={() => removeToast(toast.id)}
        />
      ))}
    </ToastContext.Provider>
  );
};

export const useToast = () => useContext(ToastContext);