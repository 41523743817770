// ReplyFloatingActionButton.js

import React, { useContext } from 'react';
import FloatingActionButton from './FloatingActionButton';
import { AxiosContext } from '../../App';
// import axios from 'axios';

const ReplyFloatingActionButton = ({ originalPost, onReplyPosted }) => {
  const axiosInstance = useContext(AxiosContext);

  const handleReplySubmit = async (content) => {
    try {
      // const userString = localStorage.getItem('user');
      // if (!userString) {
      //   console.error('User not logged in');
      //   return;
      // }

      // const user = JSON.parse(userString);
      const response = await axiosInstance.post('/v2/post_reply', {
        tweet: content,
        // user_id: user.uid,
        // username: user.username,
        // profile_picture_url: user.profile_picture_url,
        tweet_id: originalPost.id,
        poster_id: originalPost.pid
      });

      if (response.status === 201) {
        console.log('Reply submitted successfully');
        onReplyPosted();
      }
    } catch (error) {
      console.error('Error submitting reply:', error);
    }
  };

  return (
    <FloatingActionButton
      onSubmit={handleReplySubmit}
      placeholder="Reply to Post?"
    />
  );
};

export default ReplyFloatingActionButton;