import React from 'react';
import styled, { keyframes } from 'styled-components';

const tick = keyframes`
  from { --t: 0; }
  to { --t: 86400000; }
`;

const CharDiv = styled.div`
  --offset-x: calc(var(--x) - 0.5);
  --abs-x: calc(max(var(--offset-x), -1 * var(--offset-x)));
  --offset-y: calc(var(--y) - 0.5);
  --abs-y: calc(max(var(--offset-y), -1 * var(--offset-y)));

  --l-pattern: 0.6;
  --l: calc(
    sin(var(--abs-x) / sin(var(--abs-y) * var(--l-pattern)) * 7 - var(--t) / 300) 
    * 100%
  ); // 3
  --h-hue: 170deg;
  // --h: calc(var(--abs-x) * var(--h-hue)); // 365deg
  --h: calc(var(--h-hue) + var(--abs-x) * 200deg); /* Adjust the multiplier for finer control */
  
  // Default value for saturation in hsl()
  --hover-saturation: 72%;

  color: hsl(var(--h) var(--hover-saturation), max(var(--l), 2%)); // 6%

  // Add transition on color
  // transition: color 400ms ease;
`;

const MainContainer = styled.main`
  --cols: ${props => props.cols};
  max-width: 600px;

  display: grid;
  grid-template-columns: repeat(var(--cols), 1fr);
  gap: 0px 6px;
  font-size: 16px;
  font-family: sans-serif;
  -webkit-animation: ${tick} 108000000ms linear infinite; // 86400000
  animation: ${tick} 108000000ms linear infinite; // 86400000
  // transition: all 0.3s ease;

  &:hover {
    // Change a property of all CharDivs on hover
    ${CharDiv} {
      --hover-saturation: 3%;
      // --h-hue: 0deg;
      --l-pattern: 3;
    }
  }

  @media (max-width: 768px) {
    font-size: 8px;
  }

`;



const AnimatedTitle = ({ title, cols = 50, n = 800 }) => {
  // const cols = 41;
  // const n = 82;
  const rows = Math.ceil(n / cols);

  return (
    <MainContainer cols={cols}>
      {Array.from({ length: n }).map((_, i) => (
        <CharDiv
          key={i}
          style={{
            '--x': ((i + 1) % cols) / cols,
            '--y': (rows - Math.floor(i / cols)) / rows,
          }}
        >
          {title.split('')[i % title.length]}
        </CharDiv>
      ))}
    </MainContainer>
  );
};

export default AnimatedTitle;