import { useState } from "react";
import styled from "styled-components";

const PageContainer = styled.div`
  // padding: 1rem;
  color: white;
`;

const Header = styled.div`
  height: 200px;
  width: 100%;
  position: fixed;
  top: 0px;
  background-color: #121212;
`;

const SubHeader = styled.div`
  height: 100px;
  width: 100%;
  position: sticky;
  top: 200px;
  background-color: #3a3a3a;
`;

const SectionBlock = styled.div`
  height: 450px;
  width: 100%;
  background-color: #838484;
`;

const SuperbPage = () => {
  
  return (
    <>
      <Header />
      <SubHeader />
      <SectionBlock>
        <p>Hi from SectionBlock</p>
      </SectionBlock>
      <PageContainer>
        <p>Hello From SuperbPage</p>
      </PageContainer>
    </>
  );
};

export default SuperbPage;
