import React, { useContext, useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';
import WebRTCManager from '../components/rooms/WebRTCManager';
import { AxiosContext } from '../App';
import { useNavigate } from 'react-router-dom';
import { BarLoader } from 'react-spinners';

const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px;
`;

const PageContainer = styled.div`
  // padding: 20px;
  height: 100%;
  // width: 100%;
  overflow-x: hidden;
`;

// const PageHeader = styled.h1`
//   color: #ffffff;
// `;

const RoomsPage = () => {
  const axiosInstance = useContext(AxiosContext);
  const [userData, setUserData] = useState(null);

  const navigate = useNavigate();

  const fetchUserProfile = useCallback(async () => {
    try {
      const response = await axiosInstance.get('/v2/user-profile');
      setUserData(response.data);
      // setLoading(false);
    } catch (err) {
      // setError('Failed to fetch user profile');
      // setLoading(false);
      if (err.response && err.response.status === 401) {
        navigate('/login');
      }
    }
  }, [axiosInstance, navigate]);

  useEffect(() => {
    fetchUserProfile();
    // adding-to-dependency-array-pending-any-new-issues-else-use->eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchUserProfile]);

  if (!userData) {
    return (
      <SpinnerContainer>
          <BarLoader color="#888888" loading={!userData} />
      </SpinnerContainer>
    );
  }

  return (
    <PageContainer>
      {/*
      <PageHeader>Page</PageHeader>
      <p>This is a placeholder for the page. We'll add more details here later!</p> 
      */}
      <WebRTCManager roomId="12345" displayName={userData.username} profilePictureUrl={userData.profile_picture_url} />
    </PageContainer>
  );
};

export default RoomsPage;