import React from 'react';
import styled from 'styled-components';
// import { Link } from 'react-router-dom';
import { FaExternalLinkAlt } from 'react-icons/fa';

// const HeaderContainer = styled.header`
//   background-color: #000000;
//   -webkit-backdrop-filter: blur(10px);
//   backdrop-filter: blur(5px);
//   color: white;
//   padding: 1rem;
//   text-align: center;
//   position: sticky;
//   top: 0;
//   transition: top 0.3s;
//   z-index: 100;
// `;

const Header = styled.header`
  background: linear-gradient(to top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
  color: white;
  padding: 2rem;
  text-align: center;
  position: fixed;
  width: 96.9vw;
  top: 0;
  transition: top 0.3s;
  z-index: 100;
  display: flex;
  justify-content: space-between; // This will push the two items to opposite ends

  @media (max-width: 1700px) {
    width: 94.9vw;
  }

  @media (max-width: 768px) {
    display: none;
    
  }
`;

const HeaderLeft = styled.span`
  text-align: left;
  display: flex;
  flex-direction: column
`
const HeaderTitle = styled.span`
  font-size: 22px;
`

const HeaderSubTitle = styled.span`
  opacity: 0.5;
  line-height: 175%;
`

const HeaderRight = styled.span`
  text-align: right;
`

const HeaderLink = styled.a`
  padding: 0.9rem;
  text-decoration: none;
  cursor: pointer;
  color: #fff;

  svg {
    padding-left: 0.3rem;
  }
`

const FounderHeader = () => {
  return (
    <Header>
        <HeaderLeft>
          <HeaderTitle>Sebastien Phillips</HeaderTitle>
          <HeaderSubTitle>Full-Stack Engineer</HeaderSubTitle>
        </HeaderLeft>
        <HeaderRight>
          <HeaderLink href='https://www.linkedin.com/in/sebastienphillips/'>
            Linkedin<FaExternalLinkAlt />
          </HeaderLink>
          <HeaderLink href='https://drive.google.com/file/d/1T6ltCCP-H0ccBAy3l-1YRC_ljH8P0Rxj/view?usp=sharing'>
            Resume
            <FaExternalLinkAlt />
          </HeaderLink>
        </HeaderRight>
      </Header>
  );
};

export default FounderHeader;