// PortfolioStyles.js
import { motion } from "framer-motion";
import styled from 'styled-components';
import { FaArrowDown } from 'react-icons/fa'; //, FaExternalLinkAlt


export const FooterWrapper = styled.div`
  display: flex;
`

export const Footer = styled.div`
  position: absolute;
  bottom: 0%;
  height: 20vh;
  width: 100%;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 75%);
  z-index: 100;
  display: flex;
  justify-content: space-between; // This will push the two items to opposite ends

  @media (max-width: 1700px) {
    height: 30vh;
  }

  @media (max-width: 768px) {
    height: 40px;
    width: 355px;
    // justify-content: start; // This will push the two items to opposite ends
    padding: 20px;
  }
`

export const FooterLeft = styled.span`
  padding-top: 115px;
  padding-left: 115px;
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    padding: 0;
  }
`

export const FooterRight = styled.span`
  padding-top: 135px;
  padding-right: 115px;
  opacity: 0.5;

  @media (max-width: 768px) {
    display: none;
    padding: 0;
    text-align: right;
    opacity: 0.25;
  }
`

export const FooterTitle = styled.span`
  font-size: 16px;

  @media (max-width: 768px) {
    opacity: 0.5;
    text-align: left;
  }
`

export const FooterSubTitle = styled.span`
  opacity: 0.5;
  line-height: 200%;
  text-align: left;

  @media (max-width: 768px) {
    opacity: 0.25;
  }
`

export const SubtTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  justify-content: space-evenly; // This will push the two items to opposite ends
`

export const FooterIcon = styled.span`
  margin-left: 5px;
  font-size: 1.5em;
  display: flex; // This helps with alignment
  align-items: center; // This ensures the icon itself is vertically centered
`

export const PortfolioContainer = styled.div`
  // width: 100vw;
  // height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  font-family: 'Arial', sans-serif;
  // position: relative;
`;

export const FullScreenSection = styled.section`
  height: 100vh;
  min-height: 600px; // Ensure a minimum height on smaller screens
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  background: #070707;
  color: #fff;
  overflow-x: hidden;
`;

export const SplashSection = styled(FullScreenSection)`
  // background: url("https://picsum.photos/1600/900") center/cover no-repeat;
  background: #000;
`;

// First, create the styled components for the arrow
export const ScrollIndicator = styled.div`
  position: absolute;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;
  z-index: 10;
`;

export const AnimatedArrow = styled(FaArrowDown)`
  color: #ffffff;
  font-size: 24px;
  opacity: 0.8;
`;

export const SectionTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 60px;
  margin-left: 40px;
  margin-right: 40px;
  height: 600px;
  width: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
  // gap: 15px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media (max-width: 1700px) {
    margin-top: 180px;
    margin-left: 5px;
    
  }

  @media (max-width: 768px) {
    font-size: 1.5rem;
    width: 300px;
    margin-top: 0;
    margin-left: 0;
    margin-right: 0;
    transform: translate(-50%, -30%);
    
  }
`

export const SectionTextMidContainer = styled.div`
  display: flex;
  flex-direction: column;
  
  margin-top: 50px;
  margin-left: 40px;
  margin-right: 40px;
  // height: 600px;
  // width: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
  gap: 0px;
  // position: absolute;
  // top: 50%;
  // left: 50%;
  // transform: translate(-50%, -95%);

  @media (max-width: 768px) {
    font-size: 1.5rem;
    // width: 300px;
    // margin-top: 0;
    // margin-left: 0;
    // margin-right: 0;
    // transform: translate(-50%, -30%);
    margin-bottom: 10px;
    padding-bottom: 40px;
    
  }
`

export const SectionTextSmall = styled.div`
  font-size: 1.85rem;
  // text-transform: uppercase;
  color: white;
  
  // margin: 35px;
  // position: absolute;
  // top: 50%;
  // left: 50%;
  // transform: translate(-50%, -50%);
  z-index: 2;
  transition: transform 0.8s ease, opacity 0.4s ease;
  opacity: 1;
  text-align: left;
  font-weight: bold;
  font-family: 'Ubuntu', sans-serif;

  @media (max-width: 1700px) {
    font-size: 1.2rem;
  }

  @media (max-width: 768px) {
    font-size: 0.9rem;
    
  }
`;

export const SectionText = styled.div`
  font-size: 3rem;
  // text-transform: uppercase;
  color: white;
  
  // margin: 35px;
  // position: absolute;
  // top: 50%;
  // left: 50%;
  // transform: translate(-50%, -50%);
  z-index: 2;
  transition: transform 0.8s ease, opacity 0.4s ease;
  opacity: 1;
  text-align: left;
  font-weight: bold;
  font-family: 'Ubuntu', sans-serif;



  @media (max-width: 1700px) {
    font-size: 2.5rem;
    
  }

  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

export const SectionTextMid = styled.div`
  font-size: 2rem;
  // text-transform: uppercase;
  color: white;
  // letter-spacing: 0.01em;
  // line-height: 50%;

  // margin: 35px;
  // transform: translate(-50%, -50%);
  z-index: 2;
  transition: transform 0.8s ease, opacity 0.4s ease;
  opacity: 1;
  text-align: left;
  font-weight: 500;
  font-family: 'Ubuntu', sans-serif;

  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

// export const SectionTextMidBackup = styled.div`
//   font-size: 2rem;
//   // text-transform: uppercase;
//   color: white;
  
//   // margin: 35px;
//   position: absolute;
//   top: 7%;
//   left: 5%;
//   // transform: translate(-50%, -50%);
//   z-index: 2;
//   transition: transform 0.8s ease, opacity 0.4s ease;
//   opacity: 1;
//   text-align: left;
//   font-weight: 400;
//   font-family: 'Ubuntu', sans-serif;

//   @media (max-width: 768px) {
//     font-size: 1.5rem;
//   }
// `;

export const SectionTextAlt = styled.div`
  font-size: 3rem;
  // text-transform: uppercase;
  color: white;
  // position: absolute;
  // top: 20%;
  // left: 50%;
  // transform: translate(-50%, -50%);
  z-index: 2;
  transition: transform 0.8s ease, opacity 0.4s ease;
  opacity: 1;
  text-align: left;
  font-weight: bold;
  font-family: 'Ubuntu', sans-serif;



  @media (max-width: 1700px) {
    font-size: 2.5rem;
    
  }

  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`;
export const SectionImageContainer = styled.div`
  // flex: 1;
  padding: 0;
  width: 95%;
  height: 600px;
  border-radius: 50%;
  object-fit: cover;
  background: none;
  clip-path: polygon(37.5% 20%, 62.5% 20%, 62.5% 80%, 37.5% 80%);
  transform: rotate(30deg);
  transition: all 0.8s ease;
  margin-top: 15px;
  // margin-bottom: 30px;
  margin-left: auto;
  margin-right: auto;
  
  

  @media (max-width: 768px) {
    width: 100%;
    height: 300px;
    margin-top: 0;
  }
`;

export const SectionVideoContainer = styled.div`
  // flex: 1;
  padding: 0;
  // width: 95%;
  // height: 600px;
  border-radius: 50%;
  object-fit: cover;
  background: none;
  clip-path: polygon(37.5% 20%, 62.5% 20%, 62.5% 80%, 37.5% 80%);
  transform: rotate(30deg);
  transition: all 0.8s ease;
  margin-top: 15px;
  // margin-bottom: 30px;
  margin-left: auto;
  margin-right: auto;
  // position: absolute;
  // right: 0px;
  
  

  @media (max-width: 768px) {
    width: 80vw;
    // height: 300px;
    margin-top: 0;
  }
`;

export const SectionImage = styled.img`
  // flex: 1;
  padding: 0;
  margin-left: 350px;
  margin-right: -70px;
  width: 50%;
  height: 450px;
  border-radius: 25px;
  object-fit: cover;
  background: none;
  transform: scale(2);
  transition: all 0.8s ease;
  
  @media (max-width: 1700px) {
    margin-left: 300px;
    height: 350px;
  }

  @media (max-width: 768px) {
    width: 300px;
    margin-left: 0;
    margin-right: 0;
    height: 250px;
    
  }
`;

export const SectionImageBackground = styled.div`
  // background: linear-gradient(45deg, #212121 0%, #171717 33%, #0E0E0E 66%, #070707 100%);
  background: none;


  // width: 95%;
  max-width: 950px;
  height: 450px;
  // max-height: 150px;
  padding: 0px 0px 15px;
  border-radius: 25px;
  position: relative;
  // margin-top: 150px;
  
  &:hover ${SectionImage} {
    transform: scale(1);
  }

  &:hover ${SectionImageContainer} {
    transform: rotate(0deg);
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
  }

  &:hover ${SectionText} {
    transform: translateX(-100%);
    opacity: 0;
  }

  &:hover ${SectionTextAlt} {
    transform: translateX(-200%);
    opacity: 0;
  }

  @media (max-width: 1700px) {
    max-width: 950px;
    height: 350px;
    padding: 35px 0px 0px;
  }
  
  @media (max-width: 768px) {
    width: 300px;
    height: 250px;
    padding: 0;
    // margin-left: 0;
    // margin-right: 0;
    
  }

  

`

export const SectionVideoBackground = styled.div`
  // background: linear-gradient(45deg, #212121 0%, #171717 33%, #0E0E0E 66%, #070707 100%);
  background: none;


  width: 75%;
  // max-width: 950px;
  // height: 65%;
  max-height: 55vh;
  
  // max-height: 150px;
  // padding: 35px 0px 15px;
  border-radius: 15px;
  // position: absolute;
  // top: 10%;
  // left: 2%;
  // margin-top: 150px;
  margin: auto;
  
  
  &:hover ${SectionImage} {
    transform: scale(1);
  }

  &:hover ${SectionImageContainer} {
    transform: rotate(0deg);
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
  }

  &:hover ${SectionText} {
    transform: translateX(-100%);
    opacity: 0;
  }

  &:hover ${SectionTextAlt} {
    transform: translateX(-200%);
    opacity: 0;
  }
  
  @media (max-width: 1700px) {
    width: 65%;
  }

  @media (max-width: 768px) {
    width: 60vw;
    height: 50vh;
    padding: 0;
    margin: 0;
    // margin-right: 0;
    
  }

`

export const IntroSection = styled(FullScreenSection)`
  // background: #1a1a1a;
  background: linear-gradient(45deg, #212121 0%, #171717 33%, #0E0E0E 66%, #070707 100%);
`;

export const SkillsSection = styled(FullScreenSection)`
  background: linear-gradient(315deg, #070707 0%, #0E0E0E 33%, #171717 66%, #212121 100%);
`;

export const ProjectsSection = styled(FullScreenSection)`
  // background: #3a3a3a;
  background: linear-gradient(47deg, #212121 0%, #171717 33%, #0E0E0E 66%, #070707 100%);
`;

export const FooterSection = styled(FullScreenSection)`
  // background: #4a4a4a;
  background: linear-gradient(313deg, #070707 0%, #0E0E0E 33%, #171717 66%, #212121 100%);
  position: relative;
`;

export const ProjectCard = styled.div`
  background-color: rgba(0, 0, 0, 0.4);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(5px);
  position: relative;
  display: flex;
  flex-direction: column;
  // justify-content: center;
  // justify-content: space-between;
  // align-items: center;
  height: 80%;
  // width: ${({ $mobileView }) => $mobileView ? '35%' : '65%'};
  width: 65%;
  border: 10px solid rgba(255, 255, 255, 0.03);
  border-radius: 20px; /* Adjust this value for more or less rounded corners */
  box-shadow: 0 40px 80px #00000080;
  transition: background-color 0.3s ease, background 0.3s ease, width 1s ease;

  @media (max-width: 768px) {
    width: 90%;
    height: 60%;
    // align-items: center;
  }

  // &:hover {
  //   background-color: rgba(0, 0, 0, 0.3);
  //   // background: linear-gradient(313deg, #070707 0%, #0E0E0E 33%, #171717 66%, #212121 100%);
  //   // background: linear-gradient(45deg, #9e87cf 0%, #1ea7b7 33%, #16488c 66%, #874fbe 100%);
  // }
`

// export const ProjectViewToggle = styled.button`
//   position: absolute;
//   top: 3%;
//   right: 5%;
//   // color: rgba(0, 0, 0, 0)
//   // background-color: transparent;
//   // border: none;
//   background: none;
// 	color: inherit;
// 	border: none;
//   padding: 30px;
//   cursor: pointer;
  

//   svg {
//     font-size: 1.7em;
//     color: #fff
//     // padding: 30px;
//     cursor: pointer;
//   }
// `

export const ContactCard = styled.div`
  // background-color: rgba(0, 0, 0, 0.4);
  // -webkit-backdrop-filter: blur(10px);
  // backdrop-filter: blur(5px);
  // position: relative;
  display: flex;
  flex-direction: column;
  // justify-content: center;
  justify-content: space-evenly;
  // align-items: center;
  height: 80%;
  width: 65%;
  // border: 10px solid rgba(255, 255, 255, 0.03);
  // border-radius: 20px; /* Adjust this value for more or less rounded corners */
  // box-shadow: 0 40px 80px #00000080;
  // transition: background-color 0.3s ease, background 0.3s ease;

  @media (max-width: 768px) {
    width: 85%;
  }

  // &:hover {
  //   background-color: rgba(0, 0, 0, 0.3);
  //   // background: linear-gradient(313deg, #070707 0%, #0E0E0E 33%, #171717 66%, #212121 100%);
  //   // background: linear-gradient(45deg, #9e87cf 0%, #1ea7b7 33%, #16488c 66%, #874fbe 100%);
  // }
`

// export const Title = styled.h1`
//   font-size: 3rem;
//   margin-bottom: 1rem;
//   opacity: 0;
// `;

export const ContactTitle = styled.h2`
  font-size: 2.5rem;
  margin-bottom: -3rem;

  @media (max-width: 768px) {
    font-size: 2rem;
    margin-left: -10px;
  }
`;

export const ContactForm = styled.form`
  padding: 5px;
  display: flex;
  flex-direction: column;
`

// export const Subtitle = styled.h2`
//   font-size: 1.5rem;
//   margin-bottom: 2rem;
//   opacity: 0;
// `;

// export const Content = styled.div`
//   max-width: 800px;
//   opacity: 0;
// `;

// export const SkillsList = styled.ul`
//   list-style-type: none;
//   padding: 0;
//   display: flex;
//   flex-wrap: wrap;
//   justify-content: center;
// `;

// export const SkillItem = styled.li`
//   background: #fff;
//   color: #070707;
//   padding: 0.5rem 1rem;
//   margin: 0.5rem;
//   border-radius: 20px;
//   opacity: 0;
// `;

// export const ProjectGrid = styled.div`
//   display: grid;
//   grid-template-columns: repeat(2, 1fr);
//   gap: 2rem;
//   max-width: 800px;
// `;

// export const ProjectCardTemp = styled.div`
//   background: #fff;
//   color: #070707;
//   padding: 1rem;
//   border-radius: 10px;
//   opacity: 0;
// `;

// export const ProjectImage = styled.img`
//   width: 100%;
//   height: 150px;
//   object-fit: cover;
//   border-radius: 5px;
//   margin-bottom: 1rem;
// `;

export const SkillsBackground = styled.div`
  // background: linear-gradient(45deg, #91E2B6 0%, #118ECF 33%, #15CC94 66%, #91C40E 100%);
  // background: linear-gradient(45deg, #9e87cf 0%, #1ea7b7 33%, #16488c 66%, #874fbe 100%);
  background: none;
  padding: 0;
  border-radius: 25px;
  // box-shadow: 10px 5px 5px red;
  margin-top: 75px;

  @media (max-width: 768px) {
    padding: 0;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 50px;
  }
`

export const SkillsForeground = styled.div`
  background-color: rgba(0, 0, 0, 0.4);
  -webkit-backdrop-filter: blur(50px);
  backdrop-filter: blur(50px);
  padding: 35px;
  border: 10px solid rgba(255, 255, 255, 0.03);
  border-radius: 25px;
  // margin-bottom: 25px;

  @media (max-width: 768px) {
      padding: 15px;
  }
`

export const SkillsContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 100px; /* Adjust height as necessary */
    width: 800px;
    max-width: 800px;
    margin-bottom: 25px;

    @media (max-width: 768px) {
      height: 50px; /* Adjust height as necessary */
      margin-bottom: 50px;
      width: 225px;
    }

    @media (max-width: 1700px) {
    max-width: 650px;
    height: 70px; /* Adjust height as necessary */
    }
`;

export const SkillIcon = styled.div`
    position: relative;
    display: inline-block;
    font-size: 50px; /* Adjust size as needed */
    margin: 20px; /* Adjust spacing */
    transform: rotate(${(props) => props.rotation}deg);
    // animation: float 3s ease-in-out infinite;
    transition: transform 0.3s;

    &:hover {
        transform: scale(1.1) rotate(${(props) => props.rotation}deg);
    }

    @keyframes float {
        0%, 100% {
            transform: translateY(0);
        }
        50% {
            transform: translateY(-10px);
        }
    }

    &:hover span {
      opacity: 1;
      transform: translateY(-10px);
      transform: rotate(0deg);
    }

    @media (max-width: 768px) {
      font-size: 32px; /* Adjust size as needed */
      margin: 5px; /* Adjust spacing */
    }

    @media (max-width: 1700px) {
    font-size: 40px; /* Adjust size as needed */
    }
`;

export const Tooltip = styled.span`
  position: absolute;
  bottom: -35px;
  left: -20%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  white-space: nowrap;
  font-size: 12px;
  opacity: 0;
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
  pointer-events: none;
`;

export const StyledVideo = styled.video`
  // margin-top: 7vh;
  // margin-bottom: 15px;
  // margin-left: 30px;
  width: 100%;
  // max-height: 55vh;
  border-radius: 15px; /* Adjust this value for more or less rounded corners */
  overflow: hidden; /* Ensures that the content inside the border doesn't overflow */
  // box-shadow: 0px 4px 10px rgba(255, 255, 255, 0.2); /* Optional: Adds a shadow for a nicer effect */
  border: 1px solid rgba(255, 255, 255, 0.2); /* Outer border */

  @media (max-width: 768px) {
    width: 70vw;
    // max-height: 100vh;
    margin-left: 30px;
    // margin-right: 0;
    
  }
`;

// export const StyledVideoAlt = styled.video`
//   width: 100%;
//   max-height: 50vh;
//   object-fit: contain;
//   margin-bottom: 2rem;
// `;

// export const StyledVideo2 = styled.video`
//   width: 100%;
//   max-height: 50vh;
//   object-fit: contain;
//   margin-bottom: 2rem;
// `;

export const ShinyButton = styled(motion.button)`
  margin: auto;
  width: 10vw;
  position: relative;
  font-size: 1rem;
  // font-weight: bold;
  padding-left: 1.5rem; /* 24px */
  padding-right: 1.5rem; /* 24px */
  padding-top: 1rem; /* 8px */
  padding-bottom: 1rem; /* 8px */
  margin-bottom: 3vh;
  border-radius: 0.5rem;
  color: rgb(212 212 212);
  cursor: pointer;
  background: radial-gradient(
    circle at 50% 0%,
    rgba(250, 250, 250, 0.05) 0%,
    transparent 60%
  ), rgba(15, 15, 15, 1);
  border: none;
  mask-image: linear-gradient(
    -75deg,
    white calc(var(--x) + 20%),
    transparent calc(var(--x) + 30%),
    white calc(var(--x) + 100%)
  );
  -webkit-mask-image: linear-gradient(
    -75deg,
    white calc(var(--x) + 20%),
    transparent calc(var(--x) + 30%),
    white calc(var(--x) + 100%)
  );

  @media (max-width: 768px) {
    width: 50vw;
    // margin-left: 0;
    // margin-right: 0;
    
  }
`

// export const ShinyButtonBackup = styled(motion.button)`
//   position: relative;
//   font-size: 1rem;
//   // font-weight: bold;
//   padding-left: 1.5rem; /* 24px */
//   padding-right: 1.5rem; /* 24px */
//   padding-top: 1rem; /* 8px */
//   padding-bottom: 1rem; /* 8px */
//   margin-top: 55%;
//   border-radius: 0.5rem;
//   color: rgb(212 212 212);
//   cursor: pointer;
//   background: radial-gradient(
//     circle at 50% 0%,
//     rgba(250, 250, 250, 0.05) 0%,
//     transparent 60%
//   ), rgba(15, 15, 15, 1);
//   border: none;
//   mask-image: linear-gradient(
//     -75deg,
//     white calc(var(--x) + 20%),
//     transparent calc(var(--x) + 30%),
//     white calc(var(--x) + 100%)
//   );
//   -webkit-mask-image: linear-gradient(
//     -75deg,
//     white calc(var(--x) + 20%),
//     transparent calc(var(--x) + 30%),
//     white calc(var(--x) + 100%)
//   );
// `

export const ButtonOverlay = styled.span`
  position: absolute;
  inset: 0px;
  // height: 100%;
  padding: 2px;
  border-radius: 0.5rem;
  background-image: linear-gradient(
    -75deg,
    rgba(255, 255, 255, 0.1) calc(var(--x) + 20%),
    rgba(255, 255, 255, 0.5) calc(var(--x) + 25%),
    rgba(255, 255, 255, 0.1) calc(var(--x) + 100%)
  );
`
export const ButtonText = styled.span`
	// display: block;
  // position: relative;
  letter-spacing: 0.025em;
  height: 100%;
  width: 100%;
  font-weight: 300;
`

// export const HeaderText = styled.div`
//   text-align: left;
//   margin-bottom: 2rem;
// `;

// export const Title = styled.h2`
//   font-size: 2.5rem;
//   margin-bottom: 0.5rem;
// 
//   @media (max-width: 768px) {
//     font-size: 2rem;
//   }
// `;

export const SubTitle = styled.h3`
  font-size: 1.25rem;
  text-align: left;
  font-weight: 300;
  line-height: 25%;
  letter-spacing: 0.01em;

  // font-weight: normal;
  opacity: 0.8;

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

export const SubTitleMuted = styled.span`
  font-size: 1rem;
  text-align: left;
  font-weight: 200;
  line-height: 25%;
  letter-spacing: 0.01em;

  // font-weight: normal;
  font-family: monospace;
  opacity: 0.25;

  @media (max-width: 768px) {
    font-size: 1rem;
    display: none;
  }
`;

// export const ShinyButton2 = styled.button`
//   padding: 1rem 2rem;
//   font-size: 1rem;
//   background: linear-gradient(45deg, #ff6b6b, #4ecdc4);
//   border: none;
//   border-radius: 50px;
//   color: white;
//   cursor: pointer;
//   transition: transform 0.3s ease;

//   &:hover {
//     transform: scale(1.05);
//   }
// `;

export const Input = styled.input`
  color: white;
  background: #212121;
  padding: 0.5rem;
  margin-bottom: 1rem;
  border: 1px solid #797979;
  border-radius: 5px;
  font-size: 1rem;

  @media (max-width: 768px) {
    width: 90%;
  }
`;

export const TextAreaInput = styled.textarea`
  color: white;
  background: #212121;
  padding: 0.5rem;
  margin-bottom: 1rem;
  border: 1px solid #797979;
  border-radius: 5px;
  font-size: 1rem;
  width: 20vw;
  height: 10vh;

  @media (max-width: 768px) {
    width: 90%;
  }
`;

export const ProjectWrapper = styled.div`
  height: 80vh;
  display: flex;
  flex-direction: column;
  // justify-content: space-between;

  @media (max-width: 768px) {
    height: 100vh;
    width: 200px
    justify-content: start;
    
  }
`

export const ProjectHeader = styled.span`
  font-size: 1.75rem;
  font-weight: 500;
  padding-top: 35px;
  padding-bottom: 15px;
`

export const CardWrapper = styled.div`
  display: flex;
  width: 65vw;
  height: 60vh;
  justify-content: space-evenly;

  @media (max-width: 768px) {
    flex-direction: column;
    width: 85%;
    height: 100%;
    justify-content: start;
    margin-bottom: 10px;
  }
`

export const Card = styled.div`
  background-color: rgba(0, 0, 0, 0.4);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(5px);
  margin: 20px;
  padding: 35px 27px 15px;
  position: relative;
  display: flex;
  flex-direction: column;
  // justify-content: center;
  // justify-content: space-between;
  // align-items: center;
  height: 100%;
  width: 100%;
  border: 10px solid rgba(255, 255, 255, 0.03);
  border-radius: 20px; /* Adjust this value for more or less rounded corners */
  box-shadow: 0 40px 80px #00000080;
  transition: background-color 0.3s ease, background 0.3s ease, width 1s ease;

  @media (max-width: 768px) {
    width: 90%;
    margin: 5px;
  }

  // &:hover {
  //   background-color: rgba(0, 0, 0, 0.3);
  //   // background: linear-gradient(313deg, #070707 0%, #0E0E0E 33%, #171717 66%, #212121 100%);
  //   // background: linear-gradient(45deg, #9e87cf 0%, #1ea7b7 33%, #16488c 66%, #874fbe 100%);
  // }

  &:hover::after {
    content: '';
    position: absolute;
    top: -10px; /* Match the existing border thickness */
    left: -10px; /* Match the existing border thickness */
    right: -10px; /* Match the existing border thickness */
    bottom: -10px; /* Match the existing border thickness */
    border: 1px solid rgba(255, 255, 255, 0.2); /* Outer border */
    border-radius: 20px; /* Match or slightly larger than the parent border */
    pointer-events: none; /* Prevent interaction with the pseudo-element */
    transition: border 0.3s ease;
  }

`
export const LLMCard = styled(Card)`
  // background: #1a1a1a;
  // background: linear-gradient(45deg, #212121 0%, #171717 33%, #0E0E0E 66%, #070707 100%);
  // border: none;
  background-image: 
    linear-gradient(to bottom, rgba(17, 17, 17, 1) 25%, rgba(17, 17, 17, 0.8) 85%, rgba(17, 17, 17, 0.6) 95%), 
    url("https://www.iiicoast.com/backend-static/upload/llama.png");
  background-size: contain;
  background-position: center;
  /* Restrict the background image to the content box */
  background-clip: padding-box;
  // border: 10px solid rgba(255, 255, 255, 0.5);

  // &:after {
  //   content: '';
  //   position: absolute;
  //   bottom: 0%; /* Start the gradient halfway up */
  //   left: 0;
  //   right: 0;
  //   height: 100%; /* Full height of the overlay */
  //   background: linear-gradient(to top, rgba(7, 7, 7, 0.3) 0%, rgba(7, 7, 7, 0.9) 75%);
  //   border-radius: 9px;
  // }
`;

export const AutoCard = styled(Card)`
  // background: #1a1a1a;
  // background: linear-gradient(45deg, #212121 0%, #171717 33%, #0E0E0E 66%, #070707 100%);
  // border: none;
  background-image: 
    linear-gradient(to bottom, rgba(17, 17, 17, 1) 25%, rgba(17, 17, 17, 0.8) 85%, rgba(17, 17, 17, 0.6) 95%), 
    url("https://www.iiicoast.com/backend-static/upload/invertautomation.png");
  background-size: cover;
  background-position: center;
  /* Restrict the background image to the content box */
  background-clip: padding-box;
  // border: 10px solid rgba(255, 255, 255, 0.5);
  

  // &:after {
  //   content: '';
  //   position: absolute;
  //   bottom: 0%; /* Start the gradient halfway up */
  //   left: 0;
  //   right: 0;
  //   height: 100%; /* Full height of the overlay */
  //   background: linear-gradient(to top, rgba(7, 7, 7, 0.3) 0%, rgba(7, 7, 7, 0.9) 75%);
  //   border-radius: 9px;
  // }

  @media (max-width: 1700px) {
    padding: 35px 29px 15px;
  }
`;

export const ThreeDCard = styled(Card)`
  // background: #1a1a1a;
  // background: linear-gradient(45deg, #212121 0%, #171717 33%, #0E0E0E 66%, #070707 100%);
  // border: none;
  background-image: 
    linear-gradient(to bottom, rgba(17, 17, 17, 1) 25%, rgba(17, 17, 17, 0.8) 85%, rgba(17, 17, 17, 0.6) 95%), 
    url("https://www.iiicoast.com/backend-static/upload/3ddesign.png");
  background-size: cover;
  background-position: center;
  /* Restrict the background image to the content box */
  background-clip: padding-box;
  // border: 10px solid rgba(255, 255, 255, 0.5);

  // &:after {
  //   content: '';
  //   position: absolute;
  //   bottom: 0%; /* Start the gradient halfway up */
  //   left: 0;
  //   right: 0;
  //   height: 100%; /* Full height of the overlay */
  //   background: linear-gradient(to top, rgba(7, 7, 7, 0.3) 0%, rgba(7, 7, 7, 0.9) 75%);
  //   border-radius: 9px;
  // }
`;


export const ProjectText = styled.div`
  font-size: 2rem;
  // text-transform: uppercase;
  color: white;
  // letter-spacing: 0.01em;
  // line-height: 50%;

  // margin: 35px;
  // transform: translate(-50%, -50%);
  z-index: 2;
  transition: transform 0.8s ease, opacity 0.4s ease;
  opacity: 1;
  text-align: left;
  font-weight: 500;
  font-family: 'Ubuntu', sans-serif;

  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

export const CardSubTitle = styled.h3`
  font-size: 1.25rem;
  text-align: left;
  font-weight: 300;
  margin-top: 9px;
  line-height: 100%;
  letter-spacing: 0.01em;
  color: #fff;
  z-index: 100;

  // font-weight: normal;
  // opacity: 0.8;

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;