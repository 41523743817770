import { useState } from "react";
import styled from "styled-components";

const PageContainer = styled.div`
  // padding: 1rem;
  color: white;
`;

const Header = styled.div`
  height: 100px;
  width: 100%;
  // position: fixed;
  // top: 0px;
  background-color: #121212;
  display: flex;
  justify-content: space-between;
`;

const SubHeader = styled.div`
  height: 75px;
  width: 100%;
  position: sticky;
  top: 0px;
  background-color: #3a3a3a;
  display: flex;
  // gap: 10px;
  color: white;
  justify-content: space-between;
  align-items: center;
`;

const SectionBlock = styled.div`
  // margin-top: 200px;
  height: 450px;
  width: 100%;
  background-color: #838484;
`;

const HeaderLogo = styled.img`
  // width: 200px;
  padding-left: 17px;
  height: 100px;
`;

const HeaderContactSection = styled.div`
  padding: 10px;
  display: flex;
`;

const NavSection = styled.div`
  display: flex;
  gap: 25px;
  height: 100%;
  align-items: center;
  padding-left: 30px;
`;

const NavItem = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`;

const NavItemActive = styled.div`
  border-bottom: 3px solid #ffd61f;
  box-sizing: border-box;
  height: 100%;
  display: flex;
  align-items: center;
`;

const NavButton = styled.div`
  height: 100%;
  background-color: #ffd61f;
  width: 300px;
  margin-right: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SuperbPage = () => {
  
  return (
    <>
      <Header>
        <HeaderLogo src="https://www.iiicoast.com/backend-static/upload/logo_retina.png" />
        <HeaderContactSection>
          <p>
            Contact 1
          </p>
          <p>
            Contact 2
          </p>
        </HeaderContactSection>
      </Header>
      <SubHeader>
        <NavSection>
          <NavItemActive>Home</NavItemActive>
          <NavItem>Home</NavItem>
          <NavItem>Home</NavItem>
          <NavItem>Home</NavItem>
          <NavItem>Home</NavItem>
          <NavItem>Home</NavItem>
        </NavSection>
        <NavButton>
          Free Consultation
        </NavButton>
      </SubHeader>
      <SectionBlock>
        <p>Hi from SectionBlock</p>
      </SectionBlock>
      <SectionBlock>
        <p>Hi from SectionBlock</p>
      </SectionBlock>
      <SectionBlock>
        <p>Hi from SectionBlock</p>
      </SectionBlock>
      <PageContainer>
        <p>Hello From SuperbPage</p>
      </PageContainer>
    </>
  );
};

export default SuperbPage;
