import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const HeaderContainer = styled.header`
  background-color: #000000;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(5px);
  color: white;
  padding: 1rem;
  text-align: center;
  position: sticky;
  top: 0;
  transition: top 0.3s;
  z-index: 100;
`;

const OddHeader = () => {
  return (
    <HeaderContainer>
      <Link to="/test">
        <img src="https://www.iiicoast.com/backend-static/upload/oddjobs-logo-white.png" alt="Logo" width="100px" />
      </Link>
    </HeaderContainer>
  );
};

export default OddHeader;