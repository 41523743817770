import React from 'react';

const ControlButtons = ({ toggleAudio, toggleVideo, audioMuted, videoMuted }) => {
  return (
    <div className="control_buttons">
      <button id="toggleAudioButton" className="toggle-button" onClick={toggleAudio}>
        {audioMuted ? "Unmute Audio" : "Mute Audio"}
      </button>
      <button id="toggleVideoButton" className="toggle-button" onClick={toggleVideo}>
        {videoMuted ? "Unmute Video" : "Mute Video"}
      </button>
    </div>
  );
};

export default ControlButtons;
