import React, { useState, useEffect } from 'react'; // , useContext, useRef
// import { AuthContext } from '../AuthContext';
import axiosInstance from '../../axiosConfig';
// import useLongPolling from '../hooks/useLongPolling'; // Adjust path accordingly
// import { AxiosContext } from '../App';
import styled from 'styled-components';
import { FaBell, FaTimes } from 'react-icons/fa'; //, FaCog, FaChevronRight, FaSearch, FaRegComments, FaMusic, FaRocket, FaTh, FaDoorOpen, FaBars, FaUserPlus, FaDoorClosed, FaHome, FaUser, 
import useWindowSize from '../../hooks/useWindowSize';
// import { NavLink, Link } from 'react-router-dom';
import { useSwipeable } from 'react-swipeable';
// import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const SidebarContainer = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  width: ${({ $isMobile }) => $isMobile ? '95%' : '340px'};
  height: ${({ $isMobile }) => $isMobile ? '100%' : '100vh'};
  background-color: #070707;
  border-left: 1px solid #111;
  color: white;
  padding: ${({ $isMobile }) => $isMobile ? '40px 10px 20px 10px' : '20px 20px 10px 20px'};
  transition: all 0.3s ease, width 0.3s ease; /* Smooth sliding effect */
  z-index: 1015;
  overflow-x: hidden;

  transform: ${({ $minimized }) => $minimized ? 'translateX(100%)' : 'translateX(0)'};
  overflow-y: auto; /* Add this to enable scrolling within the tray if needed */

  @media (max-width: 768px) {
    transform: ${({ $minimized }) => $minimized ? 'translateX(100%)' : 'translateX(0)'};
    // right: -90px;
    // width: 100%;
  }
`;

const BellContainer = styled.div`
  position: relative;
  display: inline-block;
`;

const ExpandButton = styled.button`
  display: block;
  position: fixed;
  top: 10px;
  right: 10px;
  background-color: ${({ $minimized }) => $minimized ? 'rgba(0,0,0,0)' : '#070707'}; // #000000; // #070707
  color: white;
  border: none;
  padding: 10px;
  margin-right: ${({ $minimized }) => $minimized ? '7px' : '7px'}; // #000000; // #070707
  border-radius: 50%;
  cursor: pointer;
  font-size: 1.5rem;
  width: 50px; /* Adjust as needed */
  height: 50px; /* Adjust as needed */
  z-index: 1016;

  @media (max-width: 768px) {
    
  }

  /* Style for the icons */
  svg {
    padding-top: 2px;
    width: 20px; /* Adjust as needed Added to fix oval with width/height on bg circle may need additional adjustments*/ 
    height: 20px; /* Adjust as needed */
    color: #212121;
  }
`;

const Badge = styled.span`
  position: absolute;
  top: -10px;
  right: -12px;
  background-color: red;
  color: white;
  padding: 2px 6px;
  border-radius: 12px;
  font-size: 12px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
`;

// const SidebarLink = styled(NavLink)`
//   background: none;
//   border: none;
//   color: #888888;
//   font-size: 1.5rem;
//   cursor: pointer;
//   display: flex;
//   align-items: center;
//   margin-left: 5px;
//   margin-bottom: 20px;
//   text-decoration: none;
//   transition: color 0.2s ease;
//   
//   &.active {
//     color: #ffffff;
//     font-weight: bold;
//   }
// 
//   &:hover {
//     color: #ffffff;
//   }
// 
//   span {
//     font-size: 18px;
//     margin-left: 10px;
//     display: ${({ $minimized }) => ($minimized ? 'none' : 'inline')};
//   }
// `;

// const SidebarButton = styled(Link)`
//   background: none;
//   border: none;
//   color: #888888;
//   font-size: 1.5rem;
//   cursor: pointer;
//   display: flex;
//   align-items: center;
//   margin-left: 5px;
//   margin-bottom: 20px;
//   text-decoration: none;
//   transition: color 0.2s ease;
//   
//   &:hover {
//     color: #ffffff;
//   }
// 
//   span {
//     font-size: 18px;
//     margin-left: 10px;
//     // display: ${({ $minimized }) => ($minimized ? 'none' : 'inline')};
//   }
// `;

// const SidebarButton2 = styled.button`
//   background: none;
//   border: none;
//   color: #888888;
//   font-size: 1.5rem;
//   cursor: pointer;
//   display: flex;
//   align-items: center;
//   margin-left: 5px;
//   margin-bottom: 20px;
//   transition: color 0.2s ease;
// 
//   &:hover {
//     color: #ffffff;  // Full white on hover
//   }
// 
//   span {
//     font-size: 18px;
//     margin-left: 10px;
//     display: ${({ $minimized }) => ($minimized ? 'none' : 'inline')};
//   }
// `;

const NotificationHeader = styled.div`
  display: flex;
  padding-left: 5px;
`

const ClearAllButton = styled.button`
  background-color: #070707;
  color: #888888;
  border: none;
  margin-top: 18px;
  margin-left: 15px;
  height: 35px;
  border-radius: 8px;
  cursor: pointer;

  &:hover {
    color: #ffffff;  // Full white on hover
    border: 1px solid #212121;
  }
`

const NotificationItem = styled.div`
  background-color: #1a1a1a;
  border-radius: 5px;
  padding: 15px;
  margin-bottom: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  position: relative; 
`;

const NotificationTitle = styled.p`
  margin: 0;
  font-size: 0.9rem;
`;

const ProfilePicture = styled.img`
  padding: 5px;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  vertical-align: middle;
`;

const NotificationContent = styled.p`
  margin: 5px 0 0;
  font-size: 0.8rem;
  color: #888888;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  color: #fff;
  font-size: 1.2rem;
  cursor: pointer;
`;

const NotificationTray = ({ unreadCount, notifications }) => {
    // const { isAuthenticated, logout } = useContext(AuthContext);
    const [localNotifications, setLocalNotifications] = useState(notifications);
    const [localUnreadCount, setLocalUnreadCount] = useState(unreadCount);
    const [minimized, setMinimized] = useState(true);
    const [isDismissed, setIsDismissed] = useState(false);
    // const [initialUnreadCount, setInitialUnreadCount] = useState(0);
    const { width } = useWindowSize();
    // const {  } = useLongPolling();
    const displayCount = localUnreadCount > 999 ? '1k+' : localUnreadCount;
    const isMobile = width <= 768;
    const navigate = useNavigate();

    // const notifications = [
    //     { id: 1, title: 'New Comment', content: 'You have a new comment on your post.' },
    //     { id: 2, title: 'New Like', content: 'Someone liked your post.' },
    //     { id: 3, title: 'New Follower', content: 'You have a new follower.' },
    //     { id: 4, title: 'Message Received', content: 'You have a new message.' },
    //     { id: 5, title: 'System Update', content: 'System maintenance scheduled for tomorrow.' },
    // ];
    
    // const localNotificationsRef = useRef(localNotifications); // Ref to keep the latest unreadCount
    // localNotificationsRef.current = localNotifications; // Update ref with the latest unreadCount

    const handleMinimize = () => {
      setMinimized(!minimized);
      // onMinimize(!minimized);
    };

    useEffect(() => {
      // if (notifications.length > localNotifications.length) {
        setLocalNotifications(notifications);
      // }
    }, [notifications]);

    useEffect(() => {
      // if (notifications.length > localNotifications.length) {
        setLocalUnreadCount(unreadCount);
      // }
    }, [unreadCount]);

    // Ensure the sidebar is minimized on mobile when the component mounts
    useEffect(() => {
      if (isMobile) {
        setMinimized(true);
      }
    }, [isMobile]);

    useEffect(() => {
        if (!minimized) {
          document.body.style.overflow = 'hidden';
        } else {
          document.body.style.overflow = '';
        }
        return () => {
          document.body.style.overflow = '';
        };
    }, [minimized]);
    
    useEffect(() => {
        // If the unread count changes via long polling, reset isDismissed
        // if (unreadCount !== initialUnreadCount) {
        if (unreadCount > 0) {
          // setInitialUnreadCount(unreadCount);
          setIsDismissed(false);
        }
      }, [unreadCount]);

    // Combined click handler for the Logout button
    // const handleLogoutClick = () => {
    //   if (isMobile) {
    //     setMinimized(true); // Minimize sidebar on mobile
    //   }
    //   onLogout(); // Perform logout action
    // };

    const swipeHandlers = useSwipeable({
        onSwipedRight: () => {
          if (!minimized) {
            handleMinimize();
          }
        },
        preventDefaultTouchmoveEvent: true,
        trackMouse: true, // Allows swipe gestures with mouse
    });

    const dismissAllNotifications = async () => {
        setIsDismissed(true)
        try {
            let response;
            response = await axiosInstance.post('/v2/dismiss_all_notifications');
            console.log(response)
          } catch (error) {
            console.error('Error dismissing all notifications:', error);
          }
    }
    
    // const removeNotification = (notification_id) => {
    //   setLocalNotifications(prevNotifications => {
    //     const updatedNotifications = prevNotifications.filter(notif => notif.nid !== notification_id);
    //     console.log('Updated Notifications:', updatedNotifications); // Debug log
    //     return updatedNotifications;
    //   });
    // };

    const dismissNotification = async (notification_id) => {
        // Optimistic update
        // removeNotification(notification_id)
        // console.log(localNotifications)
        setLocalNotifications(prevNotifications => 
          prevNotifications.filter(notif => notif.nid !== notification_id)
        );

        setLocalUnreadCount(localUnreadCount - 1)
        // console.log(localNotifications)
        try {
          // const userString = localStorage.getItem('user');
          // if (!userString) {
          //   console.error('User not logged in');
          //   return;
          // }
    
          // const user = JSON.parse(userString);
          let response;

          response = await axiosInstance.post('/v2/dismiss_notification', {
            notification_id: notification_id,
          });
          console.log(response)
    
          // if (response.status === 204) {
          //   setLikeCount(prevCount => prevCount + 1);
          //   setIsLiked(true);
          //   if (onLike) onLike(post.id);
          // }
        } catch (error) {
          console.error('Error liking post:', error);
          // Revert the optimistic update if the API call fails
          setLocalNotifications(notifications);
        }
      };

    // const handleResetAndNavigate = () => {
    //   if (isMobile) {
    //     handleMinimize(); // Call minimize function if on mobile
    //   }
    //   // refreshPosts();
    //   // Scroll to top
    //   window.scrollTo(0, 0);
    // };

    // const handleLogoutClick = () => {
    //   if (isMobile) {
    //     handleMinimize(); // Call minimize function if on mobile
    //   }
    //   logout(); // Call the logout function passed from parent
    // };

    const handleMessageNotificationClick = (notification) => {
      if (notification.cid) {
          navigate(`/conversation/${notification.cid}`);
          handleMinimize();
      }
    };

    return (
      <>
        <ExpandButton $minimized={minimized} onClick={handleMinimize}>
          {minimized ? 
          <>
          <BellContainer>
            <FaBell />
            {isDismissed ? (
              <></>
            ) : (
              localUnreadCount > 0 && <Badge>{displayCount}</Badge>
            )}
          </BellContainer>
          </>
          : <FaTimes />}
        </ExpandButton>
        <SidebarContainer
          $minimized={minimized}
          $isMobile={isMobile}
          {...swipeHandlers}
        >
        <NotificationHeader><h2>Notifications</h2><ClearAllButton onClick={dismissAllNotifications}>Clear All</ClearAllButton></NotificationHeader>
        {isDismissed ? (
          <p>No notifications</p>
        ) : (
          localNotifications && (
            <>
              {localNotifications.map(notification => (
                <NotificationItem key={notification.nid}>
                    <CloseButton onClick={() => dismissNotification(notification.nid)}>
                        <FaTimes />
                    </CloseButton>
                    <NotificationTitle 
                        onClick={() => notification.cid ? handleMessageNotificationClick(notification) : null}
                        style={{ cursor: notification.cid ? 'pointer' : 'default' }}
                    >
                    <ProfilePicture src={`https://www.iiicoast.com${notification.profile_picture_url}`} />
                    {notification.notif_text}
                  </NotificationTitle>
                  <NotificationContent>{notification.date}</NotificationContent>
                </NotificationItem>
              ))}
            </>
          )
        )}
        </SidebarContainer>
      </>
    );
  };
  
  export default NotificationTray;