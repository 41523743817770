import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';

const ProfilePlaceholder = styled.img`
    height: 35px;
    width: 35px;
`

const LocalVidWrapper = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
`

const LocalVidContainer = styled.video`
    height: 240px;
    width: 150px;
    object-fit: cover;
    z-index: 100;
    
    // bottom: 0;
    // right: 0;
`

const LocalVideo = ({ stream, muted, profilePictureUrl, videoMuted }) => {
  const videoRef = useRef();

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.srcObject = stream;
    }
  }, [stream]);

  return (
    <LocalVidWrapper id="localVidContainer">
      {videoMuted ? (
        <div>
          {/* Placeholder for computer view styling or additional elements, consider using opacity css change instead to persist camera stream */}
          <ProfilePlaceholder id="profilePlaceholder" src={`https://www.iiicoast.com/backend-static/${profilePictureUrl}`} />
        </div>
      ) : (
        <div>
          {/* Placeholder for mobile view styling or additional elements */}
          <LocalVidContainer id="local_vid" ref={videoRef} muted={muted} autoPlay playsInline></LocalVidContainer>
        </div>
      )}
    </LocalVidWrapper>
  );
};

export default LocalVideo;
