import React, { useState, useRef, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { FaPlus, FaCheck } from 'react-icons/fa';

// Add a debounce function to limit API calls
const debounce = (func, delay) => {
  let timeout;
  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), delay);
  };
};

const rotate = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(180deg); }
`;

const ButtonContainer = styled.div`
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 1000;
`;

const Button = styled.button`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #198754;
  color: white;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  transition: all 0.3s;
  animation: ${rotate} 0.3s linear;
  z-index: 1002;

  &:hover {
    background-color: #5EAB87;
  }
`;

// Updated: Input styled like a search bar
const Input = styled.input`
  position: fixed;
  top: 100px;
  left: 50%;
  width: 250px;
  padding: 10px;
  border-radius: 5px;
  background-color: #212121;
  color: white;
  border: 1px solid #444;
  transition: all 0.3s;
  opacity: ${props => props.$isOpen ? 1 : 0};
  transform: ${props => props.$isOpen ? 'translateX(-50%)' : 'translateX(-50%) translateY(20px)'};
  pointer-events: ${props => props.$isOpen ? 'auto' : 'none'};
  z-index: 1001;
  font-size: 16px;
`;

// Dropdown list for predictive search
const PredictiveList = styled.ul`
  position: fixed;
  top: 150px;
  left: 50%;
  width: 250px;
  border-radius: 10px;
  background-color: #101010;
  color: white;
  border: 1px solid #111;
  list-style: none;
  padding: 0;
  margin: 0;
  transform: translateX(-50%);
  z-index: 1001;
  display: ${props => props.$isOpen ? 'block' : 'none'};
`;

const ListItem = styled.li`
  padding: 10px;
  cursor: pointer;

  &:hover {
    background-color: #070707;
  }
`;

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(5px);
  opacity: ${props => props.$isOpen ? 1 : 0};
  transition: opacity 0.3s ease;
  pointer-events: ${props => props.$isOpen ? 'auto' : 'none'};
  z-index: 999;
`;

const ConvoFloatingActionButton = ({ onSubmit }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [userList, setUserList] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const inputRef = useRef(null);

  // Fetch usernames based on search query (mock function for now)
  const fetchUsers = async (query) => {
    // Replace with actual API call to /get-users
    const response = await fetch(`/v2/get-users?query=${query}`);
    const users = await response.json();
    setUserList(users);
  };

  const debouncedFetchUsers = debounce(fetchUsers, 300);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    debouncedFetchUsers(e.target.value);
  };

  const handleUserClick = (user) => {
    setSelectedUser(user);
    setSearchQuery(user); // Show the selected username
    setUserList([]); // Hide the dropdown after selection
  };

  const handleToggle = () => {
    if (isOpen && selectedUser) {
      onSubmit(selectedUser); // Trigger new conversation creation
      setSearchQuery('');
      setSelectedUser(null);
    }
    setIsOpen(!isOpen);
  };

  const handleBackdropClick = (e) => {
    if (e.target === e.currentTarget) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (isOpen && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isOpen]);

  return (
    <>
      <Backdrop $isOpen={isOpen} onClick={handleBackdropClick} />
      <ButtonContainer>
        <Input 
          ref={inputRef}
          $isOpen={isOpen}
          value={searchQuery}
          onChange={handleSearchChange}
          placeholder="Search users"
        />
        <PredictiveList $isOpen={isOpen && userList.length > 0}>
          {userList.map((user, index) => (
            <ListItem key={index} onClick={() => handleUserClick(user)}>
              {user}
            </ListItem>
          ))}
        </PredictiveList>
        <Button onClick={handleToggle}>
          {isOpen ? <FaCheck /> : <FaPlus />}
        </Button>
      </ButtonContainer>
    </>
  );
};

export default ConvoFloatingActionButton;
