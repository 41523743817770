// HabitModal.js
import React, { useState } from 'react';
import styled from 'styled-components';

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContent = styled.div`
  background: rgba(21, 21, 21, 0.75);
  backdrop-filter: blur(10px);
  padding: 20px;
  border-radius: 10px;
  width: 300px;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  background: rgba(255, 255, 255, 0.1);
  border: none;
  color: #ffffff;
  border-radius: 5px;
`;

const ColorOptions = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const ColorOption = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: ${props => props.color};
  cursor: pointer;
  border: ${props => props.selected ? '2px solid white' : 'none'};
`;

const Button = styled.button`
  width: 100%;
  padding: 10px;
  background: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
`;

const HabitModal = ({ isOpen, onClose, onAdd }) => {
  const [habitName, setHabitName] = useState('');
  const [selectedColor, setSelectedColor] = useState('#4CAF50');
  const colors = ['#4CAF50', '#2196F3', '#F44336', '#FFC107', '#9C27B0'];

  if (!isOpen) return null;

  const handleAdd = () => {
    if (habitName.trim()) {
      onAdd(habitName, selectedColor);
      setHabitName('');
    }
  };

  return (
    <ModalOverlay onClick={onClose}>
      <ModalContent onClick={e => e.stopPropagation()}>
        <Input 
          type="text" 
          placeholder="Enter habit name" 
          value={habitName}
          onChange={e => setHabitName(e.target.value)}
        />
        <ColorOptions>
          {colors.map(color => (
            <ColorOption 
              key={color} 
              color={color} 
              selected={selectedColor === color}
              onClick={() => setSelectedColor(color)}
            />
          ))}
        </ColorOptions>
        <Button onClick={handleAdd}>Add Habit</Button>
      </ModalContent>
    </ModalOverlay>
  );
};

export default HabitModal;