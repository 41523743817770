import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { FaUser, FaUserTie } from 'react-icons/fa';

const LoginContainer = styled.div`
  max-width: 400px;
  margin: 2rem auto;
  padding: 2rem;
  background: rgba(32, 32, 32, 0.8);
  backdrop-filter: blur(10px);
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.1);
`;

const Title = styled.h1`
  color: #00ff88;
  text-align: center;
  margin-bottom: 2rem;
`;

const LoginOption = styled.div`
  display: flex;
  align-items: center;
  background: rgba(48, 48, 48, 0.8);
  padding: 1rem;
  margin-bottom: 1rem;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background: rgba(64, 64, 64, 0.8);
    transform: translateY(-2px);
  }
`;

const IconWrapper = styled.div`
  margin-right: 1rem;
  color: #00ff88;
`;

const OptionText = styled.span`
  color: white;
  font-size: 1.1rem;
`;

const OddLogin = () => {
  const navigate = useNavigate();

  return (
    <LoginContainer>
      <Title>Choose Login Type</Title>
      <LoginOption onClick={() => navigate('/customer-login')}>
        <IconWrapper><FaUser size={24} /></IconWrapper>
        <OptionText>Customer Login</OptionText>
      </LoginOption>
      <LoginOption onClick={() => navigate('/test/worker-login')}>
        <IconWrapper><FaUserTie size={24} /></IconWrapper>
        <OptionText>Worker Login</OptionText>
      </LoginOption>
    </LoginContainer>
  );
};

export default OddLogin;