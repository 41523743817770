import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const VideoGridContainer = styled.div`
  height: 100%;
  width: 100%;

  @media (max-width: 768px) {
    // width: 95%;  // Tighter layout on mobile
    overflow-x: hidden;
  }
`

const VideoWrapper = styled.div`
  height: 100%;
  width: 100%;
`

const VideoContainer = styled.video`
  object-fit: cover; 
  height: 100%;
  
  // @media (max-width: 768px) {
  //   width: 335px;  // Tighter layout on mobile
  // }
`

// const LoaderWrapper = styled.div`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   height: 100%;
//   background-color: #000000;
//   color: white;
// `;


const VideoGrid = ({ peers }) => {
  const [videoLayout, setVideoLayout] = useState({ width: '100%', height: '100%' });

  useEffect(() => {
    const userCount = peers.length;
    let containerWidth, containerHeight;

    if (userCount === 1) {
      containerWidth = "100%";
      containerHeight = "100%";
    } else if (userCount === 2) {
      containerWidth = "100%";
      containerHeight = "50%";
    } else {
      containerWidth = "50%";
      containerHeight = "50%";
    }

    setVideoLayout({ width: containerWidth, height: containerHeight });
  }, [peers]);

//   if (!peers) {
//     return (
//       <LoaderWrapper>
//       No users in room...
//       </LoaderWrapper>
//     )
//   }

  return (
    <VideoGridContainer id="video_grid" className="video-grid">
      {peers.map(peer => (
        <VideoWrapper id={`user_container_${peer.id}`} className="user_container" key={`${peer.id}-${peer.displayName}`}>
          <VideoContainer id={`vid_${peer.id}`} style={videoLayout} autoPlay playsInline></VideoContainer>
        </VideoWrapper>
      ))}
    </VideoGridContainer>
  );
};

export default VideoGrid;
