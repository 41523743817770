import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { FaUserCircle } from 'react-icons/fa';

const HeaderContainer = styled.header`
  background-color: #000000;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(5px);
  color: white;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  top: 0;
  transition: top 0.3s;
  z-index: 100;
`;

const LogoContainer = styled.div`
  flex: 1;
  text-align: center;
`;

const ProfileIcon = styled(Link)`
  position: absolute;
  top: 1.25rem;
  right: 1rem;
  color: white;
  font-size: 1.5rem;
  
  &:hover {
    opacity: 0.8;
  }
`;

const OddHeader = () => {
  return (
    <HeaderContainer>
      <LogoContainer>
        <Link to="/test">
          <img src="https://www.iiicoast.com/backend-static/upload/oddjobs-logo-white.png" alt="Logo" width="100px" />
        </Link>
      </LogoContainer>
      <ProfileIcon to="/test/login">
        <FaUserCircle />
      </ProfileIcon>
    </HeaderContainer>
  );
};

export default OddHeader;