import React, { useState } from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import { FaSearch, FaWind, FaThermometerHalf, FaTint } from 'react-icons/fa';
import { WiDaySunny, WiCloudy, WiRain, WiSnow, WiThunderstorm } from 'react-icons/wi';
// WiNightClear, 

const API_KEY = '65b4908eac3843a7bc964029231311';

const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  background: linear-gradient(135deg, #1a1a2e 0%, #16213e 100%);
  color: #e94560;
  font-family: 'Orbitron', sans-serif;
  padding: 2rem;
`;

const SearchBar = styled.div`
  display: flex;
  width: 100%;
  max-width: 400px;
  margin-bottom: 2rem;
`;

const Input = styled.input`
  flex-grow: 1;
  padding: 0.5rem 1rem;
  border: none;
  background: rgba(255, 255, 255, 0.1);
  color: #e94560;
  border-radius: 25px 0 0 25px;
  font-family: inherit;

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px #e94560;
  }
`;

const SearchButton = styled.button`
  background: #e94560;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 0 25px 25px 0;
  cursor: pointer;
  transition: background 0.3s ease;

  &:hover {
    background: #d63556;
  }
`;

const WeatherCard = styled(motion.div)`
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 15px;
  padding: 2rem;
  width: 100%;
  max-width: 400px;
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
`;

const WeatherIcon = styled(motion.div)`
  font-size: 5rem;
  margin-bottom: 1rem;
`;

const Temperature = styled.h1`
  font-size: 3rem;
  margin: 0;
`;

const Location = styled.h2`
  font-size: 1.5rem;
  margin: 0;
  color: #0f3460;
`;

const WeatherInfo = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
`;

const InfoItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ParticleContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -1;
`;

const Particle = styled(motion.div)`
  position: absolute;
  background: #e94560;
  border-radius: 50%;
`;

const ErrorMessage = styled(motion.div)`
  background: rgba(233, 69, 96, 0.2);
  color: #e94560;
  padding: 1rem;
  border-radius: 10px;
  margin-top: 1rem;
  text-align: center;
`;

const getWeatherIcon = (code) => {
  if (code === 1000) return <WiDaySunny />;
  if (code === 1003) return <WiCloudy />;
  if (code >= 1063 && code <= 1072) return <WiRain />;
  if (code >= 1114 && code <= 1117) return <WiSnow />;
  if (code >= 1087 && code <= 1282) return <WiThunderstorm />;
  return <WiDaySunny />;
};

const LabPage = () => {
  const [location, setLocation] = useState('');
  const [weather, setWeather] = useState(null);
  const [error, setError] = useState(null);

  const fetchWeather = async () => {
    try {
      const response = await fetch(
        `http://api.weatherapi.com/v1/current.json?key=${API_KEY}&q=${location}`
      );
      const data = await response.json();
      if (data.error) {
        setError(data.error.message);
        setWeather(null);
      } else {
        setWeather(data);
        setError(null);
      }
    } catch (err) {
      setError('An error occurred while fetching weather data.');
      setWeather(null);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    fetchWeather();
  };

  return (
    <AppContainer>
      <SearchBar>
        <Input
          type="text"
          value={location}
          onChange={(e) => setLocation(e.target.value)}
          placeholder="Enter location..."
        />
        <SearchButton onClick={handleSubmit}>
          <FaSearch />
        </SearchButton>
      </SearchBar>

      <AnimatePresence>
        {weather && (
          <WeatherCard
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.5 }}
          >
            <WeatherIcon
              initial={{ rotate: 0 }}
              animate={{ rotate: 360 }}
              transition={{ duration: 2, repeat: Infinity, ease: "linear" }}
            >
              {getWeatherIcon(weather.current.condition.code)}
            </WeatherIcon>
            <Temperature>{weather.current.temp_c}°C</Temperature>
            <Location>{weather.location.name}, {weather.location.country}</Location>
            <WeatherInfo>
              <InfoItem>
                <FaWind />
                <span>{weather.current.wind_kph} km/h</span>
              </InfoItem>
              <InfoItem>
                <FaThermometerHalf />
                <span>{weather.current.feelslike_c}°C</span>
              </InfoItem>
              <InfoItem>
                <FaTint />
                <span>{weather.current.humidity}%</span>
              </InfoItem>
            </WeatherInfo>
          </WeatherCard>
        )}

        {error && (
          <ErrorMessage
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            {error}
          </ErrorMessage>
        )}
      </AnimatePresence>

      <ParticleContainer>
        {[...Array(50)].map((_, i) => (
          <Particle
            key={i}
            initial={{
              x: Math.random() * window.innerWidth,
              y: Math.random() * window.innerHeight,
              opacity: Math.random(),
            }}
            animate={{
              y: [null, Math.random() * window.innerHeight],
              opacity: [null, Math.random()],
            }}
            transition={{
              duration: Math.random() * 10 + 10,
              repeat: Infinity,
              ease: "linear",
            }}
            style={{
              width: `${Math.random() * 5 + 1}px`,
              height: `${Math.random() * 5 + 1}px`,
            }}
          />
        ))}
      </ParticleContainer>
    </AppContainer>
  );
};

export default LabPage;