import React, { useEffect, useState, useContext } from 'react';
import gsap from 'gsap';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { AxiosContext } from '../App';
import styled from 'styled-components';
import UserPostList from '../components/UserPostList';
import { BarLoader } from 'react-spinners';
// import { useToast } from '../ToastContext';

const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px;
`;
// const BASE_URL = 'https://www.iiicoast.com';

const ProfileCard = styled.div`
  background-color: #070707;
  margin: 15px;
  border-radius: 8px;
  overflow: hidden;
  // box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); // Optional: adds a shadow for a lifted card effect

  @media (max-width: 768px) {
    margin: 5px;
  }
`;

const ProfileContainer = styled.div`
  padding: 20px;

  @media (max-width: 768px) {
    padding: 10px;
  }
`;

const CoverPhoto = styled.img`
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 8px 8px 0 0;
`;

const ProfilePicture = styled.img`
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 50%;
  margin-top: -125px;
  border: 5px solid #fff; // Adjust the border color as needed
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  // z-index: 1; /* Ensure profile picture is on top */

  @media (max-width: 768px) {
    width: 100px;
    height: 100px;
  }
`;

const ProfileHeader = styled.h3`
  margin-top: 15px;
  color: #fff; // Adjust the color as needed
`;

const BioText = styled.p`
  color: #999; // Adjust the color as needed
`;

const FollowButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`

const FollowButton = styled.button`
  margin-top: 5px;
  margin-right: 5px;
  padding: 7px 14px 7px;
  border: 1px #272727 solid;
  border-radius: 15px;
  background: none;
  color: #888888;
  cursor: pointer;
  
`

const FollowButtonText = styled.span`
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0.025em;
`

// const ProfileHeaderContainer = styled.div`
//   text-align: center;
// `;

const ViewProfilePage = () => {
  const { username } = useParams(); // Extract the username parameter from the URL
  const [user, setUser] = useState(null);
  const [following, setFollowing] = useState();
  // const username = match.params.username; // Get the username from the route parameters
  const axiosInstance = useContext(AxiosContext);
  // const { showToast } = useToast();

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const response = await axios.get(`https://www.iiicoast.com/v2/user/${username}`);
        setUser(response.data);
        setFollowing(response.data.following)
      } catch (error) {
        console.error('Error fetching user profile:', error);
      }
    };

    fetchUserProfile();
  }, [username]);

  useEffect(() => {
    if (user) {
    const coverPhoto = document.querySelector('.cover-photo');
    const profilePicture = document.querySelector('.profile_picture');

    gsap.from(coverPhoto, { opacity: 0, y: -50, duration: 0.6, ease: "power1.out" });
    gsap.from(profilePicture, { opacity: 0, scale: 0.5, duration: 0.8, delay: 0.3, ease: "elastic.out(1, 0.3)" });
    }
  }, [user]);

  const handleFollow = () => {
    axiosInstance.post(`/v2/follow/${user.uid}`)
      .then(response => {
        setFollowing(true); // Update the UI
        // showToast(`Followed ${user.username}`, "success");
      })
      .catch(err => {
        console.error('Error following user:', err);
      });
  };

  const handleUnfollow = () => {
    axiosInstance.post(`/v2/unfollow/${user.uid}`)
      .then(response => {
        setFollowing(false); // Update the UI
        // showToast(`Unfollowed ${user.username}`, "warning");
      })
      .catch(err => {
        console.error('Error unfollowing user:', err);
      });
  };

  if (!user) {
    return (
      <SpinnerContainer>
        <BarLoader color="#888888" loading={!user} />
      </SpinnerContainer>
    );
  }

  return (
    <ProfileCard>
      <ProfileContainer>
        <CoverPhoto src="https://picsum.photos/1600/400?random=cover" alt="Cover Photo" className="cover-photo" />
        <FollowButtonContainer>
        {following === 'same_user' ? (
            <></>  // Render nothing if it's the same user
          ) : (
            following ? (
              <FollowButton onClick={handleUnfollow}>
                <FollowButtonText>Unfollow</FollowButtonText>
              </FollowButton>
            ) : (
              <FollowButton onClick={handleFollow}>
                <FollowButtonText>Follow</FollowButtonText>
              </FollowButton>
            )
          )
        }
        </FollowButtonContainer>
        <div style={{ textAlign: 'center' }}>
          <ProfilePicture src={`https://www.iiicoast.com/backend-static/${user.profile_picture_url}`} alt="Profile" className="profile_picture" />
          
          <ProfileHeader>{user.username}</ProfileHeader>
          <BioText>{user.bio}</BioText>
          {(user.follower_count !== 0) && 
          <p>{user.follower_count} Follower{ user.follower_count > 1 && <>s</> }</p>
          }
      </div>
      <UserPostList username={username} />
      </ProfileContainer>
    </ProfileCard>
  );
};

export default ViewProfilePage;
