import React, { useContext, useEffect, useState, useCallback } from 'react';
import { AxiosContext } from '../App';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import gsap from 'gsap';
import styled from 'styled-components';
import UserPostList from '../components/profile/UserPostList';
import { FaPencilAlt, FaPlus, FaTimes, FaFilm, FaSearch } from 'react-icons/fa';
import { BarLoader } from 'react-spinners';

const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px;
`;

// Styled components
const ProfileCard = styled.div`
  background-color: #070707;
  margin: 15px;
  // border: 5px solid #070707;
  border-radius: 8px;
  overflow: hidden;
  // box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); // Optional: adds a shadow for a lifted card effect

  @media (max-width: 768px) {
    margin: 5px;
  }
`;

const ProfileContainer = styled.div`
  padding: 20px;

  @media (max-width: 768px) {
    padding: 10px;
  }
`;

const CoverPhoto = styled.img`
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 8px 8px 0 0;
`;

const ProfilePicture = styled.img`
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 50%;
  margin-top: -75px;
  border: 5px solid #070707; // Adjust the border color as needed
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);

  @media (max-width: 768px) {
    width: 100px;
    height: 100px;
  }
`;

const ProfileHeader = styled.h4`
  margin-top: 15px;
  color: #fff; // Adjust the color as needed
`;

const BioText = styled.p`
  color: #999; // Adjust the color as needed
`;

// const FormContainer = styled.div`
//   margin-top: 20px;
// `;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const StyledInput = styled.input`
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
`;

const StyledInput2 = styled.input`
  font-size: 16px;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
`;

const StyledButton = styled.button`
  padding: 0.75rem;
  background: linear-gradient(to right, #0d68d2, #53a3ff, #2b5bff);
  color: #fff;
  border: none;
  border-radius: 20px;
  font-weight: bold;
  font-size: 1rem;
  cursor: pointer;
  margin-bottom: 1rem;
  background-size: 200% auto;
  transition: background-position 0.4s ease-in-out;

  &:hover {
    background-position: right center;
  }
`;

const EditButton = styled.button`
  background: none;
  border: none;
  color: #198754;
  cursor: pointer;
  font-size: 1.2rem;
  margin-left: 10px;
`;

const ProfilePictureContainer = styled.div`
  position: relative;
  display: inline-block;
`;

const ProfilePictureEditButton = styled.button`
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: #198754;
  color: white;
  border: none;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  @media (max-width: 768px) {
    width: 30px;
    height: 30px;
  }
`;

const Modal = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) ${props => props.$isOpen ? 'translateY(0)' : 'translateY(20px)'};
  background-color: rgba(7, 7, 7, 0.75);
  color: white;
  padding: 20px;
  border-radius: 8px;
  z-index: 1001;
  opacity: ${props => props.$isOpen ? 1 : 0};
  transition: all 0.3s ease-in-out;
  pointer-events: ${props => props.$isOpen ? 'auto' : 'none'};
`;

const SearchModal = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) ${props => props.$isOpen ? 'translateY(0)' : 'translateY(20px)'};
  // background-color: #070707;
  color: white;
  padding: 50px;
  border-radius: 8px;
  z-index: 1001;
  opacity: ${props => props.$isOpen ? 1 : 0};
  transition: all 0.3s ease-in-out;
  pointer-events: ${props => props.$isOpen ? 'auto' : 'none'};

  @media (max-width: 768px) {
    width: 100vw;
  }
`;

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  opacity: ${props => props.$isOpen ? 1 : 0};
  transition: opacity 0.3s ease;
  pointer-events: ${props => props.$isOpen ? 'auto' : 'none'};
  z-index: 1000;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  color: #fff;
  font-size: 1.2rem;
  cursor: pointer;
`;

const FollowerCount = styled.span`
  color: #999; // Adjust the color as needed
`
// const TabHeader = styled.div`
//   display: flex;
//   align-items: bottom;
//   justify-content: center
//   color: #fff;
//   border-bottom: 1px solid #212121; // Adjust the border color as needed
//   margin-bottom: 10px;
//   margin-top: 10px;
//   width: 100%;
// `

const TabHeader = styled.div`
  display: flex;
  align-items: bottom;
  // justify-content: center;
  color: #fff;
  border-bottom: 1px solid #212121;
  margin-bottom: 10px;
  margin-top: 10px;
  width: 100%;
`;

const Tab = styled.span`
  padding: 5px 15px 5px;
  vertical-align: middle;
  color: ${props => props.$active ? '#fff' : '#999'};
  line-height: 40px;
  border-bottom: ${props => props.$active ? '3px solid #fff' : 'none'};
  font-weight: ${props => props.$active ? '600' : 'normal'};
  cursor: pointer;
  display: flex;
  align-items: center;
  
  &:hover {
    color: #fff;
  }
`;

const PostsTab = styled.span`
  padding: 5px;
  font-weight: 600;
  vertical-align: middle;
  color: #fff;
  line-height: 40px;
  border-bottom: 3px solid #fff; // Adjust the border color as needed
`

const MediaTab = styled.div`
  display: flex;
  padding: 15px;
  color: #999; // Adjust the color as needed
`

const MediaTabText = styled.span`
  padding-left: 5px;
`

const InputContainer = styled.div`
  background-color: #000;
  display: flex;
  white-space: nowrap; /* Prevent wrapping */
  align-items: center;
  // margin: 10px;
  padding: 5px;
  border-radius: 25px;
  border: 1px solid #212121;
`;

const SearchContainer = styled.div`
  margin-bottom: 20px;
`;

const SearchInput = styled.input`
  background-color: #000;
  font-size: 16px;
  flex: 1;
  color: #fff;
  padding: 8px 12px;
  border-radius: 25px;
  border: none;
  margin-right: 8px;
  outline: none;
`;

const SearchButton = styled.button`
  display: flex;
  // padding: 8px 16px;
  padding: 8px;
  background-color: #007bff;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  // background: linear-gradient(to right, rgb(13, 104, 210), rgb(63, 111, 275), rgb(43, 91, 255));
  color: #fff;
  border-radius: 25px;
  border: none;
  cursor: pointer;
  background-size: 200% auto;
  transition: background-position 0.4s ease-in-out;
  
  &:hover {
    // background-color: #0056b3;
    background-position: right center;
  }

  svg {
  padding: 2px;
  // padding-bottom: 0.5px;
  }
`;

const SearchText = styled.span`
  line-height: 22px;
`

const MediaResults = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin-top: 20px;
  overflow-x: auto;

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const MediaItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const PosterImage = styled.img`
  width: 200px;
  height: 300px;
  object-fit: cover;
  border-radius: 8px;
  margin-bottom: 10px;

  @media (max-width: 768px) {
    width: 100px;
    height: 150px;
  }
`;

const MediaTitle = styled.p`
  color: #fff;
  margin: 0;
  font-size: 14px;
`;

const MediaTabContainer = styled.div`
  width: 100%;
`;

const ResultsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

// const MediaItem = styled.div`
//   width: 30%;
//   text-align: center;
// `;

const MediaPoster = styled.img`
  width: 100%;
  height: auto;
  border-radius: 8px;
`;

const AddMediaPlaceholder = styled.div`
  width: 200px;
  height: 300px;
  background-color: #212121;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
  color: #888;
  
  &:hover {
    background-color: #444;
  }

  @media (max-width: 768px) {
    width: 100px;
    height: 150px;
  }
`;

const MediaSectionTitle = styled.h5`
  color: #fff;
`

const ProfilePage = () => {
  const axiosInstance = useContext(AxiosContext);
  const [activeTab, setActiveTab] = useState('posts');
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState(null);
  const [userMediaResults, setUserMediaResults] = useState([]);
  // const [loading, setLoading] = useState(true);
  // const [error, setError] = useState(null);
  const navigate = useNavigate();

  const [newBio, setNewBio] = useState('');
  const [newProfilePicture, setNewProfilePicture] = useState(null);

  const [isProfilePictureModalOpen, setIsProfilePictureModalOpen] = useState(false);
  const [isBioModalOpen, setIsBioModalOpen] = useState(false);
  const [isSearchModalOpen, setIsSearchModalOpen] = useState(false);

  const TMDB_ACCESS_TOKEN = 'eyJhbGciOiJIUzI1NiJ9.eyJhdWQiOiJmNzNhNzhkODZhMzQwOWQ1MzFiYWE0MjQ4ZTE5MTM4MiIsIm5iZiI6MTczMzExNjc0MS42MzYsInN1YiI6IjY3NGQ0MzQ1NjQ5Y2FjOTNjYjY0MTQwNSIsInNjb3BlcyI6WyJhcGlfcmVhZCJdLCJ2ZXJzaW9uIjoxfQ.2B_-qZ2foauVsFHhkq4kIVYQyDVPn_l0idc8s0holKo';

  const openProfilePictureModal = () => setIsProfilePictureModalOpen(true);
  const closeProfilePictureModal = () => setIsProfilePictureModalOpen(false);
  const openBioModal = () => setIsBioModalOpen(true);
  const closeBioModal = () => setIsBioModalOpen(false);
  const openSearchModal = () => setIsSearchModalOpen(true);
  const closeSearchModal = () => setIsSearchModalOpen(false);

  const handleSearch = async () => {
    if (!searchQuery.trim()) return;
    
    setLoading(true);
    try {
      const response = await axios.get(
        `https://api.themoviedb.org/3/search/multi?query=${searchQuery}&include_adult=false&language=en-US&page=1`,
        {
          headers: {
            'Authorization': `Bearer ${TMDB_ACCESS_TOKEN}`
          }
        }
      );
      
      // Get first 3 results that have poster_path
      const filteredResults = response.data.results
        .filter(item => item.poster_path)
        .slice(0, 3);
        
      setSearchResults(filteredResults);
    } catch (error) {
      console.error('Error searching:', error);
    } finally {
      setLoading(false);
    }
  };

  // const MediaTabContainer = () => (
  //   <div>
  //     <SearchContainer>
  //       <InputContainer>
  //         <SearchInput 
  //           type="text" 
  //           placeholder="Search..."
  //           value={searchQuery}
  //           onChange={e => setSearchQuery(e.target.value)}
  //         />
  //         <SearchButton onClick={handleSearch}>
  //           <FaSearch /> Search
  //         </SearchButton>
  //       </InputContainer>
  //     </SearchContainer>
      
  //     {loading ? (
  //       <div>Loading...</div>
  //     ) : (
  //       <MediaResults>
  //         {searchResults.map(item => (
  //           <MediaItem key={item.id}>
  //             <PosterImage 
  //               src={`https://image.tmdb.org/t/p/original${item.poster_path}`}
  //               alt={item.title}
  //             />
  //             <MediaTitle>{item.title}</MediaTitle>
  //           </MediaItem>
  //         ))}
  //       </MediaResults>
  //     )}
  //   </div>
  // );

  // const handleSearch = async () => {
  //   try {
  //     const response = await axios.get(
  //       `https://api.themoviedb.org/3/search/multi?query=${searchQuery}&include_adult=false&language=en-US&page=1`,
  //       {
  //         headers: {
  //           'Authorization': `Bearer ${TMDB_ACCESS_TOKEN}`
  //         }
  //       }
  //     );
  //     setSearchResults(response.data.results.slice(0, 3));
  //   } catch (error) {
  //     console.error('Error fetching TMDB data:', error);
  //   }
  // };

  // const fetchUserProfile = useCallback(async () => {
  //   try {
  //     const response = await axiosInstance.get('/v2/user-profile');
  //     setUserData(response.data);
  //     // setLoading(false);
  //   } catch (err) {
  //     // setError('Failed to fetch user profile');
  //     // setLoading(false);
  //     if (err.response && err.response.status === 401) {
  //       navigate('/login');
  //     }
  //   }
  // }, [axiosInstance, navigate]);

  const fetchUserProfile = useCallback(async () => {
    try {
      const [profileResponse, mediaResponse] = await Promise.all([
        axiosInstance.get('/v2/user-profile'),
        axiosInstance.get('/v2/user_media')
      ]);
      
      setUserData(profileResponse.data);
      setUserMediaResults(mediaResponse.data);
    } catch (err) {
      if (err.response && err.response.status === 401) {
        navigate('/login');
      }
    }
  }, [axiosInstance, navigate]);

  const fetchUserMedia = useCallback(async () => {
    try {
      const [mediaResponse] = await Promise.all([
        // axiosInstance.get('/v2/user-profile'),
        axiosInstance.get('/v2/user_media')
      ]);
      
      // setUserData(profileResponse.data);
      setUserMediaResults(mediaResponse.data);
    } catch (err) {
      if (err.response && err.response.status === 401) {
        navigate('/login');
      }
    }
  }, [axiosInstance, navigate]);

  // const fetchMovieSearch = useCallback(async () => {
  //   try {
  //     const response = await axios.get('https://api.themoviedb.org/3/search/movie?include_adult=false&language=en-US&page=1', 
  //       { 'headers': { 'Authorization': 'Bearer eyJhbGciOiJIUzI1NiJ9.eyJhdWQiOiJmNzNhNzhkODZhMzQwOWQ1MzFiYWE0MjQ4ZTE5MTM4MiIsIm5iZiI6MTczMzExNjc0MS42MzYsInN1YiI6IjY3NGQ0MzQ1NjQ5Y2FjOTNjYjY0MTQwNSIsInNjb3BlcyI6WyJhcGlfcmVhZCJdLCJ2ZXJzaW9uIjoxfQ.2B_-qZ2foauVsFHhkq4kIVYQyDVPn_l0idc8s0holKo' } }
  //     );
  //     console.log(response.data);
  //     // setLoading(false);
  //   } catch (err) {
  //     // setError('Failed to fetch user profile');
  //     // setLoading(false);
  //     if (err.response && err.response.status === 401) {
  //       // navigate('/login');
  //     }
  //   }
  // }, []);

  useEffect(() => {
    fetchUserProfile();
    // fetchMovieSearch();
    // adding-to-dependency-array-pending-any-new-issues-else-use->eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchUserProfile]); // , fetchMovieSearch

  useEffect(() => {
    if (userData) {
      const coverPhoto = document.querySelector('.cover-photo');
      const profilePicture = document.querySelector('.profile_picture');

      gsap.from(coverPhoto, { opacity: 0, y: -50, duration: 0.6, ease: "power1.out" });
      gsap.from(profilePicture, { opacity: 0, scale: 0.5, duration: 0.8, delay: 0.3, ease: "elastic.out(1, 0.3)" });
    }
  }, [userData]);

  // const user = JSON.parse(localStorage.getItem('user'));

  const handleBioSubmit = async (e) => {
    e.preventDefault();
    try {
      await axiosInstance.post('/v2/update_bio', { bio: newBio });
      fetchUserProfile();
      setNewBio('');
      closeBioModal();
    } catch (err) {
      // setError('Failed to update bio');
    }
  };

  const handleProfilePictureSubmit = async (e) => {
    e.preventDefault();
    if (!newProfilePicture) return;

    const formData = new FormData();
    formData.append('photo', newProfilePicture);

    try {
      await axiosInstance.post('/v2/update_profile_picture', formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
      fetchUserProfile();
      setNewProfilePicture(null);
      closeProfilePictureModal();
    } catch (err) {
      // setError('Failed to update profile picture');
    }
  };

  const handleMediaSelect = async (item) => {
    try {
      const response = await axiosInstance.post('/v2/add_media', {
        tmdb_id: item.id,
        media_type: item.media_type
      });
      // Handle successful addition
      console.log(response.data);
      closeSearchModal();
      fetchUserMedia();
    } catch (error) {
      console.error('Error adding media:', error);
    }
  };

  if (!userData) {
    return (
      <SpinnerContainer>
          <BarLoader color="#888888" loading={!userData} />
      </SpinnerContainer>
    );
  }

  return (
    <ProfileCard>
      <ProfileContainer>
        <CoverPhoto src="https://picsum.photos/1600/400?random=cover" alt="Cover Photo" className="cover-photo" />
        <div style={{ textAlign: 'center' }}>
          <ProfilePictureContainer>
            <ProfilePicture src={`https://www.iiicoast.com/backend-static/${userData.profile_picture_url}`} alt="Profile" className="profile_picture" />
            <ProfilePictureEditButton onClick={openProfilePictureModal}>
              <FaPlus />
            </ProfilePictureEditButton>
          </ProfilePictureContainer>
          <ProfileHeader>{userData.username}</ProfileHeader>
          <BioText>
            {userData.bio}
            <EditButton onClick={openBioModal}>
              <FaPencilAlt />
            </EditButton>
          </BioText>
          {(userData.follower_count !== 0) && 
          <FollowerCount>{userData.follower_count} Follower{ userData.follower_count > 1 && <>s</> }</FollowerCount>
          }
        </div>
        <TabHeader>
        <Tab 
          $active={activeTab === 'posts'} 
          onClick={() => setActiveTab('posts')}
        >
          Posts
        </Tab>
        <Tab 
          $active={activeTab === 'media'} 
          onClick={() => setActiveTab('media')}
        >
          {/* <FaFilm /> */}
          <MediaTabText>Movies & TV</MediaTabText>
        </Tab>
      </TabHeader>
      
      {activeTab === 'posts' ? (
        <UserPostList username={userData.username} />
      ) : (
        <MediaTabContainer>
          <MediaSectionTitle>Recently Watched</MediaSectionTitle>
          
          <MediaResults>
            <MediaItem>
            <AddMediaPlaceholder onClick={openSearchModal}>
            <FaPlus />
          </AddMediaPlaceholder>
          </MediaItem>
          {Array.isArray(userMediaResults) && userMediaResults.length > 0 ? (
            userMediaResults.map(item => (
              <MediaItem key={item.id}>
                <PosterImage
                  onClick={() => handleMediaSelect(item)}
                  src={`https://iiicoast.com/backend-static/${item.poster_path}`}
                  alt={item.title}
                />
                <MediaTitle>{item.title}</MediaTitle>
              </MediaItem>
            ))
          ) : (
            // <EmptyStateMessage>No media added yet</EmptyStateMessage>
            <></>
          )}
        </MediaResults>
          
          
          {/* <MediaResults>
           {searchResults.map(item => (
             <MediaItem key={item.id}>
               <PosterImage 
                 src={`https://image.tmdb.org/t/p/original${item.poster_path}`}
                 alt={item.title}
               />
               <MediaTitle>{item.title || item.name}</MediaTitle>
             </MediaItem>
           ))}
         </MediaResults> */}
        </MediaTabContainer>
      )}
      </ProfileContainer>
      

      <Backdrop $isOpen={isProfilePictureModalOpen || isBioModalOpen || isSearchModalOpen} onClick={() => {
        closeProfilePictureModal();
        closeBioModal();
        closeSearchModal();
      }} />

      <Modal $isOpen={isProfilePictureModalOpen}>
        <CloseButton onClick={closeProfilePictureModal}>
          <FaTimes />
        </CloseButton>
        <h2>Update Profile Picture</h2>
        <StyledForm onSubmit={handleProfilePictureSubmit}>
          <StyledInput
            type="file"
            onChange={(e) => setNewProfilePicture(e.target.files[0])}
            accept="image/*"
          />
          <StyledButton type="submit">Update Profile Picture</StyledButton>
        </StyledForm>
      </Modal>

      <Modal $isOpen={isBioModalOpen}>
        <CloseButton onClick={closeBioModal}>
          <FaTimes />
        </CloseButton>
        <h2>Update Bio</h2>
        <StyledForm onSubmit={handleBioSubmit}>
          <StyledInput2
            type="text"
            value={newBio}
            onChange={(e) => setNewBio(e.target.value)}
            placeholder="Update your bio"
          />
          <StyledButton type="submit">Update Bio</StyledButton>
        </StyledForm>
      </Modal>

      <SearchModal $isOpen={isSearchModalOpen}>
        <CloseButton onClick={closeSearchModal}>
          <FaTimes />
        </CloseButton>
        <SearchContainer>
            <InputContainer>
              <SearchInput 
                type="text" 
                placeholder="Search..."
                value={searchQuery}
                onChange={e => setSearchQuery(e.target.value)}
              />
              <SearchButton onClick={handleSearch}>
                <FaSearch />{/* <SearchText>Search</SearchText> */}
              </SearchButton>
            </InputContainer>
          </SearchContainer>
          <MediaResults>
           {searchResults.map(item => (
             <MediaItem key={item.id}>
               <PosterImage
                 onClick={() => handleMediaSelect(item)} // Pass the item to the handler
                 src={`https://image.tmdb.org/t/p/original${item.poster_path}`}
                 alt={item.title}
               />
               <MediaTitle>{item.title || item.name}</MediaTitle>
             </MediaItem>
           ))}
         </MediaResults>
      </SearchModal>
    </ProfileCard>
  );
};

export default ProfilePage;
