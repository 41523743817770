import React, { useEffect, useState } from 'react';
import styled, { keyframes, css } from 'styled-components';
import { FaInfoCircle, FaCheckCircle, FaExclamationTriangle } from 'react-icons/fa';

// const slideIn = keyframes`
//   from {
//     transform: translateY(100%);
//     opacity: 0;
//   }
//   to {
//     transform: translateY(0);
//     opacity: 1;
//   }
// `;

// const slideOut = keyframes`
//   from {
//     transform: translateY(0);
//     opacity: 1;
//   }
//   to {
//     transform: translateY(100%);
//     opacity: 0;
//   }
// `;

const mobileSlideIn = keyframes`
  from {
    transform: translate(-50%, -20px);
    opacity: 0;
  }
  to {
    transform: translate(-50%, 0);
    opacity: 1;
  }
`;

const mobileSlideOut = keyframes`
  from {
    transform: translate(-50%, 0);
    opacity: 1;
  }
  to {
    transform: translate(-50%, -20px);
    opacity: 0;
  }
`;

const slideIn = keyframes`
  from {
    transform: translate(-50%, -20px) translateX(30px);  /* Start off 40px to the right */
    opacity: 0;
  }
  to {
    transform: translate(-50%, 0) translateX(30px);  /* End at the same 40px offset */
    opacity: 1;
  }
`;

const slideOut = keyframes`
  from {
    transform: translate(-50%, 0) translateX(30px);  /* Starting 40px right */
    opacity: 1;
  }
  to {
    transform: translate(-50%, -20px) translateX(30px);  /* Ending 40px right */
    opacity: 0;
  }
`;

const ToastContainer = styled.div`
  z-index: 2000;
  position: fixed;
  // bottom: 20px;
  // right: 20px;
  top: 77px; /* or adjust for your header */
  left: 50%;
  // transform: translateX(-50%);
  transform: translateX(calc(-50% + 30px));
  padding: 12px 20px;
  border-radius: 4px;
  box-shadow: 0 10px 100px 2.5px rgba(0,0,0,0.8);
  display: flex;
  align-items: center;
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(3px);
  animation: ${({ $isClosing }) => 
    $isClosing 
      ? css`${slideOut} 0.3s ease-in forwards`
      : css`${slideIn} 0.3s ease-out`
  };

  ${({ $type }) => {
    switch ($type) {
      case 'success':
        return css`
          // background-color: #051b11;
          background-color: rgba(5, 27, 17, 0.45);
          border: 1px solid #082b1b;
          color: #75b798;
        `;
      case 'warning':
        return css`
          // background-color: #332701;
          background-color: rgba(51, 39, 1, 0.45);
          border: 1px solid #5f4802;
          color: #e7c55d;
        `;
      case 'danger':
        return css`
          // background-color: #2c0b0e;
          background-color: rgba(44, 11, 14, 0.45);
          border: 1px solid #4c2229;
          color: #ea868f;
        `;
      default: // info
        return css`
          // background-color: #031633;
          background-color: rgba(3, 22, 51, 0.45);
          border: 1px solid #052e6b;
          color: #6ea8fe;
        `;
    }
  }}

  @media (max-width: 768px) {
    width: 70%;
    transform: translateX(-50%);
    animation: ${({ $isClosing }) => 
      $isClosing 
        ? css`${mobileSlideOut} 0.3s ease-in forwards`
        : css`${mobileSlideIn} 0.3s ease-out`
    };
  }
`;

const Icon = styled.span`
  margin-right: 10px;
  font-size: 1.2em;
  display: flex; // This helps with alignment
  align-items: center; // This ensures the icon itself is vertically centered
`;

const ToastText = styled.span`
  
  @media (max-width: 768px) {
    // text-align: center;
  }
`;

const Toast = ({ message, type = 'info', onClose }) => {
    const [isClosing, setIsClosing] = useState(false);

    useEffect(() => {
    const timer = setTimeout(() => {
      setIsClosing(true);
    }, 2700); // Start closing animation slightly before removal

      return () => clearTimeout(timer);
    }, []);  

    useEffect(() => {
      if (isClosing) {
        const closeTimer = setTimeout(onClose, 300); // Remove after closing animation
        return () => clearTimeout(closeTimer);
      }
    }, [isClosing, onClose]);

    const getIcon = () => {
      switch (type) {
        case 'success':
          return <FaCheckCircle />;
        case 'warning':
        case 'danger':
          return <FaExclamationTriangle />;
        default:
          return <FaInfoCircle />;
      }
    };  

    return (
        <ToastContainer $type={type} $isClosing={isClosing}>
            <Icon>{getIcon()}</Icon>
            <ToastText>{message}</ToastText>
        </ToastContainer>
    );
};

export default Toast;
