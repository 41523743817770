import React, { useState, useContext } from 'react';
import { AuthContext } from '../../AuthContext';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
  background-color: #000000;
  padding-top: 10vh;

  @media (max-width: 768px) {
    padding-top: 0;
    padding-left: 15px;
    padding-right: 15px;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  background-color: #070707;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 300px;
`;

const Header = styled.h1`
  color: white;
  margin-bottom: 1.5rem;
  text-align: center;
`;

const Label = styled.label`
  margin-bottom: 0.5rem;
  color: grey;
`;

const Input = styled.input`
  color: white;
  background: #212121;
  padding: 0.5rem;
  margin-bottom: 1rem;
  border: 1px solid #797979;
  border-radius: 5px;
  font-size: 1rem;
`;

const Button = styled.button`
  padding: 0.75rem;
  background: linear-gradient(to left, rgb(13, 104, 210), rgb(67, 26, 172), rgb(43, 91, 255));
  color: #fff;
  border: none;
  border-radius: 5px;
  font-weight: bold;
  font-size: 1rem;
  cursor: pointer;
  margin-bottom: 1rem;
  background-size: 200% auto;
  transition: background-position 0.4s ease-in-out;

  &:hover {
    background-position: right center;
  }
`;

const Error = styled.p`
  color: red;
  margin-bottom: 1rem;
  text-align: center;
`;

const SignInLink = styled(Link)`
  color: #007bff;
  text-align: center;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

const WhiteText = styled.p`
  color: white;
`;

function Register({ setIsAuthenticated }) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { login } = useContext(AuthContext);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('/v2/register2', { username, password, email });
      // localStorage.setItem('token', response.data.token);
      // localStorage.setItem('user', JSON.stringify(response.data.user));
      console.log(response)
      await login(username, password)
      navigate('/');
    } catch (error) {
      setError(error.response?.data?.msg || error.response?.data?.error || 'An error occurred');
    }
  };

  return (
    <Container>
      <Form onSubmit={handleSubmit}>
        <Header>Sign Up</Header>
        {error && <Error>{error}</Error>}
        <Label htmlFor="username">Username</Label>
        <Input 
          id="username" 
          type="text" 
          value={username} 
          onChange={(e) => setUsername(e.target.value)} 
          placeholder="Username" 
        />
        <Label htmlFor="password">Password</Label>
        <Input 
          id="password" 
          type="password" 
          value={password} 
          onChange={(e) => setPassword(e.target.value)} 
          placeholder="Password" 
        />
        <Label htmlFor="passwordconfirm">Confirm Password</Label>
        <Input 
          id="passwordconfirm" 
          type="password" 
          placeholder="Confirm Password" 
        />
        <Label htmlFor="email">Email</Label>
        <Input 
          id="email" 
          type="email" 
          value={email} 
          onChange={(e) => setEmail(e.target.value)} 
          placeholder="Email" 
        />
        <Button type="submit">Register</Button>
        <WhiteText>Already have an account? <SignInLink to="/login">Sign In</SignInLink></WhiteText>
      </Form>
    </Container>
  );
}

export default Register;
