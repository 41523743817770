import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import InfiniteScroll from 'react-infinite-scroll-component';
import Post from '../feed/Post'; // Import the new Post component
import { BarLoader } from 'react-spinners';

const UserPostListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px;
`;

function UserPostList({ username }) {
  const [posts, setPosts] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const fetchUserPosts = useCallback(async () => {
    setIsLoading(true);
    setTimeout(async () => {
        try {
          const response = await axios.get(`https://www.iiicoast.com/api/get-user-posts/${page}?username=${username}`);
          const newPosts = response.data;
          setPosts(prevPosts => [...prevPosts, ...newPosts]);

          if (newPosts.length === 0) {
            setHasMore(false);
          } else {
            setPage(prevPage => prevPage + 1);
            setHasMore(newPosts.length > 0);
          }
          
          // setPage(prevPage => prevPage + 1);
        } catch (error) {
          console.error('Error fetching user posts:', error);
        } finally {
            setIsLoading(false);
        }
    }, 500); // 0.5-second delay before loading more posts
  }, [username, page]);

  useEffect(() => {
    if (posts.length === 0) {
      fetchUserPosts();
    }
  }, [posts.length, fetchUserPosts]);

  return (
    <InfiniteScroll
      dataLength={posts.length}
      next={fetchUserPosts}
      hasMore={hasMore}
      loader={
        <SpinnerContainer>
          <BarLoader color="#888888" loading={isLoading} />
        </SpinnerContainer>
      }
      scrollThreshold={0.9}
    >
      <UserPostListContainer>
        {posts.map((post, index) => (
          <Post key={`${post.id}-${post.timestamp}-${post.thread_url}-${index}`} post={post} />
        ))}
      </UserPostListContainer>
    </InfiniteScroll>
  );
}

export default UserPostList;