import React, { useState, useRef, useEffect, useCallback } from 'react';
import styled, { keyframes } from 'styled-components'; // , css
import { FaPlus, FaCheck } from 'react-icons/fa';

const rotate = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(180deg); }
`;

const ButtonContainer = styled.div`
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 1000;
`;

const Button = styled.button`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #198754;
  color: white;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  transition: all 0.3s;
  animation: ${rotate} 0.3s linear;
  z-index: 1002;

  &:hover {
    background-color: #5EAB87;
  }
`;

// Consider adding responsive width to TextArea
const TextArea = styled.textarea`
  position: fixed;
  top: 100px;
  left: 50%;
  width: 300px;
  height: 100px;
  padding: 10px;
  border-radius: 5px;
  background-color: #212121;
  color: white;
  border: 1px solid #444;
  resize: none;
  transition: all 0.3s;
  opacity: ${props => props.$isOpen ? 1 : 0};
  transform: ${props => props.$isOpen ? 'translateX(-50%)' : 'translateX(-50%) translateY(20px)'};
  pointer-events: ${props => props.$isOpen ? 'auto' : 'none'};
  z-index: 1001;
  font-size: 16px
`;

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(5px);
  opacity: ${props => props.$isOpen ? 1 : 0};
  transition: opacity 0.3s ease;
  pointer-events: ${props => props.$isOpen ? 'auto' : 'none'};
  z-index: 999;
`;

const FloatingActionButton = ({ onSubmit, placeholder = "What's happening?" }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [content, setContent] = useState('');
  const textAreaRef = useRef(null);
  const [enterPressed, setEnterPressed] = useState(false);

  const handleToggle = useCallback(() => {
    console.log('handleToggle called');
    console.log('isOpen:', isOpen);
    console.log('content:', content.trim());
    if (isOpen && content.trim()) {
      console.log('Submitting content:', content);
      onSubmit(content);
      setContent('');

      // Close the virtual keyboard on mobile devices
      // if (textAreaRef.current) {
      //   console.log('Blurring textarea');
      //   textAreaRef.current.blur(); // Remove focus from the textarea
      // }
    }
    setIsOpen(!isOpen);
  }, [content, isOpen, onSubmit]);

  useEffect(() => {
    if (enterPressed) {
      handleToggle();
      setEnterPressed(false);
    }
  }, [content, enterPressed, handleToggle]);

  useEffect(() => {
    if (!isOpen && textAreaRef.current) {
      console.log('Blurring textarea in useEffect');
      textAreaRef.current.blur();
    }
  }, [isOpen]);

  // Function to handle keydown events
  const handleKeyDown = (e) => {
    const isEnterKey = e.key === 'Enter' || e.keyCode === 13 || e.code === 'Enter';
    console.log('Key pressed:', e.key);
    if (isEnterKey) { //   && !e.shiftKey removed shift key check for proper iOS behaviour. If necessary for desktop, will need a mobile check here.
      e.preventDefault(); // Prevents the default behavior of adding a new line
      console.log('Enter key pressed without Shift');
      // Set flag to true to trigger useEffect
      setEnterPressed(true);
    }
  };

  useEffect(() => {
    if (isOpen && textAreaRef.current) {
      textAreaRef.current.focus();
      console.log('Textarea focused');
    }
  }, [isOpen]);

  // useEffect to manage event listener
  useEffect(() => {
    const textArea = textAreaRef.current; // Store the current ref value

    if (textArea) {
      console.log('Adding keydown event listener');
      textArea.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      if (textArea) {
        console.log('Removing keydown event listener');
        textArea.removeEventListener('keydown', handleKeyDown);
      }
    };
  }, [isOpen]);

  const handleBackdropClick = (e) => {
    if (e.target === e.currentTarget) {
      console.log('Backdrop clicked');
      setIsOpen(false);
    }
  };

  return (
    <>
      <Backdrop $isOpen={isOpen} onClick={handleBackdropClick} />
      <ButtonContainer>
        <TextArea 
          ref={textAreaRef}
          $isOpen={isOpen}
          value={content}
          onChange={(e) => setContent(e.target.value)}
          placeholder={placeholder}
        />
        <Button onClick={handleToggle}>
          {isOpen ? <FaCheck /> : <FaPlus />}
        </Button>
      </ButtonContainer>
    </>
  );
};

export default FloatingActionButton;
