// LabPage.jsx
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { FaPlus, FaTimes, FaCheck } from 'react-icons/fa';
import { format, subDays, addDays } from 'date-fns';

const Container = styled.div`
  background: rgba(18, 18, 18, 0.8);
  backdrop-filter: blur(10px);
  border-radius: 10px;
  padding: 20px;
  color: white;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const Title = styled.h1`
  font-size: 24px;
  margin: 0;
`;

const AddButton = styled.button`
  background: none;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
  
  &:hover {
    opacity: 0.8;
  }
`;

const SubHeader = styled.div`
  display: grid;
  grid-template-columns: 200px repeat(5, 1fr);
  margin-bottom: 10px;
  text-align: right;
`;

const DateLabel = styled.div`
  padding: 10px;
  font-size: 14px;
`;

const HabitRow = styled.div`
  display: grid;
  grid-template-columns: 200px repeat(5, 1fr);
  margin-bottom: 10px;
  align-items: center;
`;

const HabitName = styled.div`
  color: ${props => props.color};
`;

const HabitMarker = styled.button`
  background: none;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  cursor: pointer;
  color: ${props => props.isChecked ? props.color : '#555'};
`;

const Modal = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(30, 30, 30, 0.95);
  backdrop-filter: blur(10px);
  padding: 20px;
  border-radius: 10px;
  z-index: 1000;
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const Input = styled.input`
  background: rgba(255, 255, 255, 0.1);
  border: none;
  padding: 10px;
  color: white;
  border-radius: 5px;
`;

const ColorPicker = styled.div`
  display: flex;
  gap: 10px;
`;

const ColorOption = styled.button`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 2px solid ${props => props.selected ? 'white' : 'transparent'};
  background-color: ${props => props.color};
  cursor: pointer;
`;

const SubmitButton = styled.button`
  background: rgba(255, 255, 255, 0.1);
  border: none;
  padding: 10px;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  
  &:hover {
    background: rgba(255, 255, 255, 0.2);
  }
`;

const COLORS = ['#FF6B6B', '#4ECDC4', '#45B7D1', '#FFBE0B', '#FB5607'];

function LabPage() {
  const [habits, setHabits] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newHabitName, setNewHabitName] = useState('');
  const [selectedColor, setSelectedColor] = useState(COLORS[0]);
  const [currentDate] = useState(new Date());

  const dates = [
    subDays(currentDate, 2),
    subDays(currentDate, 1),
    currentDate,
    addDays(currentDate, 1),
    addDays(currentDate, 2),
  ];

  useEffect(() => {
    const savedHabits = localStorage.getItem('habits');
    if (savedHabits) {
      setHabits(JSON.parse(savedHabits));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('habits', JSON.stringify(habits));
  }, [habits]);

  const toggleHabit = (habitIndex, dateIndex) => {
    setHabits(prevHabits => {
      const newHabits = [...prevHabits];
      newHabits[habitIndex].completed[dateIndex] = !newHabits[habitIndex].completed[dateIndex];
      return newHabits;
    });
  };

  const addHabit = (e) => {
    e.preventDefault();
    if (newHabitName.trim()) {
      const newHabit = {
        name: newHabitName,
        color: selectedColor,
        completed: [false, false, false, false, false]
      };
      setHabits([...habits, newHabit]);
      setIsModalOpen(false);
      setNewHabitName('');
      setSelectedColor(COLORS[0]);
    }
  };

  return (
    <Container>
      <Header>
        <Title>Habits</Title>
        <AddButton onClick={() => setIsModalOpen(true)}>
          <FaPlus />
        </AddButton>
      </Header>

      <SubHeader>
        <div></div>
        {dates.map((date, index) => (
          <DateLabel key={index}>
            {format(date, 'MMM d')}
          </DateLabel>
        ))}
      </SubHeader>

      {habits.map((habit, habitIndex) => (
        <HabitRow key={habitIndex}>
          <HabitName color={habit.color}>{habit.name}</HabitName>
          {habit.completed.map((isChecked, dateIndex) => (
            <HabitMarker
              key={dateIndex}
              onClick={() => toggleHabit(habitIndex, dateIndex)}
              isChecked={isChecked}
              color={habit.color}
            >
              {isChecked ? <FaCheck /> : <FaTimes />}
            </HabitMarker>
          ))}
        </HabitRow>
      ))}

      {isModalOpen && (
        <>
          <Overlay onClick={() => setIsModalOpen(false)} />
          <Modal>
            <Form onSubmit={addHabit}>
              <Input
                type="text"
                placeholder="Habit name"
                value={newHabitName}
                onChange={(e) => setNewHabitName(e.target.value)}
              />
              <ColorPicker>
                {COLORS.map((color) => (
                  <ColorOption
                    key={color}
                    color={color}
                    selected={selectedColor === color}
                    onClick={() => setSelectedColor(color)}
                    type="button"
                  />
                ))}
              </ColorPicker>
              <SubmitButton type="submit">Add Habit</SubmitButton>
            </Form>
          </Modal>
        </>
      )}
    </Container>
  );
}

export default LabPage;