import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  @property --t {
    syntax: "<integer>";
    initial-value: 0;
    inherits: true;
  }

  body {
    font-family: 'Arial', sans-serif;
    background-color: #000000;
    margin: 0;
    padding: 0;
  }

  /**/
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  

  /* Define your keyframe animations 
  @keyframes fadeIn {
      0% {
          opacity: 0;
          transform: translateY(20px);
      }
      100% {
          opacity: 1;
          transform: translateY(0);
      }
  }

  @keyframes fadeOut {
      0% {
          opacity: 1;
          transform: translateY(0);
      }
      100% {
          opacity: 0;
          transform: translateY(-20px);
      }
  }
  */
  /* Apply animation styles 
  .fade-in {
      animation: fadeIn 0.5s forwards; /* Animation when entering the view */
  }

  .fade-out {
      animation: fadeOut 0.5s forwards; /* Animation when leaving the view */
  }
  */
`;

export default GlobalStyles;