import { Color } from '@tiptap/extension-color'
import ListItem from '@tiptap/extension-list-item'
import TextStyle from '@tiptap/extension-text-style'
import { EditorProvider, useCurrentEditor } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import React, { useState } from 'react';
// import { RiTextDirectionR } from 'react-icons/ri'
import styled from 'styled-components';
import { 
  MdFormatBold, 
  MdFormatItalic, 
  MdStrikethroughS, 
  MdCode, 
  MdFormatClear, 
  MdClear, 
  MdTextFields, 
  MdTitle, 
  MdFormatListBulleted, 
  MdFormatListNumbered, 
  MdFormatQuote, 
  MdHorizontalRule, 
  MdInsertPageBreak, 
  MdUndo, 
  MdRedo, 
  MdPalette 
} from 'react-icons/md';
import { RiParagraph, RiCodeBlock } from "react-icons/ri";


const StyledMenuBar = styled.div`
  position: sticky;
  top: 70px;
  background-color: rgba(0,0,0,0.5);
  z-index: 1000; /* Ensure it appears above other content */
  isolation: isolate; /* Prevent it from being affected by parent filters */
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(5px);

  .control-group {
    padding: 10px;
    // background-color: #000;
    border-radius: 5px 5px 0 0;
    
    .button-group {
      display: flex;
      flex-wrap: wrap;
      gap: 5px;
      
      button {
        background-color: #2F2F2F;
        border: none;
        border-radius: 3px;
        color: #FFFFFF;
        padding: 5px 10px;
        font-size: 14px;
        cursor: pointer;
        transition: background-color 0.2s;

        display: flex; /* Enable flexbox */
        align-items: center; /* Center items vertically */
        justify-content: center; /* Center items horizontally */
        
        &:hover {
          background-color: #404040;
        }
        
        &.is-active {
          background-color: #965EFF;
        }
        
        &:disabled {
          opacity: 0.5;
          cursor: not-allowed;
        }
      }
    }
  }
`;


const StyledEditorContent = styled.div`
  background-color: #1F1F1F;
  color: #FFFFFF;
  border-radius: 0 0 5px 5px;
  
  .ProseMirror {
    padding: 20px;
    min-height: 200px;
    
    &:focus {
      outline: none;
    }
    
    // Add styles for different elements within the editor
    h1, h2, h3, h4, h5, h6 {
      color: #FFFFFF;
    }
    
    code {
      background-color: #2F2F2F;
      color: #E06C75;
      padding: 2px 4px;
      border-radius: 3px;
    }
    
    pre {
      background-color: #2F2F2F;
      padding: 10px;
      border-radius: 3px;
      
      code {
        background-color: transparent;
        color: #ABB2BF;
      }
    }
    
    blockquote {
      border-left: 3px solid #965EFF;
      padding-left: 10px;
      color: #ABABAB;
    }
  }
`;

const Small = styled.span`
  font-size: 12px;
`

const MenuBar = () => {
  const { editor } = useCurrentEditor()

  if (!editor) {
    return null
  }

  return (
    <StyledMenuBar>
        <div className="control-group">
          <div className="button-group">
            <button
              onClick={() => editor.chain().focus().toggleBold().run()}
              disabled={
                !editor.can()
                  .chain()
                  .focus()
                  .toggleBold()
                  .run()
              }
              className={editor.isActive('bold') ? 'is-active' : ''}
            >
              <MdFormatBold />
            </button>
            <button
              onClick={() => editor.chain().focus().toggleItalic().run()}
              disabled={
                !editor.can()
                  .chain()
                  .focus()
                  .toggleItalic()
                  .run()
              }
              className={editor.isActive('italic') ? 'is-active' : ''}
            >
              <MdFormatItalic />
            </button>
            <button
              onClick={() => editor.chain().focus().toggleStrike().run()}
              disabled={
                !editor.can()
                  .chain()
                  .focus()
                  .toggleStrike()
                  .run()
              }
              className={editor.isActive('strike') ? 'is-active' : ''}
            >
              <MdStrikethroughS />
            </button>
            <button
              onClick={() => editor.chain().focus().toggleCode().run()}
              disabled={
                !editor.can()
                  .chain()
                  .focus()
                  .toggleCode()
                  .run()
              }
              className={editor.isActive('code') ? 'is-active' : ''}
            >
              <MdCode />
            </button>
            {/* <button onClick={() => editor.chain().focus().unsetAllMarks().run()}>
              Clear marks
            </button>
            <button onClick={() => editor.chain().focus().clearNodes().run()}>
              Clear nodes
            </button> */}
            <button
              onClick={() => editor.chain().focus().setParagraph().run()}
              className={editor.isActive('paragraph') ? 'is-active' : ''}
            >
              <RiParagraph />
            </button>
            <button
              onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
              className={editor.isActive('heading', { level: 1 }) ? 'is-active' : ''}
            >
              <Small>H1</Small>
            </button>
            <button
              onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
              className={editor.isActive('heading', { level: 2 }) ? 'is-active' : ''}
            >
              <Small>H2</Small>
            </button>
            <button
              onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}
              className={editor.isActive('heading', { level: 3 }) ? 'is-active' : ''}
            >
              <Small>H3</Small>
            </button>
            <button
              onClick={() => editor.chain().focus().toggleHeading({ level: 4 }).run()}
              className={editor.isActive('heading', { level: 4 }) ? 'is-active' : ''}
            >
              <Small>H4</Small>
            </button>
            <button
              onClick={() => editor.chain().focus().toggleHeading({ level: 5 }).run()}
              className={editor.isActive('heading', { level: 5 }) ? 'is-active' : ''}
            >
              <Small>H5</Small>
            </button>
            <button
              onClick={() => editor.chain().focus().toggleHeading({ level: 6 }).run()}
              className={editor.isActive('heading', { level: 6 }) ? 'is-active' : ''}
            >
              <Small>H6</Small>
            </button>
            <button
              onClick={() => editor.chain().focus().toggleBulletList().run()}
              className={editor.isActive('bulletList') ? 'is-active' : ''}
            >
              <MdFormatListBulleted />
            </button>
            <button
              onClick={() => editor.chain().focus().toggleOrderedList().run()}
              className={editor.isActive('orderedList') ? 'is-active' : ''}
            >
              <MdFormatListNumbered />
            </button>
            <button
              onClick={() => editor.chain().focus().toggleCodeBlock().run()}
              className={editor.isActive('codeBlock') ? 'is-active' : ''}
            >
              <RiCodeBlock />
            </button>
            <button
              onClick={() => editor.chain().focus().toggleBlockquote().run()}
              className={editor.isActive('blockquote') ? 'is-active' : ''}
            >
              <MdFormatQuote />
            </button>
            <button onClick={() => editor.chain().focus().setHorizontalRule().run()}>
              <MdHorizontalRule />
            </button>
            <button onClick={() => editor.chain().focus().setHardBreak().run()}>
              <MdInsertPageBreak />
            </button>
            <button
              onClick={() => editor.chain().focus().undo().run()}
              disabled={
                !editor.can()
                  .chain()
                  .focus()
                  .undo()
                  .run()
              }
            >
              <MdUndo />
            </button>
            <button
              onClick={() => editor.chain().focus().redo().run()}
              disabled={
                !editor.can()
                  .chain()
                  .focus()
                  .redo()
                  .run()
              }
            >
              <MdRedo />
            </button>
            <button
              onClick={() => editor.chain().focus().setColor('#958DF1').run()}
              className={editor.isActive('textStyle', { color: '#958DF1' }) ? 'is-active' : ''}
            >
              <MdPalette  />
            </button>
          </div>
        </div>
    </StyledMenuBar>
  )
}

const extensions = [
  Color.configure({ types: [TextStyle.name, ListItem.name] }),
  TextStyle.configure({ types: [ListItem.name] }),
  StarterKit.configure({
    bulletList: {
      keepMarks: true,
      keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
    },
    orderedList: {
      keepMarks: true,
      keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
    },
  }),
]

const content = `
<h2>
  Welcome,
</h2>
<p>
  this is a <em>basic</em> example of an <strong>Article</strong>. There are all kind of basic text styles you’d probably expect in the menu bar above. But wait until you see the lists:
</p>
<ul>
  <li>
    That’s a bullet list with one …
  </li>
  <li>
    … or two list items.
  </li>
</ul>
<p>
  Isn’t that great? And all of that is editable. But wait, there’s more. Let’s try a code block:
</p>
<pre><code class="language-css">body {
  display: none;
}</code></pre>
<p>
  I know, I know, this is impressive. It’s only the tip of the iceberg though. Give it a try and click a little bit around. When you're ready to post your article click the save button below.
</p>
<blockquote>
  In a Time of Universal Deceit — Telling the Truth Is a Revolutionary Act 💬
  <br />
  — Unknown
</blockquote>
`

// const RTEditor = () => {
//   return (
//     <EditorProvider slotBefore={<MenuBar />} extensions={extensions} content={content}></EditorProvider>
//   )
// }

// export default RTEditor;

const RTEditor = ({ onUpdate, initialContent = content }) => {
    const [editor, setEditor] = useState(null);
  
    return (
      <StyledEditorContent>
        <EditorProvider 
          slotBefore={<MenuBar />} 
          extensions={extensions} 
          content={initialContent}
          onUpdate={({ editor }) => {
            setEditor(editor);
            onUpdate && onUpdate(editor.getHTML());
          }}
        />
      </StyledEditorContent>
    )
  }
  
  export default RTEditor;