import React from 'react';
import styled from 'styled-components';
// import { Link } from 'react-router-dom';
import { useLocation } from "react-router-dom";

const PageContainer = styled.div`
  padding: 20px;
  display: flex;
  justify-content: center;
`;

const PageHeader = styled.h1`
  color: #ffffff;
`;

const PageContent = styled.div`
  flex: 1;
  padding-left: 20px;
  padding-right: 20px;
  transition: margin-left 0.3s ease;
  max-width: 800px;

  @media (max-width: 768px) {
    padding-left: 0px;
    padding-right: 0px;
    margin-left: -10px;
    width: 100%;
  }

  @media (min-width: 768px) and (max-width: 1200px) {
  }
`;

const ScheduleHeader = styled.div`
  background-color: #212121;
  display: flex; /* Make ScheduleHeader a flex container */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  text-align: center;
  width: 100%;
  height: 120px;
  padding: 5px;
  border-radius: 20px;
  // background-size: cover;
  // background-position: center;
  // background-clip: padding-box;
  color: white;
  // cursor: pointer;
  margin: auto;
  margin-bottom: 15px;

  // &:hover {
  //   border: 5px solid rgba(255, 255, 255, 0.015);
  //   padding: 0px;
  //   transition: border 0.3s ease, padding 0.3s ease;
  // }
`;

const ButtonText = styled.span`
  font-size: 24px;
  text-shadow: 0 4px 8px #000000;
  font-weight: 600;
  // vertical-align: middle
  // margin: auto;
  // padding-top: 50px;
`;

const ConfirmCard = styled.div`
  display: flex;
  flex-direction: column;
  // flex-wrap: wrap;
  // justify-content: space-evenly;
  // align-items: center;    /* Center vertically */
  height: 400px;
  width: 97%;
  margin-bottom: 10px;
  border: 1px solid #212121;
  border-radius: 20px;
  padding: 15px;
  color: #fff;

  @media (max-width: 768px) {
    width: 93%;
  }
`

const CardText = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  height: 100%;
`

const JobButton = styled.div`
  //background-color: #000;
  display: flex; /* Make JobButton a flex container */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  text-align: center;
  width: 380px;
  height: 50%;
  padding: 5px;
  border-radius: 20px;
  background-image: url("https://www.iiicoast.com/backend-static/upload/3ddesign.png");
  background-size: cover;
  background-position: center;
  background-clip: padding-box;
  color: white;
  cursor: pointer;

  &:hover {
    border: 5px solid rgba(255, 255, 255, 0.015);
    padding: 0px;
    transition: border 0.3s ease, padding 0.3s ease;
  }
`

const PressureWashingButton = styled(JobButton)`
  background-image: url("https://www.iiicoast.com/backend-static/upload/ai-generated-man-power-washing-his-road-in-wet-weather-with-rain-free-photo.jpeg");
`

const SnowShovelingButton = styled(JobButton)`
  background-image: url("https://www.iiicoast.com/backend-static/upload/snow.jpg");
`

const GutterButton = styled(JobButton)`
  background-image: url("https://www.iiicoast.com/backend-static/upload/gutter.jpg");
`

const WindowsButton = styled(JobButton)`
  background-image: url("https://www.iiicoast.com/backend-static/upload/window.jpg");
`
const MoreJobsButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`

const MoreJobsButton = styled.div`
  background: linear-gradient(45deg, #00B050 0%, #00803B 66%, #00B050 88%);
  padding: 5px;
  border-radius: 20px;
  width: 98%;
  height: 60px;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center; /* Center vertically */
  text-align: center;
  // background-image: url("https://www.iiicoast.com/backend-static/upload/3ddesign.png");
  background-size: 200% auto;
  cursor: pointer;
  text-decoration: none;
  // background-image: url("https://www.iiicoast.com/backend-static/upload/3ddesign.png");
  color: white;
  font-size: 20px;
  font-weight: bold;
  // text-shadow: 0 0 10px #000000;

  transition: background-position 0.4s ease-in-out;

  &:hover {
    background-position: right center;
  }
`

const EstimateText = styled.p`
  text-align: right;
  margin-top: auto; /* Pushes the last item to the bottom */
`
// Determine the appropriate placeholder based on the job type
const getUnitLabel = (job) => {
  switch (job) {
    case "Pressure Washing":
    case "Snow Shoveling":
      return "sq ft";
    case "Gutter Cleaning":
      return "ft";
    case "Window Cleaning":
      return "windows";
    default:
      return "Enter details"; // Default placeholder if no job type
  }
};

// Determine the appropriate placeholder based on the job type
const getCostPerMetric = (job) => {
  switch (job) {
    case "Pressure Washing":
      return 0.50;
    case "Snow Shoveling":
      return 0.35;
    case "Gutter Cleaning":
      return 1.50;
    case "Window Cleaning":
      return 5;
    default:
      return "Enter details"; // Default placeholder if no job type
  }
};

const OddConfirm = () => {
  const location = useLocation();
  const { job, date, input } = location.state || {}; // , details
  const costPerMetric = getCostPerMetric(job);
  const totalCost = input * costPerMetric;
  const unitLabel = getUnitLabel(job)

  
  return (
    <PageContainer>
      <PageContent>
        <ScheduleHeader><ButtonText>✅ Booking {job} on {date.toLocaleDateString()}</ButtonText></ScheduleHeader>
        <ConfirmCard>
          <CardText>
            <h3>Estimate</h3>
            <p>{job} for {input} {unitLabel}</p>
            <p>{input} x {costPerMetric} per {unitLabel} = ${totalCost}</p>
            <EstimateText>Final Estimate: ${totalCost}</EstimateText>
          </CardText>
        </ConfirmCard>
        <br></br>
        <MoreJobsButtonContainer>
          <MoreJobsButton>Pay Now</MoreJobsButton>
        </MoreJobsButtonContainer>
        {/*<p>This is a placeholder for the page. We'll add more details here later!</p>*/}
      </PageContent>
    </PageContainer>
  );
};

export default OddConfirm;