import React, { useState, useEffect, useCallback } from 'react';
import questionsData from '../../data/questions.json';
// import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import { useToast } from '../../ToastContext';
import { BarLoader } from 'react-spinners';
import {
  SpinnerContainer,
  GlassContainer,
  ProblemStatement,
  OptionsGrid,
  OptionButton,
  NextButton,
  DifficultyIndicator
} from './StudyStyles'; // Move styled components to a separate file

// Animation variants
const containerVariants = {
  hidden: { opacity: 0, scale: 0.9 },
  visible: { 
    opacity: 1, 
    scale: 1,
    transition: { duration: 0.5 }
  },
  exit: { 
    opacity: 0,
    scale: 0.9,
    transition: { duration: 0.3 }
  }
};

const problemVariants = {
  hidden: { opacity: 0, y: -20 },
  visible: { 
    opacity: 1, 
    y: 0,
    transition: { delay: 0.2, duration: 0.5 }
  }
};

const optionsGridVariants = {
  hidden: { opacity: 0 },
  visible: { 
    opacity: 1,
    transition: { 
      staggerChildren: 0.1,
      delayChildren: 0.3
    }
  }
};

const optionVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: { 
    opacity: 1, 
    y: 0,
    transition: { duration: 0.5 }
  }
};

const generateDivisionProblem = () => {
    console.log('Generating division problem');
    
    let dividend, divisor, answer;
    
    do {
      dividend = Math.floor(Math.random() * 90000) + 10000; // 5-digit number
      divisor = Math.floor(Math.random() * 8) + 2; // 2 to 9
      answer = Math.floor(dividend / divisor);
    } while (divisor === 1 || answer === dividend); // Ensure divisor is not 1 and the problem isn't trivial
    
    console.log('Division values:', { dividend, divisor, answer });
    
    const usePercentageOptions = Math.random() < 0.5;
  
    let otherOptions;
    if (usePercentageOptions) {
      console.log('Using percentage options');
      otherOptions = [
        Math.round(answer * (1 + Math.random() * 0.2)),
        Math.round(answer * (1 - Math.random() * 0.2)),
        Math.round(answer * (1 + (Math.random() * 0.4 - 0.2)))
      ];
    //   setDifficulty('hard');
    } else {
      console.log('Using standard options');
      otherOptions = [
        Math.floor(dividend / (divisor + 1)),
        Math.floor(dividend / (divisor - 1)),
        Math.floor(dividend / (divisor + 2))
      ];
    //   setDifficulty('easy');
    }
  
    otherOptions = otherOptions.filter(opt => opt !== answer && opt > 0);
    const allOptions = [answer, ...otherOptions].sort(() => Math.random() - 0.5);
    
    console.log('Generated options:', allOptions);
    
    return {
        type: 'division',
        numerator: dividend,
        denominator: divisor,
        answer: answer,
        options: allOptions
    };

    // setProblem({ 
    //   type: 'division',
    //   numerator: dividend, 
    //   denominator: divisor, 
    //   answer: answer 
    // });
    // setOptions(allOptions);
  };

  const generateDecimalProblem = () => {
      console.log('Generating decimal problem');
      const numerator = Math.floor(Math.random() * 9) + 1;
      const denominator = Math.floor(Math.random() * 90) + 10;
      const answer = Math.round((numerator / denominator) * 100) / 100;
    
      const generateUniqueOption = (baseAnswer, usedOptions) => {
        let option;
        let attempts = 0;
        console.log('Generating unique option');
        
        do {
          attempts++;
          const variation = (Math.random() * 0.3 + 0.05) * (Math.random() < 0.5 ? 1 : -1);
          option = Math.round((baseAnswer * (1 + variation)) * 100) / 100;
          
          if (attempts > 100) {
            console.warn('Too many attempts to generate unique option');
            break;
          }
        } while (usedOptions.has(option) || option <= 0 || option === baseAnswer);
        
        return option;
      };
    
      const usedOptions = new Set([answer]);
      const otherOptions = [];
    
      for (let i = 0; i < 3; i++) {
        const newOption = generateUniqueOption(answer, usedOptions);
        otherOptions.push(newOption);
        usedOptions.add(newOption);
      }
    
      const allOptions = [answer, ...otherOptions].sort(() => Math.random() - 0.5);
    
      console.log('Setting decimal problem state');
      return {
        type: 'decimal',
        numerator: numerator,
        denominator: denominator,
        answer: answer,
        options: allOptions
      };
    //   setProblem({ 
    //     type: 'decimal',
    //     numerator: numerator, 
    //     denominator: denominator, 
    //     answer: answer 
    //   });
    //   setOptions(allOptions);
    //   setDifficulty('hard');
    };

const GeneralPracticeComponent = () => {
  const { showToast } = useToast();
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [options, setOptions] = useState([]);
  const [optionStatus, setOptionStatus] = useState({});
  const [showNext, setShowNext] = useState(false);
  const [difficulty] = useState('easy'); // , setDifficulty

  // Utility function to shuffle an array
  function shuffleArray(array) {
    const shuffled = [...array]; // Create a copy to avoid mutating the original array
    for (let i = shuffled.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]]; // Swap elements
    }
    return shuffled;
  }

  const generateMathQuestion = useCallback((questionTemplate) => {
    let problem;
    if (questionTemplate.math_type === 'divisor') {
      problem = generateDivisionProblem();
    } else {
      problem = generateDecimalProblem();
    }
    return {
      ...questionTemplate,
      ...problem
    };
  }, []);

  const generateQuestion = useCallback(() => {
    const availableQuestions = questionsData.questions;
    const randomIndex = Math.floor(Math.random() * availableQuestions.length);
    const questionTemplate = availableQuestions[randomIndex];

    let question;
    let questionOptions;

    if (questionTemplate.question_type === 'math') {
      question = generateMathQuestion(questionTemplate);
      questionOptions = question.options;
    } else {
      question = questionTemplate;
      questionOptions = shuffleArray(question.options); // Shuffle the options
      // questionOptions = question.options;
    }

    setCurrentQuestion(question);
    setOptions(questionOptions);
    setOptionStatus({});
    setShowNext(false);
  }, [generateMathQuestion]);

  useEffect(() => {
    generateQuestion();
  }, [generateQuestion]);

  const handleOptionClick = (option) => {
    const correctAnswer = currentQuestion.question_type === 'math' 
      ? currentQuestion.answer 
      : currentQuestion.correct_answer;

    if (option === correctAnswer) {
      setOptionStatus(prev => ({ ...prev, [option]: 'correct' }));
      showToast("Correct!", "success");
      setShowNext(true);
    } else {
      setOptionStatus(prev => ({ ...prev, [option]: 'incorrect' }));
      showToast("Incorrect. Try again!", "danger");
    }
  };

  const renderProblemStatement = () => {
    if (currentQuestion.question_type === 'math') {
      if (currentQuestion.math_type === 'division') {
        return `${currentQuestion.numerator} / ${currentQuestion.denominator} = ?`;
      } else {
        return `${currentQuestion.numerator} / ${currentQuestion.denominator} = ? (rounded to hundredth)`;
      }
    } else {
      return (
        <>
          {/* <div>{currentQuestion.text}</div> */}
          <div>{currentQuestion.sentence}</div>
        </>
      );
    }
  };

  if (!questionsData || !currentQuestion) {
    return (
      <SpinnerContainer>
        <BarLoader color="#888888" loading={!questionsData || !currentQuestion} />
      </SpinnerContainer>
    );
  }

  // Return JSX similar to your original component, but using currentQuestion instead of problem
  return (
    <AnimatePresence mode="wait">
      <GlassContainer
        key={currentQuestion.numerator + currentQuestion.denominator}
        variants={containerVariants}
        initial="hidden"
        animate="visible"
        exit="exit"
      >
        <DifficultyIndicator
          difficulty={difficulty}
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.5 }}
        >
          {difficulty === 'easy' ? '★' : '★★'}
        </DifficultyIndicator>

        <ProblemStatement
          variants={problemVariants}
        >
          {renderProblemStatement()}
        </ProblemStatement>

        <OptionsGrid
          variants={optionsGridVariants}
        >
          {options.map((option, index) => (
            <OptionButton
              key={index}
              variants={optionVariants}
              onClick={() => handleOptionClick(option)}
              status={optionStatus[option]}
              whileTap={{ scale: 0.95 }}
            >
              {option}
            </OptionButton>
          ))}
        </OptionsGrid>

        <AnimatePresence>
          {showNext && (
            <NextButton
              as={motion.button}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 20 }}
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
              onClick={generateQuestion}
            >
              Next Problem
            </NextButton>
          )}
        </AnimatePresence>
      </GlassContainer>
    </AnimatePresence>
  );
};

export default GeneralPracticeComponent;