import React, { useContext } from 'react';
import { AuthContext } from '../../AuthContext';
import { Navigate } from 'react-router-dom';
import styled from 'styled-components';
import { BarLoader } from 'react-spinners';

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #000000;
  color: white;
`;

const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px;
`;

function ProtectedRoute({ children }) {
  const { isAuthenticated, loading } = useContext(AuthContext);
  
  if (loading) {
    return (
      <LoaderWrapper>
        <SpinnerContainer>
          <BarLoader color="#888888" loading={loading} />
        </SpinnerContainer>
      </LoaderWrapper>
    );
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  

  return children;
}

export default ProtectedRoute;
