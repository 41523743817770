import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const PageContainer = styled.div`
  padding: 20px;
  display: flex;
  justify-content: center;
  overflow-y: auto; 
  height: 800px;

  @media (max-width: 768px) {
    height: 80%;
  }
`;

const PageHeader = styled.h1`
  color: #ffffff;
`;

const PageContent = styled.div`
  flex: 1;
  padding-left: 20px;
  padding-right: 20px;
  transition: margin-left 0.3s ease;
  max-width: 800px;
  // overflow-y: auto; 
  margin-bottom 15px;

  @media (max-width: 768px) {
    padding-left: 0px;
    padding-right: 0px;
    margin-left: 0;
    width: 100%;
  }

  @media (min-width: 768px) and (max-width: 1200px) {
  }
`;

const MainContent = styled.div`
  display: flex;
  flex-direction: column;
`

const ButtonContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;    /* Center vertically */
  gap: 15px;
  height: 400px;
  width: 100%;
  margin-bottom: 50px;
`

const JobButton = styled(Link)`
  //background-color: #000;
  display: flex; /* Make JobButton a flex container */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  text-align: center;
  width: 380px;
  height: 50%;
  padding: 5px;
  border-radius: 20px;
  background-image: url("https://www.iiicoast.com/backend-static/upload/3ddesign.png");
  background-size: cover;
  background-position: center;
  background-clip: padding-box;
  color: white;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    border: 5px solid rgba(255, 255, 255, 0.015);
    padding: 0px;
    transition: border 0.3s ease, padding 0.3s ease;
  }
`
const ButtonText = styled.span`
  font-size: 24px;
  text-shadow: 0 4px 8px #000000;
  font-weight: 600;
  // vertical-align: middle
  // margin: auto;
  // padding-top: 50px;
`

const PressureWashingButton = styled(JobButton)`
  background-image: url("https://www.iiicoast.com/backend-static/upload/ai-generated-man-power-washing-his-road-in-wet-weather-with-rain-free-photo.jpeg");
`

const SnowShovelingButton = styled(JobButton)`
  background-image: url("https://www.iiicoast.com/backend-static/upload/snow.jpg");
`

const GutterButton = styled(JobButton)`
  background-image: url("https://www.iiicoast.com/backend-static/upload/gutter.jpg");
`

const WindowsButton = styled(JobButton)`
  background-image: url("https://www.iiicoast.com/backend-static/upload/window.jpg");
`
const MoreJobsButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`

const MoreJobsButton = styled.div`
  background: linear-gradient(45deg, #EE8131 33%, #EA336B 66%, #6E3BF1 100%); // #F8D849 0%, 
  display: flex;
  justify-content: center;
  align-items: center; /* Center vertically */
  text-align: center;
  padding: 5px;
  border-radius: 20px;
  width: 98%;
  height: 60px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  color: white;
  font-size: 20px;
  font-weight: bold;
  background-size: 200% auto;
  transition: background-position 0.4s ease-in-out;
  // background-image: url("https://www.iiicoast.com/backend-static/upload/3ddesign.png");

  &:hover {
    background-position: right center;
  }
`

const OddHome = () => {
  return (
    <PageContainer>
      <PageContent>
        <PageHeader>I need...</PageHeader>
        <MainContent>
          <ButtonContainer>
              <PressureWashingButton to="/test/job" state={{ job: "Pressure Washing" }}><ButtonText>Pressure Washing</ButtonText></PressureWashingButton>
              <SnowShovelingButton to="/test/job" state={{ job: "Snow Shoveling" }}><ButtonText>Snow Shoveling</ButtonText></SnowShovelingButton>
              <GutterButton to="/test/job" state={{ job: "Gutter Cleaning" }}><ButtonText>Gutter Cleaning</ButtonText></GutterButton>
              <WindowsButton to="/test/job" state={{ job: "Window Cleaning" }}><ButtonText>Window Cleaning</ButtonText></WindowsButton>
              <MoreJobsButton>Something Else?</MoreJobsButton>
          </ButtonContainer>
          <br></br>
            
        </MainContent>
        {/*<p>This is a placeholder for the page. We'll add more details here later!</p>*/}
      </PageContent>
    </PageContainer>
  );
};

export default OddHome;