// AppStyles.js
import styled from 'styled-components';

// Styled Loader Component
export const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #000000;
  color: white;
`;

export const AppContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const PageContent = styled.div`
  flex: 1;
  padding-left: 20px;
  padding-right: 20px;
  margin-left: ${props => props.$sidebarMinimized ? '60px' : '240px'};
  transition: margin-left 0.3s ease;
  max-width: 800px;

  @media (max-width: 768px) {
    padding-left: 0px;
    padding-right: 0px;
    margin-left: 0;
    width: 100%;
  }

  @media (min-width: 768px) and (max-width: 1200px) {
    margin-left: ${props => props.$sidebarMinimized ? '80px' : '260px'};
    margin-right: ${props => props.$sidebarMinimized ? '20px' : '0'};
  }
`;

export const NewPostButton = styled.button`
  position: fixed;
  top: 80px;            /* Adjust this value for vertical positioning */
  left: 50%;
  transform: translateX(-50%);  /* Center the button horizontally */
  z-index: 1000;        /* Ensure it appears above other elements */
  padding: 10px 20px;
  background-color: #007bff; /* Blue background */
  color: white;
  border: none;
  border-radius: 25px;
  font-size: 16px;
  cursor: pointer;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #0056b3; /* Darker blue on hover */
  }
`;

export const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px;
`;