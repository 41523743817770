import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';
import axiosInstance from './axiosConfig';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    checkAuthentication();
  }, []);

  const checkAuthentication = async () => {
    try {
      const return_response = await axios.get('/v2/returning-user');
      if (return_response.data.username) {
        setUser(return_response.data.username);
      } else {
        setUser(null);
      }
      // Make a request to a protected endpoint to verify authentication
      const response = await axiosInstance.post(
        '/v2/protected',
        {} // Empty data object as the body is not necessary for this request
      );

      if (response.status === 200) {
        // If response status is 200, user is authenticated
        setIsAuthenticated(true);
      } else {
        // If response status is not 200, user is not authenticated
        setIsAuthenticated(false);
      }
    } catch (error) {
      console.error('Error checking authentication:', error);
      setIsAuthenticated(false);
    } finally {
      setLoading(false);
    }
  };

  const login = async (username, password) => {
    try {
      const response = await axios.post('https://www.iiicoast.com/v2/login2', { username, password });
      console.log(response)
      setIsAuthenticated(true);
      // setUser(response.data.user);
      return; // response.data;
    } catch (error) {
      console.error('Login error:', error);
      throw error;
    }
  };

  const logout = async () => {
    try {
      await axios.post('https://www.iiicoast.com/v2/logout', {}, { withCredentials: true });
      setIsAuthenticated(false);
      // setUser(null);
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, user, loading, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};