// components/index.js
// export { default as Example } from './Example';
// export { default as Header } from './Header';
// export { default as Footer } from './Footer';
// export { default as Button } from './Button';


export { default as Header } from './layout/Header';
export { default as PostList } from './feed/PostList';
export { default as Sidebar } from './layout/Sidebar';
export { default as NotificationTray } from './feed/NotificationTray';
export { default as FloatingActionButton } from './feed/FloatingActionButton';
export { default as Login } from './auth/Login';
export { default as Register } from './auth/Register';
export { default as Onboarding } from './auth/Onboarding';
export { default as ViewThread } from './feed/ViewThread';
export { default as ViewArticle } from './feed/ViewArticle';
export { default as ProtectedRoute } from './auth/ProtectedRoute';
export { default as OddHeader } from './oddjobs/OddHeader';
export { default as OddHome } from './oddjobs/OddHome';
export { default as OddJob } from './oddjobs/OddJob';
export { default as OddConfirm } from './oddjobs/OddConfirm';
export { default as OddWorker } from './oddjobs/OddWorker';
export { default as OddWorkerLogin } from './oddjobs/OddWorkerLogin';
// export { default as OddWorkerJob } from './oddjobs/OddWorkerJob';
export { default as OddLogin } from './oddjobs/OddLogin';
export { default as FounderHeader } from './portfolio/FounderHeader';
export { default as RTEditor } from './RTEditor';

// Previous multi-line imports in App.js
// import Header from './components/Header';
// import PostList from './components/PostList';
// import Sidebar from './components/Sidebar';
// import NotificationTray from './components/NotificationTray';
// import FloatingActionButton from './components/FloatingActionButton';
// import Login from './components/Login';
// import Register from './components/Register';
// import Onboarding from './components/Onboarding';
// import ViewThread from './components/ViewThread';
// import ViewArticle from './components/ViewArticle';
// import ProtectedRoute from './components/ProtectedRoute';
// import OddHeader from './components/oddjobs/OddHeader';
// import OddHome from './components/oddjobs/OddHome';
// import OddJob from './components/oddjobs/OddJob';
// import OddConfirm from './components/oddjobs/OddConfirm';
// import FounderHeader from './components/portfolio/FounderHeader';