import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import useWindowSize from '../hooks/useWindowSize';
import styled, { keyframes } from 'styled-components';
import { FaPlay, FaPause } from "react-icons/fa";

const MusicContainer = styled.div`
  padding: 20px;
  color: #ffffff;
  overflow-x: hidden;

  @media only screen and (max-width: 767px) and (-webkit-min-device-pixel-ratio: 2) {
    padding: 0;
    padding-left: 20px;
    padding-right: 20px;
  }
`;

const MusicHeader = styled.h1`
  margin-bottom: 20px;
`;

const SongList = styled.div`
  height: calc(100vh - 25vh);
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  grid-gap: 20px;
  overflow-y: auto;
  overflow-x: hidden;

  @media only screen and (max-width: 767px) and (-webkit-min-device-pixel-ratio: 2) {
    height: calc(100vh - 32.5vh);
    display: block;
  }
`;

const SongItem = styled.div`
  text-align: center;
  cursor: pointer;
  transition: transform 0.2s;

  &:hover {
    transform: scale(1.05);
  }

  @media only screen and (max-width: 767px) and (-webkit-min-device-pixel-ratio: 2) {
  //  text-align: left;
    margin-bottom: 15px;
  }
`;

const SongThumbnail = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 100%;
  overflow: hidden;
  border-radius: 5px;

  @media only screen and (max-width: 767px) and (-webkit-min-device-pixel-ratio: 2) {
    width: 60px;
    height: 60px;
    padding-bottom: 0;
    float: left;
    margin-right: 15px;
  }
`;

const ThumbnailImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const SongInfo = styled.div`
  padding: 10px 0;

  // @media only screen and (max-width: 767px) and (-webkit-min-device-pixel-ratio: 2) {
  //   padding: 0;
  // }
`;

const SongTitle = styled.h3`
  margin: 0;
  font-size: 16px;
`;

const SongArtist = styled.p`
  margin: 5px 0 0;
  font-size: 14px;
  color: #888;
`;

const AudioPlayer = styled.audio`
  width: 100%;
  margin-top: 20px;

  &::-webkit-media-controls-panel {
    background-color: #000000;
  }

  &::-webkit-media-controls-play-button {
    background-color: #198754;
    color: #fff;
    border-radius: 50%;
  }

  &::-webkit-media-controls-current-time-display,
  &::-webkit-media-controls-time-remaining-display {
    color: #dadada;
  }
`;

const slideUp = keyframes`
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

const SongInfo2 = styled.div`
  display: flex;
  align-items: center;
`;

const SongTitle2 = styled.span`
  font-size: 14px;
  font-weight: bold;
  color: #fff;
`;

const SongArtist2 = styled.span`
  font-size: 12px;
  color: #ccc;
`;

const Thumbnail = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 5px;
  margin-right: 10px;
`;

const SongDetails = styled.div`
  display: flex;
  flex-direction: column;
`;

const CustomAudioPlayer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 15vh;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(10px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  box-sizing: border-box;
  animation: ${slideUp} 0.3s ease-out;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
`;

const Controls = styled.div`
  display: flex;
  align-items: center;
`;

const PlayPauseButton = styled.button`
  background: none;
  border: none;
  color: #fff;
  font-size: 24px;
  cursor: pointer;
`;

const ProgressBar = styled.input`
  width: 100%;
  margin: 0 10px;
`;

const MusicPage = () => {
  const { width } = useWindowSize();
  const isMobile = width <= 768;

  const [songs, setSongs] = useState([]);
  const [currentSong, setCurrentSong] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const audioRef = useRef(null);

  useEffect(() => {
    fetchSongs();
  }, []);

  const fetchSongs = async () => {
    try {
      const response = await axios.get('/api/get-song-info');
      setSongs(response.data);
    } catch (error) {
      console.error('Error fetching songs:', error);
    }
  };

  const playSong = (song) => {
    setCurrentSong(song);
    setIsPlaying(true);

    if ('mediaSession' in navigator) {
      navigator.mediaSession.metadata = new MediaMetadata({
        title: song.title,
        artist: song.artist,
        album: song.album,
        artwork: [
          { src: song.thumbnail_url, sizes: '512x512', type: 'image/png' }
        ]
      });
    }
  };

  const togglePlayPause = () => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause();
      } else {
        audioRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const handleProgressChange = (e) => {
    const newTime = (audioRef.current.duration / 100) * e.target.value;
    audioRef.current.currentTime = newTime;
    setProgress(e.target.value);
  };

  const updateProgress = () => {
    if (audioRef.current) {
      const progressPercent = (audioRef.current.currentTime / audioRef.current.duration) * 100;
      setProgress(progressPercent);
    }
  };

  return (
    <MusicContainer>
      <MusicHeader>Music</MusicHeader>
      <SongList>
        {songs.map((song) => (
          <SongItem key={song.sid} onClick={() => playSong(song)}>
            <SongThumbnail>
              <ThumbnailImage src={song.thumbnail_url} alt={`${song.title} thumbnail`} />
            </SongThumbnail>
            <SongInfo>
              <SongTitle>{song.title}</SongTitle>
              <SongArtist>{song.artist}</SongArtist>
            </SongInfo>
          </SongItem>
        ))}
      </SongList>
      {currentSong && (
        isMobile ? (
          // Render something else or a different component for mobile
          <CustomAudioPlayer>
          <SongInfo2>
            <Thumbnail src={currentSong.thumbnail_url} alt={`${currentSong.title} thumbnail`} />
            <SongDetails>
              <SongTitle2>{currentSong.title}</SongTitle2>
              <SongArtist2>{currentSong.artist}</SongArtist2>
            </SongDetails>
          </SongInfo2>
          <Controls>
            <PlayPauseButton onClick={togglePlayPause}>
              {isPlaying ? <FaPause /> : <FaPlay /> }
            </PlayPauseButton>
            <ProgressBar
              type="range"
              min="0"
              max="100"
              value={progress}
              onChange={handleProgressChange}
            />
          </Controls>
          <audio
            ref={audioRef}
            src={`/api/stream-song/${currentSong.filename}`}
            onTimeUpdate={updateProgress}
            onEnded={() => setIsPlaying(false)}
            autoPlay
          />
        </CustomAudioPlayer>
        ) : (
          <AudioPlayer
            id="audio-player"
            controls
            autoPlay
            src={`/api/stream-song/${currentSong.filename}`}
          />
        )
      )}
    </MusicContainer>
  );
};

export default MusicPage;