import { useState, useEffect, useContext, useRef, useCallback } from 'react';
import { AuthContext } from '../AuthContext';
import axiosInstance from '../axiosConfig';
// import axios from 'axios';

const useLongPolling = () => {
    const [notifications, setNotifications] = useState([]);
    // const [newPost, setNewPost] = useState(null);
    const [unreadCount, setUnreadCount] = useState(0);
    const [postCount, setPostCount] = useState(0);
    const [recentTid, setRecentTid] = useState(0);
    const { isAuthenticated } = useContext(AuthContext);

    const isPollingRef = useRef(false); // Ref to track if polling is already in progress

    const unreadCountRef = useRef(unreadCount); // Ref to keep the latest unreadCount
    unreadCountRef.current = unreadCount; // Update ref with the latest unreadCount

    const postCountRef = useRef(postCount); // Ref to keep the latest unreadCount
    postCountRef.current = postCount; // Update ref with the latest unreadCount

    const isAuthenticatedRef = useRef(isAuthenticated); // Ref to keep the latest unreadCount
    isAuthenticatedRef.current = isAuthenticated; // Update ref with the latest unreadCount

    const pollForUpdates = useCallback(async () => {
        if (!isAuthenticatedRef.current) {
            console.log('User is not authenticated. Skipping long-polling.');
            return;
          }
        if (isPollingRef.current) {
            // If polling is already in progress, skip the new request
            return;
        }
        isPollingRef.current = true;

        try {
            const response = await axiosInstance.get('/v2/check_updates', {
                params: {
                    unread_count: unreadCountRef.current,
                    post_count: postCountRef.current
                }
            });
            
            // Considering ending long-poll on 401 response, trying server-side implicitly refreshing tokens first
            // if (response.status === 401) {
            //     // No updates, retry after a delay
            //     return
            // } else if (response.status === 204) {
            if (response.status === 204) {
                // No updates, retry after a delay
                setTimeout(pollForUpdates, 5000);
            } else {
                const data = response.data;
                console.log('Data type:', data.type); // Log data type for debugging
                console.log('Data content:', data.content); // Log data content for debugging
                console.log('Unread count from server:', data.unread_count); // Log unread_count from server
                console.log(data.type)
                if (data.type === 'notification') {
                    setUnreadCount(data.unread_count);
                    setNotifications(data.notifications);
                    // console.log('New unread count:', unreadCount);
                } else if (data.type === 'post') {
                    setPostCount(data.post_count);
                    setRecentTid(data.recent_tid)
                }
                // Continue polling after handling the response
                setTimeout(pollForUpdates, 15000);
            }
        } catch (error) {
            console.error('Error during long-polling:', error);
            if (error.message === 'Request failed with status code 401') {
                return;
            }
            setTimeout(pollForUpdates, 5000); // Retry after a delay
        } finally {
            isPollingRef.current = false; // Reset polling flag
        }
    }, []);



    useEffect(() => {
        pollForUpdates(); // Start polling when component mounts
        return () => {
            // Optionally, you can add logic to cancel ongoing requests if needed
        };
    }, [isAuthenticated, pollForUpdates]);

    useEffect(() => {
        console.log('Current unreadCount:', unreadCount);
      }, [unreadCount]);

    return { unreadCount, recentTid, notifications };
};

export default useLongPolling;