import React, { useState } from 'react';
import styled from 'styled-components';
// import {  } from 'react-router-dom';
// import { useParams } from 'react-router-dom';
// import MyDatePicker from './MyDatePicker';
import DatePicker from "react-datepicker";
import { useLocation, Link } from "react-router-dom";

import "react-datepicker/dist/react-datepicker.css";
import "./MyCustomDatePicker.css"; // Your custom styles

const PageContainer = styled.div`
  padding: 20px;
  display: flex;
  justify-content: center;
`;

// const PageHeader = styled.h1`
//   color: #ffffff;
// `;

const PageContent = styled.div`
  flex: 1;
  padding-left: 20px;
  padding-right: 20px;
  transition: margin-left 0.3s ease;
  max-width: 800px;

  @media (max-width: 768px) {
    padding-left: 0px;
    padding-right: 0px;
    margin-left: 0;
    width: 100%;
  }

  @media (min-width: 768px) and (max-width: 1200px) {
  }
`;

// const ButtonContainer = styled.div`
//   display: flex;
//   flex-wrap: wrap;
//   justify-content: space-evenly;
//   align-items: center;    /* Center vertically */
//   gap: 15px;
//   height: 400px;
//   width: 100%;
//   margin-bottom: 10px;
// `;

const ScheduleHeader = styled.div`
  //background-color: #000;
  display: flex; /* Make ScheduleHeader a flex container */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  text-align: center;
  width: 100%;
  height: 120px;
  padding: 5px;
  border-radius: 20px;
  /* Dynamically update background based on job type */
  background-image: ${({ job }) => {
    switch (job) {
      case "Pressure Washing":
        return 'url("https://www.iiicoast.com/backend-static/upload/ai-generated-man-power-washing-his-road-in-wet-weather-with-rain-free-photo.jpeg")';
      case "Snow Shoveling":
        return 'url("https://www.iiicoast.com/backend-static/upload/snow.jpg")';
      case "Gutter Cleaning":
        return 'url("https://www.iiicoast.com/backend-static/upload/gutter.jpg")';
      case "Window Cleaning":
        return 'url("https://www.iiicoast.com/backend-static/upload/window.jpg")';
      default:
        return 'url("https://www.iiicoast.com/backend-static/upload/default.jpg")'; // Default image
    }
  }};
  background-size: cover;
  background-position: center;
  background-clip: padding-box;
  color: white;
  // cursor: pointer;
  margin: auto;
  margin-bottom: 15px;

  // &:hover {
  //   border: 5px solid rgba(255, 255, 255, 0.015);
  //   padding: 0px;
  //   transition: border 0.3s ease, padding 0.3s ease;
  // }
`;
const ButtonText = styled.span`
  font-size: 24px;
  text-shadow: 0 4px 8px #000000;
  font-weight: 600;
  // vertical-align: middle
  // margin: auto;
  // padding-top: 50px;
`;

const StyledInputContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
  padding: 5px;
  border-radius: 25px;
  border: 1px solid #212121;
  width: 51.5%;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const StyledInput = styled.input`
  font-size: 16px;
  margin-top: 5px;
  background-color: #000;
  color: #fff;
  border: none;
  flex: 1;
  padding: 5px 10px;
  outline: none;
`;

// const PressureWashingButton = styled(ScheduleHeader)`
//   background-image: url("https://www.iiicoast.com/backend-static/upload/ai-generated-man-power-washing-his-road-in-wet-weather-with-rain-free-photo.jpeg");
// `;

// const SnowShovelingButton = styled(ScheduleHeader)`
//   background-image: url("https://www.iiicoast.com/backend-static/upload/snow.jpg");
// `;

// const GutterButton = styled(ScheduleHeader)`
//   background-image: url("https://www.iiicoast.com/backend-static/upload/gutter.jpg");
// `;

// const WindowsButton = styled(ScheduleHeader)`
//   background-image: url("https://www.iiicoast.com/backend-static/upload/window.jpg");
// `;
const MoreJobsButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const MoreJobsButton = styled(Link)`
  // background-color: #fff;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)}; /* Dim if disabled */
  pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')}; /* Prevent clicks if disabled */
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')}; /* Change cursor if disabled */
  background: linear-gradient(45deg, #1ea7b7 33%, #16488c 66%, #874fbe 100%);
  padding: 5px;
  border-radius: 20px;
  width: 98%;
  height: 60px;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center; /* Center vertically */
  text-align: center;
  color: white;
  font-size: 20px;
  font-weight: bold;
  background-size: 200% auto;
  cursor: pointer;
  text-decoration: none;
  // background-image: url("https://www.iiicoast.com/backend-static/upload/3ddesign.png");

  transition: background-position 0.4s ease-in-out;

  &:hover {
    background-position: right center;
  }
`;

const MutedText = styled.p`
  color: white;
  opacity: 0.33;
  text-align: center;
`;

const OddJob = () => {
    // const { jobId } = useParams();  // This will give you "Snow Shoveling" directly
    const [startDate, setStartDate] = useState(new Date());
    const [inputValue, setInputValue] = useState('');
    const location = useLocation();
    const { job } = location.state || {}; // , details

    // Determine the appropriate placeholder based on the job type
    const getPlaceholder = (job) => {
      switch (job) {
        case "Pressure Washing":
        case "Snow Shoveling":
          return "Enter square footage";
        case "Gutter Cleaning":
          return "Enter footage";
        case "Window Cleaning":
          return "Enter number of windows";
        default:
          return "Enter details"; // Default placeholder if no job type
      }
    };

    // Determine the appropriate placeholder based on the job type
    const getAverageFact = (job) => {
      switch (job) {
        case "Pressure Washing":
        case "Snow Shoveling":
          return "The average single-car driveway and front sidewalk is 300 square feet (roughly double for a two-car driveway.)";
        case "Gutter Cleaning":
          return "The average gutter footage for a single-family home is 150 linear feet.";
        case "Window Cleaning":
          return "The average number of windows in a single-family home is about 10 windows";
        default:
          return "Enter details"; // Default placeholder if no job type
      }
    };

    const handleInputChange = (e) => {
      // setInputValue(e.target.value);
      const value = e.target.value;
      // Allow only integers or empty value
      if (/^\d*$/.test(value)) {
        setInputValue(value);
      }
    };

    // Check if input is a valid integer
    const isValidInput = Number.isInteger(Number(inputValue)) && inputValue !== '';

    return (
      <PageContainer>
        <PageContent>
        <ScheduleHeader job={job}><ButtonText>Schedule {job} on {startDate.toLocaleDateString()}</ButtonText></ScheduleHeader>
          {/* <PageHeader>Booking {job} on {startDate.toLocaleDateString()}</PageHeader> */}
          <MoreJobsButtonContainer>
          <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              showTimeSelect
              inline
            />
            </MoreJobsButtonContainer>
          {/* <ButtonContainer>
            <PressureWashingButton><ButtonText>Pressure Washing</ButtonText></PressureWashingButton>
            <SnowShovelingButton><ButtonText>Snow Shoveling</ButtonText></SnowShovelingButton>
            <GutterButton><ButtonText>Gutter Cleaning</ButtonText></GutterButton>
            <WindowsButton><ButtonText>Window Cleaning</ButtonText></WindowsButton>
          </ButtonContainer> */}
          <MoreJobsButtonContainer>
            <StyledInputContainer>
              <StyledInput
                placeholder={getPlaceholder(job)}
                value={inputValue}
                onChange={handleInputChange}
              />
            </StyledInputContainer>
          </MoreJobsButtonContainer>
          <MutedText>{getAverageFact(job)}</MutedText>
          <br></br>
          <MoreJobsButtonContainer>
            <MoreJobsButton
              to="/test/confirm"
              state={{ job: job, date: startDate, input: inputValue }}
              disabled={!isValidInput}
            >
              Continue
            </MoreJobsButton>
          </MoreJobsButtonContainer>
          {/*<p>This is a placeholder for the page. We'll add more details here later!</p>*/}
        </PageContent>
      </PageContainer>
    );
};

export default OddJob;