//---------------[ WebRTCManager.js ]-------------------- 

class WebRTCManagerClass {
    constructor(myID, socket, localVideoElement) {
        this.myID = myID;
        this.socket = socket;
        this.localVideoElement = localVideoElement;
        this._peer_list = {}; // Store peer connections
        this._ice_candidate_buffer = {}; // Buffer for storing ICE candidates
        this.PC_CONFIG = {
            iceServers: [
                {
                    urls: [
                        'stun:stun.l.google.com:19302', 
                        'stun:stun1.l.google.com:19302',
                        'stun:stun2.l.google.com:19302',
                        'stun:stun3.l.google.com:19302',
                        'stun:stun4.l.google.com:19302'
                    ]
                }
            ]
        };
        this.setupSocketListeners();
    }

    

    setupSocketListeners() {
        this.socket.on("data", (msg) => {
            switch (msg["type"]) {
                case "offer":
                    this.handleOfferMsg(msg);
                    break;
                case "answer":
                    this.handleAnswerMsg(msg);
                    break;
                case "new-ice-candidate":
                    this.handleNewICECandidateMsg(msg);
                    break;
                case "user-connect":  // Add this
                    this.handleUserConnectMsg(msg);
                    break;
                case "user-disconnect":  // Add this
                    this.handleUserDisconnectMsg(msg);
                    break;
                case "user-list":  // Add this
                    this.handleUserListMsg(msg);
                    break;
                default:
                    console.log("[WebRTCManager] Unhandled message type:", msg["type"]);
            }
        });
    }

    // Handle the user list message, similar to your old logic
    handleUserDisconnectMsg(msg) {
        // const myID = msg["my_id"];
        if ("sid" in msg) {
            const receivedSid = msg["sid"];
            if(receivedSid in this._peer_list)
                {
                    this._peer_list[receivedSid].onicecandidate = null;
                    this._peer_list[receivedSid].ontrack = null;
                    this._peer_list[receivedSid].onnegotiationneeded = null;
            
                    delete this._peer_list[receivedSid]; // remove user from user list
                }
            // Start WebRTC after receiving the user list
            // this.startWebRTC();
        }
    }

    // Handle the user list message, similar to your old logic
    handleUserConnectMsg(msg) {
        // const myID = msg["my_id"];
        if ("sid" in msg) {
            const receivedSid = msg["sid"];
            for (let peer_id in receivedSid) {
                if (!this._peer_list[peer_id]) {
                    this._peer_list[peer_id] = undefined;  // Initialize peer
                    // this.addVideoElement(peer_id, receivedList[peer_id]);  // Assuming you have a method to add a video element
                }
            }
            // Start WebRTC after receiving the user list
            // this.startWebRTC();
        }
    }

    // Handle the user list message, similar to your old logic
    handleUserListMsg(msg) {
        console.log("user list recvd ", msg);
        // const myID = msg["my_id"];
        if ("list" in msg) {
            const receivedList = msg["list"];
            for (let peer_id in receivedList) {
                if (!this._peer_list[peer_id]) {
                    this._peer_list[peer_id] = undefined;  // Initialize peer
                    // this.addVideoElement(peer_id, receivedList[peer_id]);  // Assuming you have a method to add a video element
                }
            }
            // Start WebRTC after receiving the user list
            this.startWebRTC();
        }
    }

    startWebRTC() {
        // Send an offer to each peer
        for (let peer_id in this._peer_list) {
            this.invite(peer_id);
        }
    }

    async invite(peer_id) {
        if (this._peer_list[peer_id]) {
            console.error(`[Error] Attempting to start a connection that already exists with peer <${peer_id}>!`);
            return;
        } else if (peer_id === this.myID) {
            console.error("[Error] Trying to connect to self!");
            return;
        }

        console.log(`Creating peer connection for <${peer_id}>...`);
        this.createPeerConnection(peer_id);
        await this.sleep(2000); // Optional, to wait a bit before sending tracks
        let local_stream = this.localVideoElement.srcObject;
        if (local_stream) {
            local_stream.getTracks().forEach((track) => {
                this._peer_list[peer_id].addTrack(track, local_stream);
            });
            console.log(`Added local tracks to <${peer_id}>.`);
        } else {
            console.error("[Error] No local stream available!");
        }
    }

    // Method to remove a peer and clean up
    removePeer(peer_id) {
        console.log(`Removing peer <${peer_id}>...`);

        // Check if the peer exists in the list
        if (this._peer_list[peer_id]) {
            // Close the peer connection
            this._peer_list[peer_id].close();

            // Remove the peer from the list
            delete this._peer_list[peer_id];

            console.log(`Peer <${peer_id}> removed.`);

            // Optionally, remove the video element from the DOM
            // const videoContainer = document.getElementById(`user_container_${peer_id}`);
            // if (videoContainer) {
            //     videoContainer.remove();
            // }
        } else {
            console.warn(`No peer connection found for <${peer_id}>.`);
        }
    }
    // createPeerConnection(peer_id) {
    //     if (this._peer_list[peer_id]) {
    //         console.log(`[Not supposed to happen!] Attempting to create a peer connection that already exists for <${peer_id}>.`);
    //         return;
    //     }
    // 
    //     this._peer_list[peer_id] = new RTCPeerConnection(this.PC_CONFIG);
    // 
    //     // Buffer for candidates while peer connection is not ready
    //     this._peer_list[peer_id].iceCandidateBuffer = [];
    // 
    //     // Handle ICE candidates
    //     this._peer_list[peer_id].onicecandidate = (event) => this.handleICECandidateEvent(event, peer_id);
    // 
    //     // Handle incoming media stream tracks
    //     this._peer_list[peer_id].ontrack = (event) => this.handleTrackEvent(event, peer_id);
    // 
    //     // When renegotiation is needed, create an offer
    //     this._peer_list[peer_id].onnegotiationneeded = () => this.handleNegotiationNeededEvent(peer_id);
    // 
    //     console.log(`Created peer connection for <${peer_id}>.`);
    // 
    //     // Add peer connection logic for handling buffered ICE candidates
    //     if (this._peer_list[peer_id].iceCandidateBuffer) {
    //         this._peer_list[peer_id].iceCandidateBuffer.forEach((candidate) => {
    //             this._peer_list[peer_id].addIceCandidate(candidate).catch(this.logError);
    //         });
    //         // Clear buffer after adding the candidates
    //         this._peer_list[peer_id].iceCandidateBuffer = null;
    //     }
    // }

    createPeerConnection(peer_id) {
        this._peer_list[peer_id] = new RTCPeerConnection(this.PC_CONFIG);
        const pc = this._peer_list[peer_id];
        pc.addEventListener('iceconnectionstatechange', () => {
            console.log(`ICE connection state: ${pc.iceConnectionState}`);
        });
        pc.addEventListener('connectionstatechange', () => {
            console.log(`Connection state: ${pc.connectionState}`);
        });

        this._peer_list[peer_id].onicecandidate = (event) => this.handleICECandidateEvent(event, peer_id);
        this._peer_list[peer_id].ontrack = (event) => this.handleTrackEvent(event, peer_id);
        this._peer_list[peer_id].onnegotiationneeded = () => this.handleNegotiationNeededEvent(peer_id);

        console.log(`Created peer connection for <${peer_id}>.`);
    }

    handleNegotiationNeededEvent(peer_id) {
        this._peer_list[peer_id].createOffer()
            .then((offer) => this._peer_list[peer_id].setLocalDescription(offer))
            .then(() => {
                console.log(`Sending offer to <${peer_id}>...`);
                this.sendViaServer({
                    "sender_id": this.myID,
                    "target_id": peer_id,
                    "type": "offer",
                    "sdp": this._peer_list[peer_id].localDescription
                });
            })
            .catch(this.logError);
    }

    handleOfferMsg(msg) {
        const peer_id = msg['sender_id'];
        console.log(`Received offer from <${peer_id}>`);

        this.createPeerConnection(peer_id);
        const desc = new RTCSessionDescription(msg['sdp']);
        this._peer_list[peer_id].setRemoteDescription(desc)
            .then(() => {
                const local_stream = this.localVideoElement.srcObject;
                local_stream.getTracks().forEach((track) => this._peer_list[peer_id].addTrack(track, local_stream));
            })
            .then(() => this._peer_list[peer_id].createAnswer())
            .then((answer) => this._peer_list[peer_id].setLocalDescription(answer))
            .then(() => {
                console.log(`Sending answer to <${peer_id}>...`);
                this.sendViaServer({
                    "sender_id": this.myID,
                    "target_id": peer_id,
                    "type": "answer",
                    "sdp": this._peer_list[peer_id].localDescription
                });
            })
            .catch(this.logError);
    }

    handleAnswerMsg(msg) {
        const peer_id = msg['sender_id'];
        console.log(`Received answer from <${peer_id}>`);

        const desc = new RTCSessionDescription(msg['sdp']);
        this._peer_list[peer_id].setRemoteDescription(desc).catch(this.logError);
    }

    // handleICECandidateEvent(event, peer_id) {
    //     if (event.candidate) {
    //         const candidate = event.candidate;
    // 
    //         // Check if the peer connection exists
    //         if (this._peer_list[peer_id]) {
    //             // If the peer connection exists, add the ICE candidate
    //             this._peer_list[peer_id].addIceCandidate(candidate)
    //                 .catch(this.logError);
    //         } else {
    //             console.warn(`Peer connection for ${peer_id} not found. Buffering ICE candidate.`);
    // 
    //             // If the peer connection doesn't exist yet, buffer the candidate
    //             if (!this._ice_candidate_buffer[peer_id]) {
    //                 this._ice_candidate_buffer[peer_id] = [];
    //             }
    //             this._ice_candidate_buffer[peer_id].push(candidate);
    //         }
    //     }
    // }

    handleICECandidateEvent(event, peer_id) {
        if (event.candidate) {
            this.sendViaServer({
                "sender_id": this.myID,
                "target_id": peer_id,
                "type": "new-ice-candidate",
                "candidate": event.candidate
            });
        }
    }

    handleNewICECandidateMsg(msg) {
        const peer_id = msg["sender_id"];
        console.log(`Received ICE candidate from <${peer_id}>`);

        const candidate = new RTCIceCandidate(msg.candidate);
        this._peer_list[peer_id].addIceCandidate(candidate).catch(this.logError);
    }

    handleTrackEvent(event, peer_id) {
        console.log(`Received track event from <${peer_id}>`);
        if (event.streams) {
            let videoObject = this.getVideoObj(peer_id)
            if (videoObject) {
                videoObject.srcObject = event.streams[0];
            }
            // this.getVideoObj(peer_id).srcObject = event.streams[0];
        }
    }

    // Helper to send data via socket
    sendViaServer(data) {
        this.socket.emit("data", data);
    }

    logError(error) {
        console.error("[ERROR]", error);
    }

    sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    // Placeholder method to get the video element for a peer
    getVideoObj(peer_id) {
        let userContainer = document.getElementById(`user_container_${peer_id}`);
        if (userContainer) {
            return userContainer.querySelector("video");
        }
        return null;
        // return document.getElementById(`vid_${peer_id}`);
    }
}

export default WebRTCManagerClass;