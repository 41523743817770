import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../../AuthContext';
// import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
  background-color: #000000;
  padding-top: 10vh;
  
  @media (max-width: 768px) {
    padding: 0;
    padding-left: 15px;
    padding-right: 15px;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  background-color: #070707;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 300px;
`;

const Header = styled.h1`
  color: white;
  margin-bottom: 1.5rem;
  text-align: center;
`;

const Label = styled.label`
  margin-bottom: 0.5rem;
  color: grey;
`;

const Input = styled.input`
  color: white;
  background: #212121;
  padding: 0.5rem;
  margin-bottom: 1rem;
  border: 1px solid #797979;
  border-radius: 5px;
  font-size: 1rem;
`;

const SmallLink = styled.a`
  text-decoration: none;
  color: #797979;
  font-size: 0.9rem;
  text-align: right;
  margin-bottom: 1rem;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

const Button = styled.button`
  padding: 0.75rem;
  background: linear-gradient(to right, rgb(13, 104, 210), rgb(67, 26, 172), rgb(43, 91, 255));
  color: #fff;
  border: none;
  border-radius: 5px;
  font-weight: bold;
  font-size: 1rem;
  cursor: pointer;
  margin-bottom: 1rem;
  background-size: 200% auto;
  transition: background-position 0.4s ease-in-out;

  &:hover {
    background-position: right center;
  }
`;

const Error = styled.p`
  color: red;
  margin-bottom: 1rem;
  text-align: center;
`;

const SignUpLink = styled(Link)`
  color: #007bff;
  text-align: center;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

const WhiteText = styled.p`
  color: white;
`

function Login({ setIsAuthenticated, returningUsername }) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { login, user } = useContext(AuthContext);

  // Automatically populate the username if the user is returning
  useEffect(() => {
    if (user) {
      setUsername(user);
    }
  }, [user]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await login(username, password);

      // Add a 500ms delay before navigating
      // navigate('/');
      setTimeout(() => {
        navigate('/');
      }, 25);
    } catch (error) {
      setError(error.response?.data?.error || 'An error occurred');
    }
  };

  // const handleSubmit = (e) => {
  //   e.preventDefault();
// 
  //   axios.post('https://www.iiicoast.com/v2/login2', { username, password })
  //     .then(res => {
  //       console.log('Login successful:', res.data);
  //       // localStorage.setItem('token', res.data.token);
  //       // localStorage.setItem('user', JSON.stringify(res.data.user));
// 
  //       setIsAuthenticated(true);
  //       navigate('/');
  //     })
  //     .catch(error => {
  //       console.error('Login error:', error);
  //       setError(error.response?.data?.error || 'An error occurred');
  //     });
  // };

  return (
    <Container>
      <Form onSubmit={handleSubmit}>
        <Header>
          {user ? `Welcome back, ${user}!` : 'Sign In'}
        </Header>
        {error && <Error>{error}</Error>}
        {!user && (
          <>
            <Label htmlFor="username">Username</Label>
            <Input 
              id="username" 
              type="text" 
              value={username} 
              onChange={(e) => setUsername(e.target.value)} 
              placeholder="Username" 
            />
          </>
        )}
        <Label htmlFor="password">Password</Label>
        <Input 
          id="password" 
          type="password" 
          value={password} 
          onChange={(e) => setPassword(e.target.value)} 
          placeholder="Password" 
        />
        <SmallLink href="#">Forgot Password?</SmallLink>
        <Button type="submit">Login</Button>
        <WhiteText>Don't have an account? <SignUpLink to="/onboarding">Sign Up</SignUpLink></WhiteText>
      </Form>
    </Container>
  );
}

export default Login;
