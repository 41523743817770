import React, { useEffect, useState, useRef, useCallback } from 'react';
import io from 'socket.io-client';
import VideoGrid from './VideoGrid';
import LocalVideo from './LocalVid';
import ControlButtons from './ControlButtons';
import WebRTCManagerClass from './WebRTCManagerClass'; // Import the class version of WebRTCManager
import styled from 'styled-components';

const WebRTCManagerWrapper = styled.div`
  position: relative;
  height: 700px;

  @media (max-width: 768px) {
    height: 700px;  // Tighter layout on mobile
    width: 375px;
    overflow-x: hidden;
  }
`

// const LoaderWrapper = styled.div`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   height: 350px;
//   background-color: #000000;
//   color: white;
// `;


const WebRTCManager = ({ roomId, displayName, profilePictureUrl }) => {
  const [localStream, setLocalStream] = useState(null);
  const [audioMuted, setAudioMuted] = useState(false);
  const [videoMuted, setVideoMuted] = useState(false);
  const [peers, setPeers] = useState([]);

  const socket = useRef(null);
  const webRTCManagerRef = useRef(null); // To keep a reference to the WebRTCManager class instance
  
  const setAudioMuteState = useCallback((mute) => {
    localStream?.getAudioTracks().forEach(track => track.enabled = !mute);
  }, [localStream]);

  const setVideoMuteState = useCallback((mute) => {
    localStream?.getVideoTracks().forEach(track => track.enabled = !mute);
    // const localVideo = document.getElementById('local_vid');
    // const profilePlaceholder = document.getElementById('profilePlaceholder');
// 
    // if (mute) {
    //   localVideo.classList.add('hidden');
    //   profilePlaceholder.src = profilePictureUrl;
    //   profilePlaceholder.classList.remove('hidden');
    // } else {
    //   localVideo.classList.remove('hidden');
    //   profilePlaceholder.classList.add('hidden');
    // }
  }, [localStream]);

  const startCamera = useCallback(async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: { height: 360 },
        audio: true,
      });
      setLocalStream(stream);
      if (webRTCManagerRef.current) {
        webRTCManagerRef.current.localVideoElement.srcObject = stream; // Set the local stream
      }

      // Emit that you are joining the room
      // socket.current.emit('join-room', { room_id: roomId });

      // Set initial mute states
      setAudioMuteState(audioMuted);
      setVideoMuteState(videoMuted);
    } catch (error) {
      console.error("Error starting camera: ", error);
    }
  }, [audioMuted, setAudioMuteState, setVideoMuteState, videoMuted]);

  useEffect(() => {
    // Initialize socket connection
    socket.current = io('https://www.iiicoast.com/');
  
    // Wait until the socket connects and has an ID
    socket.current.on('connect', () => {
      const clientId = socket.current.id;  // Get the unique client ID (socket.id)
  
      // Now that we have the socket id, instantiate the WebRTCManager class with clientId
      webRTCManagerRef.current = new WebRTCManagerClass(clientId, socket.current, document.getElementById('local_vid'));
  
      // Start the camera on load
      startCamera();
  
      // Emit room join event
      socket.current.emit('join-room', { room_id: roomId });
  
      // Handle peer connection events
      socket.current.on('user-connect', (data) => {
        console.log("user-connect ", data);
        addPeer(data);
        webRTCManagerRef.current.invite(data.sid); // Use 'sid' from server for signaling
      });

      // Handle peer connection events
      socket.current.on('user-list', (data) => {
        console.log("user-list ", data);

        if( "list" in data) // not the first to connect to room, existing user list recieved
        {
            let recvd_list = data["list"];  
            // add existing users to user list
            for(let peer_id in recvd_list)
            {
              addPeerMinimal(peer_id);
              // webRTCManagerRef.current.invite(peer_id); // Use 'sid' from server for signaling
            } 
        }    
        
        // webRTCManagerRef.current.invite(data.sid); // Use 'sid' from server for signaling
      });
  
      socket.current.on('user-disconnect', (data) => {
        removePeer(data.sid);
      });
    });
  
    return () => {
      socket.current.disconnect();
      webRTCManagerRef.current = null;
    };
  }, [roomId, displayName, startCamera]);

  // useEffect(() => {
  //   // Initialize socket connection
  //   socket.current = io('https://www.iiicoast.com/');
// 
  //   // Start the camera on load
  //   startCamera();
// 
  //   socket.current.emit('join-room', { room_id: roomId });
// 
  //   // Create WebRTCManager instance and pass socket
  //   webRTCManagerRef.current = new WebRTCManagerClass(displayName, socket.current, document.getElementById('local_vid'));
// 
  //   // Handle peer connection events
  //   socket.current.on('user-connect', (data) => {
  //     addPeer(data);
  //     webRTCManagerRef.current.invite(data.sid); // Call invite when a new user connects
  //   });
// 
  //   socket.current.on('user-disconnect', (data) => {
  //     removePeer(data.sid);
  //   });
// 
  //   return () => {
  //     // Cleanup socket connection and WebRTC
  //     socket.current.disconnect();
  //     webRTCManagerRef.current = null;
  //   };
  // }, [roomId, displayName]);

  

  const toggleAudio = () => {
    setAudioMuted(!audioMuted);
    setAudioMuteState(!audioMuted);
  };

  const toggleVideo = () => {
    setVideoMuted(!videoMuted);
    setVideoMuteState(!videoMuted);
  };

  const addPeerMinimal = (peerData) => {
    setPeers(prevPeers => [...prevPeers, { id: peerData, displayName: 'peerData.name' }]);
  };

  const addPeer = (peerData) => {
    setPeers(prevPeers => [...prevPeers, { id: peerData.sid, displayName: peerData.name }]);
  };

  const removePeer = (peerId) => {
    setPeers(prevPeers => prevPeers.filter(peer => peer.id !== peerId));
    if (webRTCManagerRef.current) {
      webRTCManagerRef.current.removePeer(peerId); // Clean up WebRTC peer connections if needed
    }
  };

  return (
    <WebRTCManagerWrapper>
      {/*
      {(peers.length > 0) ? (
          <VideoGrid peers={peers}/>
      ) : (
          <LoaderWrapper>
            No users in room...
          </LoaderWrapper>
      )}
      */}
      <VideoGrid peers={peers}/>
      <LocalVideo stream={localStream} muted={true} videoMuted={videoMuted} profilePictureUrl={profilePictureUrl}/>
      <ControlButtons
        toggleAudio={toggleAudio}
        toggleVideo={toggleVideo}
        audioMuted={audioMuted}
        videoMuted={videoMuted}
      />
    </WebRTCManagerWrapper>
  );
};

export default WebRTCManager;
