// pages/index.js
// export { default as Example } from './Example';
// export { default as Header } from './Header';
// export { default as Footer } from './Footer';
// export { default as Button } from './Button';

export { default as ProfilePage } from './ProfilePage';
export { default as ViewProfilePage } from './ViewProfilePage';
export { default as DraftsPage } from './DraftsPage';
export { default as ExplorePage } from './ExplorePage';
export { default as MusicPage } from './MusicPage';
export { default as VideoPage } from './VideoPage';
export { default as ChatbotPage } from './ChatbotPage';
export { default as RoomsPage } from './RoomsPage';
export { default as MessagingPage } from './MessagingPage';
export { default as ConversationPage } from './ConversationPage';
export { default as Portfolio } from './portfolio/Portfolio';
export { default as LabPage } from './LabPage';
export { default as AppsPage } from './AppsPage';
export { default as StudyPage } from './StudyPage';
export { default as HabitsPage } from './HabitsPage';

// Previous multi-line imports in App.js
// import ProfilePage from './pages/ProfilePage';
// import ViewProfilePage from './pages/ViewProfilePage';
// import ExplorePage from './pages/ExplorePage';
// import MusicPage from './pages/MusicPage';
// import VideoPage from './pages/VideoPage';
// import ChatbotPage from './pages/ChatbotPage';
// import RoomsPage from './pages/RoomsPage';
// import MessagingPage from './pages/MessagingPage';
// import ConversationPage from './pages/ConversationPage';
// import Portfolio from './pages/Portfolio';
// import LabPage from './pages/LabPage';