import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/dist/ScrollToPlugin';
import AnimatedTitle from '../components/portfolio/AnimatedTitle';
import { SiJavascript, SiReact, SiNodedotjs, SiPython, SiMysql, SiPytorch, SiTensorflow, SiJupyter } from 'react-icons/si';
// SiDocker, SiMariadb, SiAnaconda, SiArrowDown 
import { FaArrowDown, FaExternalLinkAlt } from 'react-icons/fa';
// FaEnvelope 
import { LiaFlagUsaSolid } from "react-icons/lia";
// import { IoMdPhonePortrait } from "react-icons/io";
// import { MdComputer } from "react-icons/md";
import { motion } from "framer-motion";
import { useNavigate } from 'react-router-dom'; // Import Link from react-router-dom
import axios from 'axios';


gsap.registerPlugin(ScrollTrigger, ScrollToPlugin); // (or in the top-level file in your React app)

const Header = styled.header`
  background: linear-gradient(to top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
  color: white;
  padding: 2rem;
  text-align: center;
  position: fixed;
  width: 96.9vw;
  top: 0;
  transition: top 0.3s;
  z-index: 100;
  display: flex;
  justify-content: space-between; // This will push the two items to opposite ends

  @media (max-width: 1700px) {
    width: 94.9vw;
  }

  @media (max-width: 768px) {
    display: none;
    
  }
`;

const HeaderLeft = styled.span`
  text-align: left;
  display: flex;
  flex-direction: column
`
const HeaderTitle = styled.span`
  font-size: 22px;
`

const HeaderSubTitle = styled.span`
  opacity: 0.5;
  line-height: 175%;
`

const HeaderRight = styled.span`
  text-align: right;
`

const HeaderLink = styled.a`
  padding: 0.9rem;
  text-decoration: none;
  cursor: pointer;
  color: #fff;

  svg {
    padding-left: 0.3rem;
  }
`

// const ContactIcons = styled.div`
//   padding: 1px;
// `

// const MailIcon = styled.span`
//   font-size: 1.5em;
// `

const FooterWrapper = styled.div`
  display: flex;
`

const Footer = styled.div`
  position: absolute;
  bottom: 0%;
  height: 20vh;
  width: 100%;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 75%);
  z-index: 100;
  display: flex;
  justify-content: space-between; // This will push the two items to opposite ends

  @media (max-width: 1700px) {
    height: 30vh;
  }
`

const FooterLeft = styled.span`
  padding-top: 115px;
  padding-left: 115px;
  display: flex;
  flex-direction: column
`

const FooterRight = styled.span`
  padding-top: 135px;
  padding-right: 115px;
  opacity: 0.5;
`

const FooterTitle = styled.span`
  font-size: 16px;
`

const FooterSubTitle = styled.span`
  opacity: 0.5;
  line-height: 200%;
  text-align: left;
`

const SubtTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  justify-content: space-evenly; // This will push the two items to opposite ends
`

const FooterIcon = styled.span`
  margin-left: 5px;
  font-size: 1.5em;
  display: flex; // This helps with alignment
  align-items: center; // This ensures the icon itself is vertically centered
`

const PortfolioContainer = styled.div`
  // width: 100vw;
  // height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  font-family: 'Arial', sans-serif;
  // position: relative;
`;

const FullScreenSection = styled.section`
  height: 100vh;
  min-height: 600px; // Ensure a minimum height on smaller screens
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  background: #070707;
  color: #fff;
  overflow-x: hidden;
`;

const SplashSection = styled(FullScreenSection)`
  // background: url("https://picsum.photos/1600/900") center/cover no-repeat;
  background: #000;
`;

// First, create the styled components for the arrow
const ScrollIndicator = styled.div`
  position: absolute;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;
  z-index: 10;
`;

const AnimatedArrow = styled(FaArrowDown)`
  color: #ffffff;
  font-size: 24px;
  opacity: 0.8;
`;

const SectionTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 60px;
  margin-left: 40px;
  margin-right: 40px;
  height: 600px;
  width: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
  // gap: 15px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media (max-width: 1700px) {
    margin-top: 180px;
    margin-left: 5px;
    
  }

  @media (max-width: 768px) {
    font-size: 1.5rem;
    width: 300px;
    margin-top: 0;
    margin-left: 0;
    margin-right: 0;
    transform: translate(-50%, -30%);
    
  }
`

const SectionTextMidContainer = styled.div`
  display: flex;
  flex-direction: column;
  
  margin-top: 50px;
  margin-left: 40px;
  margin-right: 40px;
  // height: 600px;
  // width: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
  gap: 0px;
  // position: absolute;
  // top: 50%;
  // left: 50%;
  // transform: translate(-50%, -95%);

  @media (max-width: 768px) {
    font-size: 1.5rem;
    // width: 300px;
    // margin-top: 0;
    // margin-left: 0;
    // margin-right: 0;
    // transform: translate(-50%, -30%);
    
  }
`

const SectionTextSmall = styled.div`
  font-size: 1.85rem;
  // text-transform: uppercase;
  color: white;
  
  // margin: 35px;
  // position: absolute;
  // top: 50%;
  // left: 50%;
  // transform: translate(-50%, -50%);
  z-index: 2;
  transition: transform 0.8s ease, opacity 0.4s ease;
  opacity: 1;
  text-align: left;
  font-weight: bold;
  font-family: 'Ubuntu', sans-serif;

  @media (max-width: 1700px) {
    font-size: 1.2rem;
  }

  @media (max-width: 768px) {
    font-size: 0.9rem;
    
  }
`;

const SectionText = styled.div`
  font-size: 3rem;
  // text-transform: uppercase;
  color: white;
  
  // margin: 35px;
  // position: absolute;
  // top: 50%;
  // left: 50%;
  // transform: translate(-50%, -50%);
  z-index: 2;
  transition: transform 0.8s ease, opacity 0.4s ease;
  opacity: 1;
  text-align: left;
  font-weight: bold;
  font-family: 'Ubuntu', sans-serif;



  @media (max-width: 1700px) {
    font-size: 2.5rem;
    
  }

  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

const SectionTextMid = styled.div`
  font-size: 2rem;
  // text-transform: uppercase;
  color: white;
  // letter-spacing: 0.01em;
  // line-height: 50%;

  // margin: 35px;
  // transform: translate(-50%, -50%);
  z-index: 2;
  transition: transform 0.8s ease, opacity 0.4s ease;
  opacity: 1;
  text-align: left;
  font-weight: 500;
  font-family: 'Ubuntu', sans-serif;

  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

// const SectionTextMidBackup = styled.div`
//   font-size: 2rem;
//   // text-transform: uppercase;
//   color: white;
  
//   // margin: 35px;
//   position: absolute;
//   top: 7%;
//   left: 5%;
//   // transform: translate(-50%, -50%);
//   z-index: 2;
//   transition: transform 0.8s ease, opacity 0.4s ease;
//   opacity: 1;
//   text-align: left;
//   font-weight: 400;
//   font-family: 'Ubuntu', sans-serif;

//   @media (max-width: 768px) {
//     font-size: 1.5rem;
//   }
// `;

const SectionTextAlt = styled.div`
  font-size: 3rem;
  // text-transform: uppercase;
  color: white;
  // position: absolute;
  // top: 20%;
  // left: 50%;
  // transform: translate(-50%, -50%);
  z-index: 2;
  transition: transform 0.8s ease, opacity 0.4s ease;
  opacity: 1;
  text-align: left;
  font-weight: bold;
  font-family: 'Ubuntu', sans-serif;



  @media (max-width: 1700px) {
    font-size: 2.5rem;
    
  }

  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`;
const SectionImageContainer = styled.div`
  // flex: 1;
  padding: 0;
  width: 95%;
  height: 600px;
  border-radius: 50%;
  object-fit: cover;
  background: none;
  clip-path: polygon(37.5% 20%, 62.5% 20%, 62.5% 80%, 37.5% 80%);
  transform: rotate(30deg);
  transition: all 0.8s ease;
  margin-top: 15px;
  // margin-bottom: 30px;
  margin-left: auto;
  margin-right: auto;
  
  

  @media (max-width: 768px) {
    width: 100%;
    height: 300px;
    margin-top: 0;
  }
`;

const SectionVideoContainer = styled.div`
  // flex: 1;
  padding: 0;
  // width: 95%;
  // height: 600px;
  border-radius: 50%;
  object-fit: cover;
  background: none;
  clip-path: polygon(37.5% 20%, 62.5% 20%, 62.5% 80%, 37.5% 80%);
  transform: rotate(30deg);
  transition: all 0.8s ease;
  margin-top: 15px;
  // margin-bottom: 30px;
  margin-left: auto;
  margin-right: auto;
  // position: absolute;
  // right: 0px;
  
  

  @media (max-width: 768px) {
    width: 80vw;
    height: 300px;
    margin-top: 0;
  }
`;

const SectionImage = styled.img`
  // flex: 1;
  padding: 0;
  margin-left: 350px;
  margin-right: -70px;
  width: 50%;
  height: 450px;
  border-radius: 25px;
  object-fit: cover;
  background: none;
  transform: scale(2);
  transition: all 0.8s ease;
  
  @media (max-width: 1700px) {
    margin-left: 300px;
    height: 350px;
  }

  @media (max-width: 768px) {
    width: 300px;
    margin-left: 0;
    margin-right: 0;
    height: 250px;
    
  }
`;

const SectionImageBackground = styled.div`
  // background: linear-gradient(45deg, #212121 0%, #171717 33%, #0E0E0E 66%, #070707 100%);
  background: none;


  // width: 95%;
  max-width: 950px;
  height: 450px;
  // max-height: 150px;
  padding: 0px 0px 15px;
  border-radius: 25px;
  position: relative;
  // margin-top: 150px;
  
  &:hover ${SectionImage} {
    transform: scale(1);
  }

  &:hover ${SectionImageContainer} {
    transform: rotate(0deg);
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
  }

  &:hover ${SectionText} {
    transform: translateX(-100%);
    opacity: 0;
  }

  &:hover ${SectionTextAlt} {
    transform: translateX(-200%);
    opacity: 0;
  }

  @media (max-width: 1700px) {
    max-width: 950px;
    height: 350px;
    padding: 35px 0px 0px;
  }
  
  @media (max-width: 768px) {
    width: 300px;
    height: 250px;
    padding: 0;
    // margin-left: 0;
    // margin-right: 0;
    
  }

  

`

const SectionVideoBackground = styled.div`
  // background: linear-gradient(45deg, #212121 0%, #171717 33%, #0E0E0E 66%, #070707 100%);
  background: none;


  width: 75%;
  // max-width: 950px;
  // height: 65%;
  max-height: 55vh;
  
  // max-height: 150px;
  // padding: 35px 0px 15px;
  border-radius: 15px;
  // position: absolute;
  // top: 10%;
  // left: 2%;
  // margin-top: 150px;
  margin: auto;
  
  
  &:hover ${SectionImage} {
    transform: scale(1);
  }

  &:hover ${SectionImageContainer} {
    transform: rotate(0deg);
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
  }

  &:hover ${SectionText} {
    transform: translateX(-100%);
    opacity: 0;
  }

  &:hover ${SectionTextAlt} {
    transform: translateX(-200%);
    opacity: 0;
  }
  
  @media (max-width: 1700px) {
    width: 65%;
  }

  @media (max-width: 768px) {
    width: 60vw;
    height: 50vh;
    padding: 0;
    // margin-left: 0;
    // margin-right: 0;
    
  }

`

const IntroSection = styled(FullScreenSection)`
  // background: #1a1a1a;
  background: linear-gradient(45deg, #212121 0%, #171717 33%, #0E0E0E 66%, #070707 100%);
`;

const SkillsSection = styled(FullScreenSection)`
  background: linear-gradient(315deg, #070707 0%, #0E0E0E 33%, #171717 66%, #212121 100%);
`;

const ProjectsSection = styled(FullScreenSection)`
  // background: #3a3a3a;
  background: linear-gradient(47deg, #212121 0%, #171717 33%, #0E0E0E 66%, #070707 100%);
`;

const FooterSection = styled(FullScreenSection)`
  // background: #4a4a4a;
  background: linear-gradient(313deg, #070707 0%, #0E0E0E 33%, #171717 66%, #212121 100%);
  position: relative;
`;

const ProjectCard = styled.div`
  background-color: rgba(0, 0, 0, 0.4);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(5px);
  position: relative;
  display: flex;
  flex-direction: column;
  // justify-content: center;
  // justify-content: space-between;
  // align-items: center;
  height: 80%;
  // width: ${({ $mobileView }) => $mobileView ? '35%' : '65%'};
  width: 65%;
  border: 10px solid rgba(255, 255, 255, 0.03);
  border-radius: 20px; /* Adjust this value for more or less rounded corners */
  box-shadow: 0 40px 80px #00000080;
  transition: background-color 0.3s ease, background 0.3s ease, width 1s ease;

  @media (max-width: 768px) {
    width: 90%;
  }

  // &:hover {
  //   background-color: rgba(0, 0, 0, 0.3);
  //   // background: linear-gradient(313deg, #070707 0%, #0E0E0E 33%, #171717 66%, #212121 100%);
  //   // background: linear-gradient(45deg, #9e87cf 0%, #1ea7b7 33%, #16488c 66%, #874fbe 100%);
  // }
`

// const ProjectViewToggle = styled.button`
//   position: absolute;
//   top: 3%;
//   right: 5%;
//   // color: rgba(0, 0, 0, 0)
//   // background-color: transparent;
//   // border: none;
//   background: none;
// 	color: inherit;
// 	border: none;
//   padding: 30px;
//   cursor: pointer;
  

//   svg {
//     font-size: 1.7em;
//     color: #fff
//     // padding: 30px;
//     cursor: pointer;
//   }
// `

const ContactCard = styled.div`
  // background-color: rgba(0, 0, 0, 0.4);
  // -webkit-backdrop-filter: blur(10px);
  // backdrop-filter: blur(5px);
  // position: relative;
  display: flex;
  flex-direction: column;
  // justify-content: center;
  justify-content: space-evenly;
  // align-items: center;
  height: 80%;
  width: 65%;
  // border: 10px solid rgba(255, 255, 255, 0.03);
  // border-radius: 20px; /* Adjust this value for more or less rounded corners */
  // box-shadow: 0 40px 80px #00000080;
  // transition: background-color 0.3s ease, background 0.3s ease;

  @media (max-width: 768px) {
    width: 85%;
  }

  // &:hover {
  //   background-color: rgba(0, 0, 0, 0.3);
  //   // background: linear-gradient(313deg, #070707 0%, #0E0E0E 33%, #171717 66%, #212121 100%);
  //   // background: linear-gradient(45deg, #9e87cf 0%, #1ea7b7 33%, #16488c 66%, #874fbe 100%);
  // }
`

// const Title = styled.h1`
//   font-size: 3rem;
//   margin-bottom: 1rem;
//   opacity: 0;
// `;

const ContactTitle = styled.h2`
  font-size: 2.5rem;
  margin-bottom: -3rem;
`;

const ContactForm = styled.form`
  padding: 5px;
  display: flex;
  flex-direction: column;
`

// const Subtitle = styled.h2`
//   font-size: 1.5rem;
//   margin-bottom: 2rem;
//   opacity: 0;
// `;

// const Content = styled.div`
//   max-width: 800px;
//   opacity: 0;
// `;

// const SkillsList = styled.ul`
//   list-style-type: none;
//   padding: 0;
//   display: flex;
//   flex-wrap: wrap;
//   justify-content: center;
// `;

// const SkillItem = styled.li`
//   background: #fff;
//   color: #070707;
//   padding: 0.5rem 1rem;
//   margin: 0.5rem;
//   border-radius: 20px;
//   opacity: 0;
// `;

// const ProjectGrid = styled.div`
//   display: grid;
//   grid-template-columns: repeat(2, 1fr);
//   gap: 2rem;
//   max-width: 800px;
// `;

// const ProjectCardTemp = styled.div`
//   background: #fff;
//   color: #070707;
//   padding: 1rem;
//   border-radius: 10px;
//   opacity: 0;
// `;

// const ProjectImage = styled.img`
//   width: 100%;
//   height: 150px;
//   object-fit: cover;
//   border-radius: 5px;
//   margin-bottom: 1rem;
// `;

const SkillsBackground = styled.div`
  // background: linear-gradient(45deg, #91E2B6 0%, #118ECF 33%, #15CC94 66%, #91C40E 100%);
  // background: linear-gradient(45deg, #9e87cf 0%, #1ea7b7 33%, #16488c 66%, #874fbe 100%);
  background: none;
  padding: 0;
  border-radius: 25px;
  // box-shadow: 10px 5px 5px red;
  margin-top: 75px;

  @media (max-width: 768px) {
    padding: 0;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 50px;
  }
`

const SkillsForeground = styled.div`
  background-color: rgba(0, 0, 0, 0.4);
  -webkit-backdrop-filter: blur(50px);
  backdrop-filter: blur(50px);
  padding: 35px;
  border: 10px solid rgba(255, 255, 255, 0.03);
  border-radius: 25px;
  // margin-bottom: 25px;

  @media (max-width: 768px) {
      padding: 15px;
  }
`

const SkillsContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 100px; /* Adjust height as necessary */
    width: 800px;
    max-width: 800px;
    margin-bottom: 25px;

    @media (max-width: 768px) {
      height: 50px; /* Adjust height as necessary */
      margin-bottom: 50px;
      width: 225px;
    }

    @media (max-width: 1700px) {
    max-width: 650px;
    height: 70px; /* Adjust height as necessary */
    }
`;

const SkillIcon = styled.div`
    position: relative;
    display: inline-block;
    font-size: 50px; /* Adjust size as needed */
    margin: 20px; /* Adjust spacing */
    transform: rotate(${(props) => props.rotation}deg);
    // animation: float 3s ease-in-out infinite;
    transition: transform 0.3s;

    &:hover {
        transform: scale(1.1) rotate(${(props) => props.rotation}deg);
    }

    @keyframes float {
        0%, 100% {
            transform: translateY(0);
        }
        50% {
            transform: translateY(-10px);
        }
    }

    &:hover span {
      opacity: 1;
      transform: translateY(-10px);
      transform: rotate(0deg);
    }

    @media (max-width: 768px) {
      font-size: 32px; /* Adjust size as needed */
      margin: 5px; /* Adjust spacing */
    }

    @media (max-width: 1700px) {
    font-size: 40px; /* Adjust size as needed */
    }
`;

const Tooltip = styled.span`
  position: absolute;
  bottom: -35px;
  left: -20%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  white-space: nowrap;
  font-size: 12px;
  opacity: 0;
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
  pointer-events: none;
`;

const StyledVideo = styled.video`
  // margin-top: 7vh;
  // margin-bottom: 15px;
  // margin-left: 30px;
  width: 100%;
  // max-height: 55vh;
  border-radius: 15px; /* Adjust this value for more or less rounded corners */
  overflow: hidden; /* Ensures that the content inside the border doesn't overflow */
  // box-shadow: 0px 4px 10px rgba(255, 255, 255, 0.2); /* Optional: Adds a shadow for a nicer effect */
  border: 1px solid rgba(255, 255, 255, 0.2); /* Outer border */

  @media (max-width: 768px) {
    width: 70vw;
    // max-height: 100vh;
    // margin-left: 0;
    // margin-right: 0;
    
  }
`;

// const StyledVideoAlt = styled.video`
//   width: 100%;
//   max-height: 50vh;
//   object-fit: contain;
//   margin-bottom: 2rem;
// `;

// const StyledVideo2 = styled.video`
//   width: 100%;
//   max-height: 50vh;
//   object-fit: contain;
//   margin-bottom: 2rem;
// `;

const ShinyButton = styled(motion.button)`
  margin: auto;
  width: 10vw;
  position: relative;
  font-size: 1rem;
  // font-weight: bold;
  padding-left: 1.5rem; /* 24px */
  padding-right: 1.5rem; /* 24px */
  padding-top: 1rem; /* 8px */
  padding-bottom: 1rem; /* 8px */
  margin-bottom: 3vh;
  border-radius: 0.5rem;
  color: rgb(212 212 212);
  cursor: pointer;
  background: radial-gradient(
    circle at 50% 0%,
    rgba(250, 250, 250, 0.05) 0%,
    transparent 60%
  ), rgba(15, 15, 15, 1);
  border: none;
  mask-image: linear-gradient(
    -75deg,
    white calc(var(--x) + 20%),
    transparent calc(var(--x) + 30%),
    white calc(var(--x) + 100%)
  );
  -webkit-mask-image: linear-gradient(
    -75deg,
    white calc(var(--x) + 20%),
    transparent calc(var(--x) + 30%),
    white calc(var(--x) + 100%)
  );

  @media (max-width: 768px) {
    width: 50vw;
    // margin-left: 0;
    // margin-right: 0;
    
  }
`

// const ShinyButtonBackup = styled(motion.button)`
//   position: relative;
//   font-size: 1rem;
//   // font-weight: bold;
//   padding-left: 1.5rem; /* 24px */
//   padding-right: 1.5rem; /* 24px */
//   padding-top: 1rem; /* 8px */
//   padding-bottom: 1rem; /* 8px */
//   margin-top: 55%;
//   border-radius: 0.5rem;
//   color: rgb(212 212 212);
//   cursor: pointer;
//   background: radial-gradient(
//     circle at 50% 0%,
//     rgba(250, 250, 250, 0.05) 0%,
//     transparent 60%
//   ), rgba(15, 15, 15, 1);
//   border: none;
//   mask-image: linear-gradient(
//     -75deg,
//     white calc(var(--x) + 20%),
//     transparent calc(var(--x) + 30%),
//     white calc(var(--x) + 100%)
//   );
//   -webkit-mask-image: linear-gradient(
//     -75deg,
//     white calc(var(--x) + 20%),
//     transparent calc(var(--x) + 30%),
//     white calc(var(--x) + 100%)
//   );
// `

const ButtonOverlay = styled.span`
  position: absolute;
  inset: 0px;
  // height: 100%;
  padding: 2px;
  border-radius: 0.5rem;
  background-image: linear-gradient(
    -75deg,
    rgba(255, 255, 255, 0.1) calc(var(--x) + 20%),
    rgba(255, 255, 255, 0.5) calc(var(--x) + 25%),
    rgba(255, 255, 255, 0.1) calc(var(--x) + 100%)
  );
`
const ButtonText = styled.span`
	// display: block;
  // position: relative;
  letter-spacing: 0.025em;
  height: 100%;
  width: 100%;
  font-weight: 300;
`

// const HeaderText = styled.div`
//   text-align: left;
//   margin-bottom: 2rem;
// `;

// const Title = styled.h2`
//   font-size: 2.5rem;
//   margin-bottom: 0.5rem;
// 
//   @media (max-width: 768px) {
//     font-size: 2rem;
//   }
// `;

const SubTitle = styled.h3`
  font-size: 1.25rem;
  text-align: left;
  font-weight: 300;
  line-height: 25%;
  letter-spacing: 0.01em;

  // font-weight: normal;
  opacity: 0.8;

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const SubTitleMuted = styled.span`
  font-size: 1rem;
  text-align: left;
  font-weight: 200;
  line-height: 25%;
  letter-spacing: 0.01em;

  // font-weight: normal;
  font-family: monospace;
  opacity: 0.25;

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

// const ShinyButton2 = styled.button`
//   padding: 1rem 2rem;
//   font-size: 1rem;
//   background: linear-gradient(45deg, #ff6b6b, #4ecdc4);
//   border: none;
//   border-radius: 50px;
//   color: white;
//   cursor: pointer;
//   transition: transform 0.3s ease;

//   &:hover {
//     transform: scale(1.05);
//   }
// `;

const Input = styled.input`
  color: white;
  background: #212121;
  padding: 0.5rem;
  margin-bottom: 1rem;
  border: 1px solid #797979;
  border-radius: 5px;
  font-size: 1rem;
`;

const TextAreaInput = styled.textarea`
  color: white;
  background: #212121;
  padding: 0.5rem;
  margin-bottom: 1rem;
  border: 1px solid #797979;
  border-radius: 5px;
  font-size: 1rem;
  width: 20vw;
  height: 10vh;
`;

const ProjectWrapper = styled.div`
  height: 80vh;
  display: flex;
  flex-direction: column;
  // justify-content: space-between;
`

const ProjectHeader = styled.span`
  font-size: 1.75rem;
  font-weight: 500;
  padding-top: 35px;
  padding-bottom: 15px;
`

const CardWrapper = styled.div`
  display: flex;
  width: 65vw;
  height: 60vh;
  justify-content: space-evenly;
`

const Card = styled.div`
  background-color: rgba(0, 0, 0, 0.4);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(5px);
  margin: 20px;
  padding: 35px 27px 15px;
  position: relative;
  display: flex;
  flex-direction: column;
  // justify-content: center;
  // justify-content: space-between;
  // align-items: center;
  height: 100%;
  width: 100%;
  border: 10px solid rgba(255, 255, 255, 0.03);
  border-radius: 20px; /* Adjust this value for more or less rounded corners */
  box-shadow: 0 40px 80px #00000080;
  transition: background-color 0.3s ease, background 0.3s ease, width 1s ease;

  @media (max-width: 768px) {
    width: 90%;
  }

  // &:hover {
  //   background-color: rgba(0, 0, 0, 0.3);
  //   // background: linear-gradient(313deg, #070707 0%, #0E0E0E 33%, #171717 66%, #212121 100%);
  //   // background: linear-gradient(45deg, #9e87cf 0%, #1ea7b7 33%, #16488c 66%, #874fbe 100%);
  // }

  &:hover::after {
    content: '';
    position: absolute;
    top: -10px; /* Match the existing border thickness */
    left: -10px; /* Match the existing border thickness */
    right: -10px; /* Match the existing border thickness */
    bottom: -10px; /* Match the existing border thickness */
    border: 1px solid rgba(255, 255, 255, 0.2); /* Outer border */
    border-radius: 20px; /* Match or slightly larger than the parent border */
    pointer-events: none; /* Prevent interaction with the pseudo-element */
    transition: border 0.3s ease;
  }

`
const LLMCard = styled(Card)`
  // background: #1a1a1a;
  // background: linear-gradient(45deg, #212121 0%, #171717 33%, #0E0E0E 66%, #070707 100%);
  // border: none;
  background-image: 
    linear-gradient(to bottom, rgba(17, 17, 17, 1) 25%, rgba(17, 17, 17, 0.8) 85%, rgba(17, 17, 17, 0.6) 95%), 
    url("https://www.iiicoast.com/backend-static/upload/llama.png");
  background-size: contain;
  background-position: center;
  /* Restrict the background image to the content box */
  background-clip: padding-box;
  // border: 10px solid rgba(255, 255, 255, 0.5);

  // &:after {
  //   content: '';
  //   position: absolute;
  //   bottom: 0%; /* Start the gradient halfway up */
  //   left: 0;
  //   right: 0;
  //   height: 100%; /* Full height of the overlay */
  //   background: linear-gradient(to top, rgba(7, 7, 7, 0.3) 0%, rgba(7, 7, 7, 0.9) 75%);
  //   border-radius: 9px;
  // }
`;

const AutoCard = styled(Card)`
  // background: #1a1a1a;
  // background: linear-gradient(45deg, #212121 0%, #171717 33%, #0E0E0E 66%, #070707 100%);
  // border: none;
  background-image: 
    linear-gradient(to bottom, rgba(17, 17, 17, 1) 25%, rgba(17, 17, 17, 0.8) 85%, rgba(17, 17, 17, 0.6) 95%), 
    url("https://www.iiicoast.com/backend-static/upload/invertautomation.png");
  background-size: cover;
  background-position: center;
  /* Restrict the background image to the content box */
  background-clip: padding-box;
  // border: 10px solid rgba(255, 255, 255, 0.5);
  

  // &:after {
  //   content: '';
  //   position: absolute;
  //   bottom: 0%; /* Start the gradient halfway up */
  //   left: 0;
  //   right: 0;
  //   height: 100%; /* Full height of the overlay */
  //   background: linear-gradient(to top, rgba(7, 7, 7, 0.3) 0%, rgba(7, 7, 7, 0.9) 75%);
  //   border-radius: 9px;
  // }

  @media (max-width: 1700px) {
    padding: 35px 29px 15px;
  }
`;

const ThreeDCard = styled(Card)`
  // background: #1a1a1a;
  // background: linear-gradient(45deg, #212121 0%, #171717 33%, #0E0E0E 66%, #070707 100%);
  // border: none;
  background-image: 
    linear-gradient(to bottom, rgba(17, 17, 17, 1) 25%, rgba(17, 17, 17, 0.8) 85%, rgba(17, 17, 17, 0.6) 95%), 
    url("https://www.iiicoast.com/backend-static/upload/3ddesign.png");
  background-size: cover;
  background-position: center;
  /* Restrict the background image to the content box */
  background-clip: padding-box;
  // border: 10px solid rgba(255, 255, 255, 0.5);

  // &:after {
  //   content: '';
  //   position: absolute;
  //   bottom: 0%; /* Start the gradient halfway up */
  //   left: 0;
  //   right: 0;
  //   height: 100%; /* Full height of the overlay */
  //   background: linear-gradient(to top, rgba(7, 7, 7, 0.3) 0%, rgba(7, 7, 7, 0.9) 75%);
  //   border-radius: 9px;
  // }
`;


const ProjectText = styled.div`
  font-size: 2rem;
  // text-transform: uppercase;
  color: white;
  // letter-spacing: 0.01em;
  // line-height: 50%;

  // margin: 35px;
  // transform: translate(-50%, -50%);
  z-index: 2;
  transition: transform 0.8s ease, opacity 0.4s ease;
  opacity: 1;
  text-align: left;
  font-weight: 500;
  font-family: 'Ubuntu', sans-serif;

  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

const CardSubTitle = styled.h3`
  font-size: 1.25rem;
  text-align: left;
  font-weight: 300;
  margin-top: 9px;
  line-height: 100%;
  letter-spacing: 0.01em;
  color: #fff;
  z-index: 100;

  // font-weight: normal;
  // opacity: 0.8;

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const Portfolio = () => {
  const skills = [
    // Python, SQL, HTML, JavaScript, Microsoft Visual Studio Code, MySQL, MariaDB, Google Analytics, PyTorch, TensorFlow,
    // Anaconda, Jupyter Notebook, GitHub
    { icon: <SiJavascript />, rotation: Math.random() * 20 - 10, description: "JavaScript" },
    { icon: <SiNodedotjs />, rotation: Math.random() * 20 - 10, description: "Node.js" },
    { icon: <SiReact />, rotation: Math.random() * 20 - 10, description: "React" },
    { icon: <SiPython />, rotation: Math.random() * 20 - 10, description: "Python" },
    { icon: <SiMysql />, rotation: Math.random() * 20 - 10, description: "MySQL" },
    // { icon: <SiMariadb />, rotation: Math.random() * 20 - 10, description: "MariaDB" },
    // { icon: <SiHtml5 />, rotation: Math.random() * 20 - 10, description: "HTML5" },
    // { icon: <SiCss3 />, rotation: Math.random() * 20 - 10, description: "CSS3" },
    { icon: <SiPytorch />, rotation: Math.random() * 20 - 10, description: "PyTorch" },
    { icon: <SiTensorflow />, rotation: Math.random() * 20 - 10, description: "TensorFlow" },
    // { icon: <SiAnaconda />, rotation: Math.random() * 20 - 10, description: "Anaconda" },
    { icon: <SiJupyter />, rotation: Math.random() * 20 - 10, description: "Jupyter" },
    // { icon: <SiDocker />, rotation: Math.random() * 20 - 10, description: "Docker" },
    // { icon: <SiAmazonwebservices />, rotation: Math.random() * 20 - 10, description: "AWS" }
  ];

  const [contactName, setContactName] = useState('');
  const [contactEmail, setContactEmail] = useState('');
  const [contactMessage, setContactMessage] = useState('');
  const [submitStatus, setSubmitStatus] = useState(''); // For handling response
  // const [mobileView, setMobileView] = useState(false);

  const sectionRefs = useRef([]);
  const containerRef = useRef(null);

  const navigate = useNavigate();

  // const handleMobileView = () => {
  //   setMobileView((prevState) => !prevState);
  // };

  useEffect(() => {
    const sections = sectionRefs.current;

    // Set up animations for each section
    sections.forEach((section, index) => {
      // const elements = section.querySelectorAll('h1, h2, div, li');
      const image = section.querySelector('.section-image');
      const imageContainer = section.querySelector('.section-image-container');
      const text = section.querySelectorAll('.section-text');
      const textAlt = section.querySelectorAll('.section-text-alt');
      const skillIcons = section.querySelectorAll('.skill-icon');
      // const projectCard = section.querySelectorAll('.project-card');
      
      // Scroll-into-view animations (scrolling down)

      gsap.fromTo(
        skillIcons,
        { opacity: 0, y: 50 },
        {
          opacity: 1,
          y: 0,
          duration: 1,
          stagger: 0.2,
          scrollTrigger: {
            trigger: section,
            start: 'top 10%',
            end: 'center 50%',
            scrub: 1,
            toggleActions: 'play none none reverse',
          },
        }
      );


      gsap.fromTo(
        image,
        { scale: 0.8 }, // Initial state when coming into view
        {
          scale: 1,
          duration: 1,
          scrollTrigger: {
            trigger: section,
            start: 'top 50%', // Start when top of section reaches the middle
            end: 'center 50%', // End when center of section reaches the middle
            // markers: true,
            scrub: true,
            toggleActions: 'play none none reverse',
          },
        }
      );
    
      gsap.fromTo(
        imageContainer,
        { rotate: '-10deg', clipPath: 'polygon(50% 50%, 50% 50%, 50% 50%, 50% 50%)' }, // Initial state when coming into view
        {
          rotate: '0deg',
          clipPath: 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)',
          duration: 1,
          scrollTrigger: {
            trigger: section,
            start: 'top 50%',
            end: 'center 50%',
            scrub: true,
            toggleActions: 'play none none reverse',
          },
        }
      );
    
      gsap.fromTo(
        text,
        { x: '-100%', opacity: 0 }, // Initial state when coming into view
        {
          x: '0%',
          opacity: 1,
          duration: 1,
          scrollTrigger: {
            trigger: section,
            start: 'top 50%',
            end: 'center 85%',
            scrub: true,
            toggleActions: 'play none none reverse',
          },
        }
      );
    
      gsap.fromTo(
        textAlt,
        { x: '-200%', opacity: 0 }, // Initial state when coming into view
        {
          x: '0%',
          opacity: 1,
          duration: 1,
          scrollTrigger: {
            trigger: section,
            start: 'top 50%',
            end: 'center 85%',
            scrub: true,
            toggleActions: 'play none none reverse',
          },
        }
      );
      
      // // Scroll-out-of-view animations (scrolling up)
      // gsap.fromTo(
      //   projectCard,
      //   { opacity: 0, y: -50 }, // Fully visible state
      //   {
      //     opacity: 1,
      //     y: 50, // Move elements upwards and fade them out
      //     duration: 1,
      //     scrollTrigger: {
      //       trigger: section,
      //       start: 'top 50%', // When bottom reaches middle of the screen
      //       end: 'center 85%', // When the section has fully scrolled out
      //       scrub: true,
      //       toggleActions: 'play none none reverse', // Animate both on scroll-up and scroll-down
      //     },
      //   }
      // );

      // Scroll-out-of-view animations (scrolling up)
      // gsap.fromTo(
      //   elements,
      //   { opacity: 1, y: 0 }, // Fully visible state
      //   {
      //     opacity: 0,
      //     y: -50, // Move elements upwards and fade them out
      //     duration: 1,
      //     scrollTrigger: {
      //       trigger: section,
      //       start: 'bottom 50%', // When bottom reaches middle of the screen
      //       end: 'top 0%', // When the section has fully scrolled out
      //       scrub: true,
      //       toggleActions: 'play none none reverse', // Animate both on scroll-up and scroll-down
      //     },
      //   }
      // );
    
      // Example for reverse behavior on image when scrolling out upwards
      gsap.fromTo(
        image,
        { scale: 1 }, // State when fully visible
        {
          scale: 0.8, // Shrink when scrolling out upwards
          scrollTrigger: {
            trigger: section,
            start: 'bottom 50%', // Starts when bottom is in middle of the viewport
            end: 'top top', // Ends when section is fully scrolled out
            scrub: true,
            toggleActions: 'play none none reverse',
          },
        }
      );
      gsap.fromTo(
        imageContainer,
        { rotate: '0deg', clipPath: 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)' }, // Initial state when coming into view
        {
          rotate: '10deg',
          clipPath: 'polygon(50% 50%, 50% 50%, 50% 50%, 50% 50%)',
          duration: 1,
          scrollTrigger: {
            trigger: section,
            start: 'bottom 90%',
            end: 'top top',
            scrub: true,
            toggleActions: 'play none none reverse',
          },
        }
      );
      gsap.fromTo(
        text,
        { x: '0%', opacity: 1 }, // Initial state when coming into view
        {
          x: '-100%',
          opacity: 0,
          duration: 1,
          scrollTrigger: {
            trigger: section,
            start: 'bottom 65%',
            end: 'top top',
            scrub: true,
            toggleActions: 'play none none reverse',
          },
        }
      );
    
      gsap.fromTo(
        textAlt,
        { x: '0%', opacity: 1 }, // Initial state when coming into view
        {
          x: '-200%',
          opacity: 0,
          duration: 1,
          scrollTrigger: {
            trigger: section,
            start: 'bottom 65%',
            end: 'top top',
            scrub: true,
            toggleActions: 'play none none reverse',
          },
        }
      );
      gsap.fromTo(
        skillIcons,
        { opacity: 1, y: 0 },
        {
          opacity: 0,
          y: 50,
          duration: 1,
          stagger: 0.2,
          scrollTrigger: {
            trigger: section,
            start: 'bottom 65%',
            end: 'top 25%',
            scrub: 1,
            toggleActions: 'play none none reverse',
          },
        }
      );

      
    });
    

    let snapScrollTrigger = ScrollTrigger.create({
      snap: {
        snapTo: (progress) => {
          const sectionIndex = Math.round(progress * (sections.length - 1));
          return sectionIndex / (sections.length - 1);
        },
        duration: { min: 0.5, max: 0.75 }, // Shortened duration for quicker response
        ease: "power4.inOut", // Changed to a gentler easing function
        delay: 0.25, // Small delay to allow for natural scrolling
      },
      trigger: containerRef.current,
      start: 'top top',
      end: 'bottom bottom',
      scrub: 0.5,
    });

    return () => {
      // Clean up
      ScrollTrigger.getAll().forEach(trigger => trigger.kill());
      if (snapScrollTrigger) snapScrollTrigger.kill();
    };
  }, []);

    const handleArrowClick = () => {
      // Scroll to IntroSection
      gsap.to(window, {
        duration: 1,
        scrollTo: { y: sectionRefs.current[1], offsetY: 0 },
        ease: "power4.inOut"
      });
    };
    
    const handleProjectButtonClick = () => {
      navigate('/');
    };

    const handleSubmit = async (e) => {
      e.preventDefault();
      setSubmitStatus('sending');
      
      if (!contactName && !contactEmail && !contactMessage) {
        setSubmitStatus('error')
        return
      }

      try {
        const response = await axios.post('/v2/contact', {
          name: contactName,
          email: contactEmail,
          message: contactMessage
        });
  
        if (response.status === 200) {
          setSubmitStatus('success');
          setContactName('');
          setContactEmail('');
          setContactMessage('');
        }
      } catch (error) {
        console.error('Error sending message:', error);
        setSubmitStatus('error');
      }
    };

    return (
      <>
      <Header>
        <HeaderLeft>
          <HeaderTitle>Sebastien Phillips</HeaderTitle>
          <HeaderSubTitle>Full-Stack Engineer</HeaderSubTitle>
        </HeaderLeft>
        <HeaderRight>
          <HeaderLink href='https://www.linkedin.com/in/sebastienphillips/'>
            Linkedin<FaExternalLinkAlt />
          </HeaderLink>
          <HeaderLink href='https://drive.google.com/file/d/1T6ltCCP-H0ccBAy3l-1YRC_ljH8P0Rxj/view?usp=sharing'>
            Resume
            <FaExternalLinkAlt />
          </HeaderLink>
        </HeaderRight>
      </Header>
      <PortfolioContainer ref={containerRef}>
        
        <SplashSection ref={(el) => (sectionRefs.current[0] = el)}>
          <AnimatedTitle title="Sebastien Phillips " cols={36} n={756} />
          <AnimatedTitle title="Designer Engineer "  cols={41} n={82} />
          <ScrollIndicator onClick={handleArrowClick}><AnimatedArrow /></ScrollIndicator>
        </SplashSection>

        <IntroSection ref={(el) => (sectionRefs.current[1] = el)}>
          <SectionImageBackground>
            <SectionImageContainer className="section-image-container">
              <SectionImage 
                className="section-image" 
                src="https://www.iiicoast.com/backend-static/founder/img-1.jpg" 
                alt="Section 1 Image" 
              />
            </SectionImageContainer>
            <SectionTextContainer>
              {/*
              <SectionTextAlt className="section-text-alt">
              👋🏾 // ✨
              </SectionTextAlt>
              */}
              <SectionText className="section-text">
              Full-stack engineer with
              </SectionText>
              <SectionTextAlt className="section-text-alt">
                a passion for creating
              </SectionTextAlt>
              <SectionText className="section-text">
                beautiful, high-
              </SectionText>
              <SectionTextAlt className="section-text-alt">
                performance web
              </SectionTextAlt>
              <SectionText className="section-text">
                applications.
              </SectionText>
            </SectionTextContainer>
          </SectionImageBackground>
          <SkillsBackground >
            <SkillsForeground>
              <SectionTextSmall className="skill-icon">
                Skills
              </SectionTextSmall>
            <SkillsContainer>
              {skills.map((skill, index) => (
                  <SkillIcon className="skill-icon" key={index} rotation={skill.rotation}>
                      {skill.icon}
                      <Tooltip>{skill.description}</Tooltip>
                  </SkillIcon>
              ))}
            </SkillsContainer>
            </SkillsForeground>
          </SkillsBackground>
        </IntroSection>
        <SkillsSection ref={(el) => (sectionRefs.current[2] = el)}>
          <ProjectCard className='project-card'>
            {/* $mobileView={mobileView} 
             <ProjectViewToggle $mobileView={mobileView} onClick={handleMobileView}>
            {mobileView ? (
              <>
                <MdComputer />
              </>
            ) : (
              <>
                <IoMdPhonePortrait />
              </>
            )}
          </ProjectViewToggle>
            */}
          <SectionTextMidContainer>
              {/*
              <SectionTextAlt className="section-text-alt">
              👋🏾 // ✨
              </SectionTextAlt>
              */}
              <SectionTextMid className="section-text">
              Capstone Project
              </SectionTextMid>
              <SubTitle>Social Media Application<SubTitleMuted> - lampr version 3.2</SubTitleMuted></SubTitle>
              {/* 
              <SectionTextAlt className="section-text-alt">
                a passion for creating
              </SectionTextAlt>
              <SectionText className="section-text">
                beautiful, high-
              </SectionText>
              <SectionTextAlt className="section-text-alt">
                performance web
              </SectionTextAlt>
              <SectionText className="section-text">
                applications.
              </SectionText>
               */}
              
            </SectionTextMidContainer>
        <SectionVideoBackground>
            <SectionVideoContainer className="section-image-container">
              <StyledVideo className="skill-icon" autoPlay muted loop>
                <source src="https://www.iiicoast.com/backend-static/upload/UIrecording2.mp4" type="video/mp4" />
                Your browser does not support the video tag.
              </StyledVideo>
            </SectionVideoContainer>
          </SectionVideoBackground>
          {/*
          <SkillsBackground >
            <SkillsForeground>
              <SectionTextSmall className="skill-icon">
                Skills
              </SectionTextSmall>
            <SkillsContainer>
              {skills.map((skill, index) => (
                  <SkillIcon className="skill-icon" key={index} rotation={skill.rotation}>
                      {skill.icon}
                      <Tooltip>{skill.description}</Tooltip>
                  </SkillIcon>
              ))}
            </SkillsContainer>
            </SkillsForeground>
          </SkillsBackground>
          */}
          <ShinyButton
            initial={{ "--x": "100%" }}
            animate={{ "--x": "-100%" }}
            whileTap={{ scale: 0.8 }}
            transition={{
              "--x": {
                repeat: Infinity,
                repeatType: "loop",
                repeatDelay: 1,
                type: "spring",
                stiffness: 20,
                damping: 15,
                mass: 2,
              }
            }} // transition is now only applied to --x
            onClick={handleProjectButtonClick}
          >
          <ButtonText>
            View Project
          </ButtonText>
          <ButtonOverlay className='linear-overlay'/>
          </ShinyButton>
          </ProjectCard>
        </SkillsSection>

        <ProjectsSection ref={(el) => (sectionRefs.current[3] = el)}>
          <ProjectWrapper>
            <ProjectHeader>More Projects</ProjectHeader>
            <CardWrapper>
              <LLMCard>
                <ProjectText>LLM Content Generation</ProjectText>
                <CardSubTitle>PyTorch, GPT2 & LLama</CardSubTitle>
              </LLMCard>
              <AutoCard>
                <ProjectText>Automation</ProjectText>
                <CardSubTitle>Selenium & AHK</CardSubTitle>
              </AutoCard>
              <ThreeDCard>
                <ProjectText>3D Design</ProjectText>
                <CardSubTitle>Unreal Engine, Blender & Three.js</CardSubTitle>
              </ThreeDCard>
            </CardWrapper>
          </ProjectWrapper>
          {/*
           <ProjectCard className='project-card'>
          <HeaderText>
            <Title>Project Title</Title>
            <SubTitle>Project Subtitle</SubTitle>
          </HeaderText>
          <StyledVideo2 src="https://www.iiicoast.com/backend-static/upload/UIrecording.mp4" controls />
          <ShinyButton2>Learn More</ShinyButton2>
        </ProjectCard>
          */}
        {/*
        <Title>Projects</Title>
          <ProjectGrid>
            <ProjectCardTemp>
              <ProjectImage src="https://picsum.photos/400/300?random=1" alt="Project 1" />
              <h3>E-commerce Platform</h3>
              <p>A full-stack e-commerce solution with React and Node.js</p>
            </ProjectCardTemp>
            <ProjectCardTemp>
              <ProjectImage src="https://picsum.photos/400/300?random=2" alt="Project 2" />
              <h3>Task Management App</h3>
              <p>A real-time task management application using Socket.io</p>
            </ProjectCardTemp>
          </ProjectGrid>
        */}
        </ProjectsSection>

        <FooterSection ref={(el) => (sectionRefs.current[4] = el)}>
          <ContactCard>
            <ContactTitle>🤙🏾 Get in Touch</ContactTitle>
            <SubtTitleWrapper>
              {/*
              <ContactIcons>
                <p>Email: john.doe@example.com</p>
                <p>GitHub: github.com/johndoe</p>
                <p>LinkedIn: linkedin.com/in/johndoe</p>
                <MailIcon>
                  <FaEnvelope />
                </MailIcon>
              </ContactIcons>
              */}
              <ContactForm onSubmit={handleSubmit}>
                <Input 
                  id="name" 
                  type="text" 
                  value={contactName} 
                  onChange={(e) => setContactName(e.target.value)} 
                  placeholder="Name" 
                />
                <Input 
                  id="email" 
                  type="text" 
                  value={contactEmail} 
                  onChange={(e) => setContactEmail(e.target.value)} 
                  placeholder="Email" 
                />
                <TextAreaInput 
                  id="message" 
                  type="text" 
                  value={contactMessage} 
                  onChange={(e) => setContactMessage(e.target.value)} 
                  placeholder="Message" 
                />
                <ShinyButton
                  type="submit"
                  initial={{ "--x": "100%" }}
                  animate={{ "--x": "-100%" }}
                  whileTap={{ scale: 0.8 }}
                  transition={{
                    "--x": {
                      repeat: Infinity,
                      repeatType: "loop",
                      repeatDelay: 1,
                      type: "spring",
                      stiffness: 20,
                      damping: 15,
                      mass: 2,
                    }
                  }} // transition is now only applied to --x
                  // onClick={handleProjectButtonClick}
                >
                  <ButtonText>
                    {submitStatus === 'sending' ? 'Sending...' : 'Submit'}
                  </ButtonText>
                  <ButtonOverlay className='linear-overlay'/>
                </ShinyButton>
                {submitStatus === 'success' && <p>Message sent successfully!</p>}
                {submitStatus === 'error' && <p>Error sending message. Please try again.</p>}
              </ContactForm>
            </SubtTitleWrapper>
          </ContactCard>
          <Footer>
              <FooterLeft>
                <FooterTitle>© 2024 Sebastien Phillips. All Rights Reserved.</FooterTitle>
                <FooterSubTitle><FooterWrapper>Made in <FooterIcon><LiaFlagUsaSolid /></FooterIcon></FooterWrapper></FooterSubTitle>
              </FooterLeft>
              <FooterRight>Last updated by Sebastien on September 29, 2024, 7:27 PM CST</FooterRight>
            </Footer>
        </FooterSection>
        
      </PortfolioContainer>
    </>
    );
  };
  
export default Portfolio;