import React, { useState, useEffect, useCallback, useContext } from 'react';
import { AuthContext } from './AuthContext';
import { ToastProvider } from './ToastContext';
import axiosInstance from './axiosConfig';
import useLongPolling from './hooks/useLongPolling';
import axios from 'axios';
import { BrowserRouter as Router, Route, Routes, Outlet } from 'react-router-dom'; // , Navigate
import GlobalStyles from './styles/GlobalStyles';
import { BarLoader } from 'react-spinners';
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import {
  Header, 
  PostList, 
  Sidebar, 
  NotificationTray, 
  FloatingActionButton, 
  Login, 
  Register, 
  Onboarding, 
  ViewThread, 
  ViewArticle, 
  ProtectedRoute, 
  OddHeader, 
  OddHome, 
  OddJob, 
  OddConfirm, 
  OddWorkerLogin,
  OddWorker,
  // OddWorkerJob,
  OddLogin,
  FounderHeader
} from './components';
import {
  ProfilePage, 
  DraftsPage,
  ViewProfilePage, 
  ExplorePage, 
  MusicPage, 
  VideoPage, 
  ChatbotPage, 
  RoomsPage, 
  MessagingPage, 
  ConversationPage, 
  Portfolio, 
  LabPage, 
  AppsPage, 
  StudyPage,
  HabitsPage
} from './pages';
import {
  LoaderWrapper, 
  AppContainer, 
  PageContent, 
  NewPostButton, 
  SpinnerContainer, 
} from './styles/AppStyles';

const NewPostsAlert = ({ onClick }) => (
  <NewPostButton onClick={onClick}>
    New Posts Available
  </NewPostButton>
);

// Layout for Test Page
const OddPageLayout = () => (
  <PayPalScriptProvider options={{ "client-id": "AWoAGxpFQBcfRTlVTnlBpJKrqGS99RKhDvgy5tbMt-VqZenniHxqVWLbiWDFvp8qM-G_0FobDaUsOFo1" }}>
    <div>
      <OddHeader />
      <main>
        {/* The Outlet will render the nested routes' content */}
        <Outlet />
      </main>
    </div>
  </PayPalScriptProvider>
);

// Layout for Test Page
const FounderLayout = () => (
    <div>
      <FounderHeader />
      <main>
        {/* The Outlet will render the nested routes' content */}
        <Outlet />
      </main>
    </div>
);

export const AxiosContext = React.createContext(null);

function App() {
  const [sidebarMinimized, setSidebarMinimized] = useState(false);
  const [page, setPage] = useState(1);
  const [posts, setPosts] = useState([]);
  const [scrolledToTop, setScrolledToTop] = useState(true);  // Track if the user is at the top
  const [isNewPosts, setIsNewPosts] = useState(false);       // Track if new posts are available
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [missingPostIds, setMissingPostIds] = useState([]);
  const [isFetchingMissing, setIsFetchingMissing] = useState(false);
  const [lastFetchedTid, setLastFetchedTid] = useState(null);
  const [lastFetchedPostId, setLastFetchedPostId] = useState(null); 
  const { isAuthenticated, loading } = useContext(AuthContext);
  const { unreadCount, notifications, recentTid } = useLongPolling();

  // // Matomo Tracking Script
  // useEffect(() => {
  //   var _paq = window._paq = window._paq || [];
  //   /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
  //   _paq.push(['trackPageView']);
  //   _paq.push(['enableLinkTracking']);
  //   (function() {
  //     var u="//admin.iiicoast.com/";
  //     _paq.push(['setTrackerUrl', u+'matomo.php']);
  //     _paq.push(['setSiteId', '1']);
  //     var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
  //     g.async=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);
  //   })();
  // }, []); // Empty dependency array means this effect runs once on mount

  useEffect(() => {
    const authConsoleText = "Current Authentication Status: "
    console.log(authConsoleText + isAuthenticated)
    setPage(1);
    setPosts([]);
  }, [isAuthenticated]); // Re-fetch when authentication status changes

  // Funtion to fetch paginated posts from API
  const fetchPosts = useCallback(async () => {
    // console.log('Fetching posts...' + ' Current page: ' + page); // + "Current Authentication Status: " + isAuthenticated
    // console.log();
    setIsLoading(true);

    setTimeout(async () => {
      try {
        const endpoint = isAuthenticated ? 'v2/get-user-feed' : 'api/get-posts';
        console.log('Fetching from ' + endpoint + ': page', page);
        let response = await axios.get(`/${endpoint}/${page}`);
        // const response = await axios.get(`https://www.iiicoast.com/api/get-posts/${page}`);
        let newPosts = response.data;
        // console.log('Fetched posts:', newPosts);

        // If freshPosts is empty and the user is authenticated, retry with the unauthenticated endpoint
        if (isAuthenticated && (!newPosts || newPosts.length === 0)) {
          console.log("No posts found for user, retrying with public feed...");
          response = await axios.get(`/api/get-posts/${page}`);
          newPosts = response.data;
        }
        
        // setPosts(prevPosts => [...prevPosts, ...newPosts]);

        // Check if there are existing posts
        setPosts(prevPosts => {
          // Ensure newPosts is not empty
          if (newPosts === prevPosts) {
            // If there are no new posts, return the current posts
            return prevPosts;
          }
          // Ensure prevPosts is not empty
          if (prevPosts.length === 0) {
            // If there are no existing posts, just add all new posts
            return [...prevPosts, ...newPosts];
          }

          // Ensure newPosts is not empty
          if (newPosts.length === 0) {
            // If there are no new posts, return the current posts
            return prevPosts;
          }
        
          // Return the updated posts
          return [...prevPosts, ...newPosts];
        });


        setHasMore(newPosts.length > 0);
        setPage(prevPage => prevPage + 1);
        // console.log('Next page will be:', page + 1);
      } catch (error) {
        console.error('Error fetching posts:', error);
      } finally {
        setIsLoading(false);
        // console.log('Loading finished');
      }
    }, 500); // 0.5-second delay before loading more posts
  }, [page, setPosts, isAuthenticated]);

  // Function to refresh posts with first page (primarily for reloading homepage on navigate)
  const refreshPosts = useCallback(async () => {
    if (!isAuthenticated) {
      console.log('User is not authenticated. Skipping refresh.');
      return;
    }

    console.log('Refreshing posts...');
    setIsRefreshing(true);

    setTimeout(async () => {
      try {
        const endpoint = isAuthenticated ? 'v2/get-user-feed' : 'api/get-posts';
        const response = await axios.get(`/${endpoint}/1`);
        // const response = await axios.get(`https://www.iiicoast.com/api/get-posts/1`);
        const freshPosts = response.data;
        console.log('Fetched fresh posts:', freshPosts);

        setPosts(freshPosts); // Replace old posts with the latest from page 1
        setPage(2); // Reset to page 2 for the next load
        setHasMore(freshPosts.length > 0);
      } catch (error) {
        console.error('Error refreshing posts:', error);
      } finally {
        setIsRefreshing(false);
        console.log('Refresh finished');
      }
    }, 250); // 0.5-second delay before loading more posts
  }, [isAuthenticated, setPosts]);

  // Function to retrieve new posts from backend with recent_tid (from long-polling)
  const getRecentPost = async (recent_tid) => {
    try {
      // Update to GET to align with best practices. 
      // const response = await axios.get('/v2/get_recent_post', {
      //   params: {
      //     recent_tid: recent_tid
      //   }
      // });
      const response = await axios.post('/v2/get_recent_post', {
        recent_tid: recent_tid,
      });

      if (response.status === 200) {
        // Fetch the new post
        if (response.data) {
          // Add the new post to the top of the list 
          console.log(response.data)
          setPosts(prevPosts => [
            {
              ...response.data,
              new: true
              // animate: true // Add an animate class to trigger the animation
            },
            ...prevPosts
          ]);
          
          // Trim the excess posts to 10
          setPosts(prevPosts => prevPosts.slice(0, 10));
          
          // Reset the page to 2
          setPage(2);
        }
        // setIsLoading(false);
  
        // Smooth scroll to the top
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
  
        console.log('Post retrieved successfully');
      }
      // const freshPosts = response.data;
      // console.log('Fetched fresh posts:', freshPosts);
// 
      // setPosts(freshPosts); // Replace old posts with the latest from page 1
      // setPage(2); // Reset to page 2 for the next load
      // setHasMore(freshPosts.length > 0);
    } catch (error) {
      console.error('Error getting recent post:', error);
    } finally {
      // setIsRefreshing(false);
      console.log('Get recent post finished');
    }
  }

  // Function to submit new post and update posts with /get-user-post API
  const handlePostSubmit = async (content) => {
    try {
      // setIsLoading(true);
      const response = await axiosInstance.post('/v2/post_tweet', {
        tweet: content,
      });
  
      if (response.status === 204) {
        // Fetch the new post
        const newPostResponse = await axiosInstance.get('/v2/get-user-post');
        if (newPostResponse.data) {
          // Add the new post to the top of the list 
          setPosts(prevPosts => [
            {
              ...newPostResponse.data,
              new: true
              // animate: true // Add an animate class to trigger the animation
            },
            ...prevPosts
          ]);
          
          // Trim the excess posts to 10
          setPosts(prevPosts => prevPosts.slice(0, 10));
          
          // Reset the page to 2
          setPage(2);
        }
        // setIsLoading(false);
        
        document.documentElement.scrollTop = 0;
        document.body.scrollTop = 0; // For older browsers

        // // Smooth scroll to the top
        // window.scrollTo({
        //   top: 0,
        //   behavior: 'smooth'
        // });
  
        console.log('Post submitted successfully');
      }
    } catch (error) {
      // setIsLoading(false);
      console.error('Error submitting post:', error);
    }
  }

  const handleNewPostClick = () => {
    // Scroll to the top for iOS Safari Standalone Mode
    document.documentElement.scrollTop = 0;
    document.body.scrollTop = 0; // For older browsers
    // window.scrollTo({ top: 0, behavior: 'smooth' });  // Scroll to top smoothly
    setScrolledToTop(true);
    setIsNewPosts(false);
    // getRecentPost(recentTid); // Fetch new posts once at the top
  };

  // // If no posts and user authenticated load first page of posts
  // useEffect(() => {
  //   if (posts.length === 0 && isAuthenticated) {
  //     console.log('Initial load: fetching first page of posts');
  //     fetchPosts();
  //   }
  // }, [posts.length, fetchPosts, isAuthenticated]);

  // If no posts and user authenticated load first page of posts
  useEffect(() => {
    if (posts.length === 0 && !loading) {
      // console.log('Initial load: fetching first page of posts');
      fetchPosts();
    }
  }, [posts.length, fetchPosts, loading]);

  useEffect(() => {
    const handleScroll = () => {
      // Check if the user is scrolled to the top
      const isAtTop = window.scrollY === 0;
      setScrolledToTop(isAtTop);
    };
  
    window.addEventListener('scroll', handleScroll);
    
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // Function to fetch the post range
const fetchPostRange = async (lastPostId) => {
  try {
    const response = await axios.get(`/v2/post-range-update/${lastPostId}`);
    const { post_ids, recent_tid } = response.data;
    setMissingPostIds(post_ids);
    return recent_tid;
  } catch (error) {
    console.error('Error fetching post range:', error);
    return null;
  }
};

useEffect(() => {
  const handlePostUpdates = async () => {
    if (posts && posts.length > 0) {
      const lastPost = posts[0];
      if (lastPost && lastPost.post && recentTid > lastPost.id && lastFetchedTid !== recentTid) {
        // Only fetch range if we haven't already fetched for this recentTid
        setLastFetchedTid(recentTid);
        const fetchedRecentTid = await fetchPostRange(lastPost.id);
        
        if (fetchedRecentTid && scrolledToTop && !isFetchingMissing) {
          setIsFetchingMissing(true);
          if (missingPostIds.length > 0) {
            const postId = missingPostIds[0];
            if (lastFetchedPostId !== postId) {
              setLastFetchedPostId(postId);
              await getRecentPost(postId);
              setMissingPostIds(prev => prev.slice(1));
            }
          }
          setIsFetchingMissing(false);
        } else if (!scrolledToTop) {
          setIsNewPosts(true);
        }
      }
    }
  };

  handlePostUpdates();
}, [recentTid, posts, scrolledToTop, missingPostIds, isFetchingMissing, lastFetchedTid, lastFetchedPostId]);

// Effect to handle fetching missing posts when scrolled to top
useEffect(() => {
  if (scrolledToTop && missingPostIds.length > 0 && !isFetchingMissing) {
    const fetchNextMissingPost = async () => {
      setIsFetchingMissing(true);
      const postId = missingPostIds[0];
      if (lastFetchedPostId !== postId) {
        setLastFetchedPostId(postId);
        await getRecentPost(postId);
        setMissingPostIds(prev => prev.slice(1));
      }
      setIsFetchingMissing(false);
    };

    fetchNextMissingPost();
  }
}, [scrolledToTop, missingPostIds, isFetchingMissing, lastFetchedPostId]);

  // const authConsoleText = "Current Authentication Status: "
  // console.log(authConsoleText + isAuthenticated)

  if (loading) {
    return (
      <LoaderWrapper><BarLoader color="#888888" loading={loading} /></LoaderWrapper>
    )
  }

  return (
    <AxiosContext.Provider value={axiosInstance}>
      <ToastProvider>
      <Router>
        <GlobalStyles />
        <Routes>
          {/* Define /lab route outside of PageContent */}
          <Route path="/lab" element={
            <>
              <LabPage /> {/* This will take up the entire viewport */}
            </>
          } />
          {/* Test app routes */}
          <Route path="/founder" element={<FounderLayout />}>
            <Route index element={<Portfolio />} />  {/* Default for /test */}
            {/* <Route path="job" element={<OddJob />} />
            <Route path="confirm" element={<OddConfirm />} /> */}
          </Route>
          <Route path="/test" element={<OddPageLayout />}>
            <Route index element={<OddHome />} />  {/* Default for /test */}
            <Route path="job" element={<OddJob />} />
            <Route path="confirm" element={<OddConfirm />} />
            <Route path="worker-login" element={<OddWorkerLogin />} />
            <Route path="worker" element={<OddWorker />} />
            {/* <Route path="worker-job" element={<OddWorkerJob />} /> */}
            <Route path="login" element={<OddLogin />} />
          </Route>
          {/* All other routes use the existing layout structure */}
          <Route path="/*" element={
            <AppContainer>
              <Sidebar onMinimize={setSidebarMinimized} refreshPosts={refreshPosts} setIsNewPosts={setIsNewPosts}/>
              { isAuthenticated && (
                <NotificationTray unreadCount={unreadCount} notifications={notifications} />
              )}
              {isNewPosts && <NewPostsAlert onClick={handleNewPostClick} />}
              <PageContent $sidebarMinimized={sidebarMinimized}>
                <Header refreshPosts={refreshPosts} />
                <Routes>
                  <Route path="/" element={
                    <>
                      {isRefreshing && (
                        <SpinnerContainer>
                          <BarLoader color="#888888" loading={isRefreshing} />
                        </SpinnerContainer>
                      )}
                      <>
                        <PostList 
                          posts={posts} 
                          setPosts={setPosts} 
                          page={page} 
                          setPage={setPage}
                          hasMore={hasMore}
                          setHasMore={setHasMore}
                          fetchPosts={fetchPosts}
                          refreshPosts={refreshPosts}
                          isLoading={isLoading}
                        />
                        {isAuthenticated && <FloatingActionButton onSubmit={handlePostSubmit} />}
                      </>
                    </>
                  } />
                  <Route path="/profile" element={
                    <ProtectedRoute>
                      <ProfilePage />
                    </ProtectedRoute>
                  } />
                  <Route path="/drafts" element={
                    <ProtectedRoute>
                      <DraftsPage />
                    </ProtectedRoute>
                  } />
                  {/* Define route for viewing other users' profiles with a username parameter */}
                  <Route path="/profile/:username" element={
                    <ProtectedRoute>
                      <ViewProfilePage />
                    </ProtectedRoute>
                  } />
                  <Route path="/explore" element={
                    <ProtectedRoute>
                      <ExplorePage />
                    </ProtectedRoute>
                  } />
                  <Route path="/messaging" element={
                    <ProtectedRoute>
                      <MessagingPage />
                    </ProtectedRoute>
                  } />
                  <Route path="/music" element={
                    <ProtectedRoute>
                      <MusicPage />
                    </ProtectedRoute>
                  } />
                  <Route path="/videos" element={
                    <ProtectedRoute>
                      <VideoPage />
                    </ProtectedRoute>
                  } />
                  <Route path="/chatbot" element={<ChatbotPage />} />
                  <Route path="/habits" element={<HabitsPage />} />
                  <Route path="/rooms" element={
                    <ProtectedRoute>
                      <RoomsPage />
                    </ProtectedRoute>
                  } />
                  <Route path="/apps" element={
                    <ProtectedRoute>
                      <AppsPage />
                    </ProtectedRoute>
                  } />
                  <Route path="/study" element={
                    // <ProtectedRoute>
                      <StudyPage />
                    // </ProtectedRoute>
                  } />
                  <Route path="/thread/:postId" element={
                    <ProtectedRoute>
                      <ViewThread />
                    </ProtectedRoute>
                  } />
                  <Route 
                    path="/article/:articleId" 
                    element={
                      <ProtectedRoute>
                        <ViewArticle />
                      </ProtectedRoute>
                    } 
                  />
                  <Route path="/conversation/:conversationId" element={
                      <ProtectedRoute>
                          <ConversationPage />
                      </ProtectedRoute>
                  } />
                  <Route path="/onboarding" element={<Onboarding />} />
                  <Route path="/login" element={<Login />} />
                  <Route path="/register" element={<Register />} />
                </Routes>
              </PageContent>
            </AppContainer>
          } />
        </Routes>
      </Router>
    </ToastProvider>
  </AxiosContext.Provider>
  );
}

export default App;