import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { FaUsers, FaSchool, FaMusic, FaVideo, FaRocket } from 'react-icons/fa';

const PageContainer = styled.div`
  padding: 20px;
  color: #ffffff;
`;

// const PageHeader = styled.h1`
//   color: #ffffff;
// `;

const AppGrid = styled.div`
  display: flex;
  // grid-template-columns: auto auto auto auto auto;
  gap: 15px;

  @media (max-width: 768px) {
    display: grid;
    grid-template-columns: auto auto auto;
  }
`

const AppSquare = styled(Link)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  height: 100px;
  width: 100px;
  background-color: #070707;
  border-radius: 20px;
  text-decoration: none;
  transition: all 0.3s ease;
  border: 1px solid #222;
  font-size: 10px;

  svg {
  color: #ffffff;
  font-size: 25px;
  // margin: auto;
  }

  &:hover {
    transform: scale(1.1);
    background-color: #212121;
  }
`

const AppsPage = () => {
  return (
    <PageContainer>
      {/* <PageHeader>Apps</PageHeader> */}
      {/* <p>This is a placeholder for the page. We'll add more details here later!</p> */}
      <AppGrid>
        <AppSquare to="/music"><FaMusic />Music</AppSquare>
        <AppSquare to="/videos"><FaVideo />Videos</AppSquare>
        <AppSquare to="/rooms"><FaUsers />Video Chat</AppSquare>
        <AppSquare to="/study"><FaSchool />Study Guide</AppSquare>
        <AppSquare to="/chatbot"><FaRocket />Chatbot</AppSquare>
      </AppGrid>
    </PageContainer>
  );
};

export default AppsPage;