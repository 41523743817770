import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../styles/VideoPage.css';

const VideoPage = () => {
    const [videos, setVideos] = useState([]);
    const [currentVideo, setCurrentVideo] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    useEffect(() => {
        fetchVideos();

        // Handle window resize
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const fetchVideos = async () => {
        try {
            const response = await axios.get('/api/get-video-info');
            setVideos(response.data);
            setLoading(false);
        } catch (err) {
            setError('Failed to fetch videos');
            setLoading(false);
        }
    };

    const handleVideoSelect = (video) => {
        setCurrentVideo(video);
        // On mobile, scroll to top when selecting a video
        if (isMobile) {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    };

    if (loading) return <div className="loading">Loading...</div>;
    if (error) return <div className="error">{error}</div>;

    return (
        <div className="video-page">
            <div className="video-player-section">
                {currentVideo ? (
                    <div className="video-player">
                        <video
                            controls
                            src={`/api/stream-video/${currentVideo.filename}`}
                            poster={currentVideo.thumbnail_url}
                            playsInline // Better mobile experience
                        >
                            Your browser does not support the video tag.
                        </video>
                        <div className="video-info">
                            <h2>{currentVideo.title}</h2>
                            <p>{currentVideo.description}</p>
                            <p>Creator: {currentVideo.creator}</p>
                            <p>Upload Date: {new Date(currentVideo.date).toLocaleDateString()}</p>
                        </div>
                    </div>
                ) : (
                    <div className="no-video-selected">
                        <p>Select a video to play</p>
                    </div>
                )}
            </div>

            <div className="video-list">
                {videos.map((video) => (
                    <div
                        key={video.vid}
                        className={`video-item ${currentVideo?.vid === video.vid ? 'active' : ''}`}
                        onClick={() => handleVideoSelect(video)}
                    >
                        <img 
                            src={video.thumbnail_url} 
                            alt={video.title}
                            loading="lazy" // Lazy load images
                        />
                        <div className="video-item-info">
                            <h3>{video.title}</h3>
                            <p>{video.creator}</p>
                            <p>{new Date(video.date).toLocaleDateString()}</p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default VideoPage;