// StyledComponents.js
import styled from 'styled-components';
import { motion } from 'framer-motion';

export const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px;
`;

// Study Guide Styled Components

export const GlassContainer = styled(motion.div)`
  background: rgba(23, 25, 35, 0.8);
  backdrop-filter: blur(12px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.37);
  border-radius: 24px;
  padding: 40px;
  max-width: 600px;
  margin: 20px auto;
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 200%;
    height: 100%;
    background: linear-gradient(
      90deg,
      transparent,
      rgba(255, 255, 255, 0.1),
      transparent
    );
    transition: 0.5s;
  }

  &:hover::before {
    left: 100%;
  }
`;

export const ProblemStatement = styled(motion.h2)`
  font-size: 32px;
  margin-bottom: 30px;
  text-align: center;
  color: #ffffff;
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.2);
  font-weight: 600;
  letter-spacing: 1px;
`;

export const OptionsGrid = styled(motion.div)`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin-top: 30px;
`;

export const OptionButton = styled(motion.button)`
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, ${props => props.status ? '0.2' : '0.1'});
  backdrop-filter: blur(4px);
  padding: 20px;
  font-size: 20px;
  color: ${props => 
    props.status === 'correct' ? '#4CAF50' : 
    props.status === 'incorrect' ? '#f44336' : '#ffffff'
  };
  border-radius: 16px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: 500;
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.1);
  
  &:hover {
    background: rgba(255, 255, 255, 0.1);
    transform: translateY(-2px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  }

  &:active {
    transform: translateY(1px);
  }
`;

export const NextButton = styled(motion.button)`
  background: linear-gradient(45deg, #2196F3, #00BCD4);
  color: white;
  border: none;
  padding: 15px 30px;
  font-size: 18px;
  cursor: pointer;
  border-radius: 12px;
  margin-top: 30px;
  width: 100%;
  font-weight: 600;
  letter-spacing: 1px;
  position: relative;
  overflow: hidden;
  
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      90deg,
      transparent,
      rgba(255, 255, 255, 0.2),
      transparent
    );
    transition: 0.5s;
  }

  &:hover::before {
    left: 100%;
  }
`;

export const DifficultyIndicator = styled(motion.div)`
  font-size: 28px;
  text-align: center;
  margin-bottom: 20px;
  color: ${props => props.difficulty === 'easy' ? '#FFD700' : '#FF4081'};
  text-shadow: 0 0 10px ${props => props.difficulty === 'easy' ? 'rgba(255, 215, 0, 0.5)' : 'rgba(255, 64, 129, 0.5)'};
`;