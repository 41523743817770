import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { FaCalendar, FaTools, FaDollarSign, FaRuler } from 'react-icons/fa';

const WorkerContainer = styled.div`
  padding: 1rem;
  max-width: 800px;
  margin: 0 auto;
`;

const JobCard = styled.div`
  background: rgba(32, 32, 32, 0.8);
  backdrop-filter: blur(10px);
  border-radius: 10px;
  padding: 1.5rem;
  margin-bottom: 1rem;
  color: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.1);
`;

const JobTitle = styled.h2`
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: #00ff88;
`;

const JobDetail = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  
  svg {
    margin-right: 0.5rem;
    color: #00ff88;
  }
`;

const LoadingSpinner = styled.div`
  text-align: center;
  color: white;
  font-size: 1.2rem;
`;

const ErrorMessage = styled.div`
  color: #ff4444;
  text-align: center;
  padding: 1rem;
`;

const OddWorker = () => {
  const [jobs, setJobs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchJobs = async () => {
      try {
        const response = await axios.get('/v2/get-all-jobs');
        setJobs(response.data.jobs);
        setLoading(false);
      } catch (err) {
        setError('Failed to load jobs. Please try again later.');
        setLoading(false);
      }
    };

    fetchJobs();
  }, []);

  if (loading) return <LoadingSpinner>Loading jobs...</LoadingSpinner>;
  if (error) return <ErrorMessage>{error}</ErrorMessage>;

  return (
    <WorkerContainer>
      {jobs.map((job) => (
        <JobCard key={job.id}>
          <JobTitle>{job.job_type}</JobTitle>
          <JobDetail>
            <FaCalendar />
            {new Date(job.job_date).toLocaleDateString()}
          </JobDetail>
          <JobDetail>
            <FaRuler />
            {job.quantity} units
          </JobDetail>
          <JobDetail>
            <FaDollarSign />
            ${job.total_cost}
          </JobDetail>
          <JobDetail>
            <FaTools />
            Order ID: {job.paypal_order_id}
          </JobDetail>
        </JobCard>
      ))}
    </WorkerContainer>
  );
};

export default OddWorker;